<div class="container">
    <div class="row">
        <div class="col-auto" id="title">
            <span class="h2 d-line-block">
                Reservations
            </span>
        </div>
        <div class="col-auto align-self-end">
            <div ngbDropdown class="city-dropdown">
                <button type="button" class="btn btn-outline-primary" ngbDropdownToggle>
                  {{ selected }}
                </button>
                <div ngbDropdownMenu aria-labelledby="city-dropdown">
                  <button ngbDropdownItem *ngFor="let city of cities" (click)="changeCity(city)">
                    {{ city }}
                  </button>
                </div>
            </div>
        </div>
        <div class="col-auto">
            <div ngbDropdown class="city-dropdown">
                <button type="button" class="btn btn-outline-primary" ngbDropdownToggle>
                  {{ selectedStatus }}
                </button>
                <div ngbDropdownMenu aria-labelledby="status-dropdown">
                  <button ngbDropdownItem *ngFor="let status of reservationStatuses" (click)="changeStatus(status)">
                    {{ status }}
                  </button>
                </div>
            </div>
        </div>
        <div class="col" id="search">
            <div class="input-group">
                <input type="text"
                    [(ngModel)]="query"
                    class="form-control" placeholder="Search" aria-describedby="btnaddon"
                    (keydown.enter)="doSearch()">
                <button class="btn btn-outline-primary" id="btnaddon" (click)="doSearch()">
                    Search
                </button>
            </div>
        </div>
    </div>

    <div class="row text-start border-top border-bottom py-2 my-2 fw-bold" *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
        <div class="col-2 fw-bold">
            Listing Title
        </div>
        <div class="col-2 fw-bold">
            Move-in / Move-out
        </div>
        <div class="col-2 fw-bold">
            Booked
        </div>
        <div class="col-2 fw-bold">
            Guest Name
        </div>
        <div class="col-2 fw-bold">
            Total Paid
        </div>
        <div class="col-2 fw-bold">
            Status
        </div>
    </div>

    <ng-container *ngFor="let reservation of reservations">
        <div class="reservation clickable" [routerLink]="['/reservation', reservation._id]">
            <app-reservation [reservation]="reservation"></app-reservation>
        </div>
    </ng-container>

    <div class="row float-end mt-2" *ngIf="totalSize > pageService.getPageSize()">
        <app-paginator 
            [length]="totalSize"
            [pageSize]="pageService.getPageSize()"
            (change)="onPageChange($event)"
            [page]="page"
            [rotate]="true"
            [ellipses]="false"
            [maxSize]="12"
          ></app-paginator> 
      </div>
</div>