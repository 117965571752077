import { User } from "src/app/models/user/user"

export interface FeedbackForm {
  _id: string
  name: string
  active: boolean
  questions: Question[]
}

export interface Question {
  _id: string
  title: { fr: string; en: string }
  inputType: InputType
  required: boolean
  options: QuestionOptions
  userStatus: UserStatus
}

export type QuestionOptions =
  | TextOptions
  | RangeOptions
  | SelectOptions
  | CheckboxOptions

export interface TextOptions {
  text: {
    placeholder: { fr: string; en: string }
  }
}

export interface RangeOptions {
  range: {
    min: number
    max: number
  }
}

export interface SelectOptions {
  select: {
    values: { fr: string; en: string }[]
  }
}

export interface CheckboxOptions {
  checkbox: {
    values: { fr: string; en: string }[]
  }
}

export enum InputType {
  SELECT = 'select',
  RANGE = 'range',
  TEXT = 'text',
  CHECKBOX = 'checkbox',
}

export enum UserStatus {
  BOTH = 'both',
  TENANT = 'tenant',
  HOST = 'host',
}

export const INITIAL_QUESTION_VALUE: Question = {
  _id: '',
  title: { fr: '', en: '' },
  inputType: InputType.TEXT,
  userStatus: UserStatus.BOTH,
  required: true,
  options: { text: { placeholder: { fr: '', en: '' } } } as TextOptions,
}

export interface SingleAnswerSchema {
  questionId: string
  questionTitle: string
  answer: string
}
export interface AnswersSchema {
  createdAt: string;
  userId: any // will be the user object
  apartmentId: any // will be the apartment object
  formId: any // will be the form object
  rentRequestId: {
    _id: string;
    moveInDate: string;
    moveOutDate: string;
  }
  userStatus: string
  answers: SingleAnswerSchema[]
}

export interface PendingFeedbackResponse {
  itemCount: number;
  pageCount: number;
  pageSize: number;
  pageNumber: number;
  firstItem: number;
  lastItem: number;
  pendings: PendingFeedbackSchema[];
}

export interface PendingFeedbackSchema {
  _id: string,
  userId: User,
  emailStatus: string,
  userStatus: string,
  status: string,
  sendDate: string,
  rentRequest: any
  createdAt: string
}
