import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { CategoryEditModalComponent } from 'src/app/components/matching/matching-keywords/category-edit-modal/category-edit-modal.component'
import { KeywordEditModalComponent } from 'src/app/components/matching/matching-keywords/keyword-edit-modal/keyword-edit-modal.component'
import { FeedbackAddModalComponent } from '../form-add-modal/form-add-modal.component'
import { FeedbackService } from '../../services/feedback.service'
import { LoginService } from 'src/app/services/login.service'
import {
  FeedbackForm,
  INITIAL_QUESTION_VALUE,
  Question,
  UserStatus,
} from '../../services/feedback.type'
import { QuestionAddModalComponent } from '../questions-add-modal/question-add-modal/question-add-modal.component'
import { ConfirmModalComponent } from 'src/app/components/matching/shared/confirm-modal/confirm-modal.component'

@Component({
  selector: 'app-feedback-questions',
  templateUrl: './feedback-questions.component.html',
  styleUrls: ['./feedback-questions.component.scss'],
})
export class FeedbackQuestionsComponent implements OnInit {
  forms: FeedbackForm[] = []
  public userStatus = UserStatus

  constructor(
    private modalService: NgbModal,
    private feedbackService: FeedbackService,
    private loginService: LoginService,
    private router: Router,
  ) {}

  async ngOnInit() {
    try {
      this.forms = await this.feedbackService.getForms()
    } catch (error) {
      this.loginService.setLoggedIn(false);
      this.router.navigate(['/login']);
    }
  }

  async activateForm(formId: string, event: Event) {
    event.stopPropagation()
    this.forms = await this.feedbackService.activateForm(formId)
  }

  addQuestion(formId: string) {
    const modalRef = this.modalService.open(QuestionAddModalComponent, {
      centered: true,
      size: 'lg',
      scrollable: true,
    })
    modalRef.componentInstance.question = INITIAL_QUESTION_VALUE
    modalRef.componentInstance.formId = formId
    modalRef.componentInstance.mode = 'add'
    modalRef.closed.subscribe((resp) => {
      this.handleResponse(resp)
    })
  }

  editQuestion(formId: string, questionId: string) {
    const question = this.getQuestion(formId, questionId)
    if (!question) return
    const modalRef = this.modalService.open(QuestionAddModalComponent, {
      centered: true,
      size: 'lg',
      scrollable: true,
    })
    modalRef.componentInstance.formId = formId
    modalRef.componentInstance.question = question
    modalRef.componentInstance.mode = 'edit'
    modalRef.closed.subscribe((resp) => {
      this.handleResponse(resp)
    })
  }

  createForm() {
    const modalRef = this.modalService.open(FeedbackAddModalComponent, {
      centered: true,
      size: 'md',
      scrollable: true,
    })
    modalRef.componentInstance.feedbackForm = {
      name: '',
      active: false,
    } as FeedbackForm
    modalRef.componentInstance.mode = 'add'
    modalRef.closed.subscribe((resp) => {
      this.handleResponse(resp)
    })
  }

  editForm(formId: string, event: Event) {
    event.stopPropagation()
    const form = this.getForm(formId)
    if (!form) return
    const modalRef = this.modalService.open(FeedbackAddModalComponent, {
      centered: true,
      size: 'md',
      scrollable: true,
    })
    modalRef.componentInstance.feedbackForm = form
    modalRef.componentInstance.mode = 'edit'
    modalRef.closed.subscribe((resp) => {
      this.handleResponse(resp)
    })
  }

  getForm(formId: string): FeedbackForm | undefined {
    for (const form of this.forms) if (form._id === formId) return form

    return undefined
  }

  handleResponse(response: FeedbackForm) {
    const form = this.getForm(response._id)
    if (!form) {
      this.forms.push(response)
      return
    }
    form.questions = response.questions
    form.name = response.name
  }

  getQuestion(formId: string, questionId: string): Question | undefined {
    const form = this.getForm(formId)
    if (!form) return undefined
    for (const question of form.questions) {
      if (question._id === questionId) return question
    }
    return undefined
  }

  deleteForm(formId: string, event: Event) {
    event.stopPropagation()

    let index = -1
    for (let i = 0; i < this.forms.length; i++)
      if (this.forms[i]._id === formId) index = i
    if (index === -1) return
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      centered: true,
      size: 'md',
    })
    modalRef.componentInstance.title = 'Warning'
    modalRef.componentInstance.text = `Are you sure you want to delete this form (${this.forms[index].name})?`
    modalRef.closed.subscribe(() => {
      this.feedbackService.deleteForm(formId)
      this.forms.splice(index, 1)
    })
  }

  deleteQuestion(formId: string, questionId: string) {
    const form = this.forms.find((f) => f._id == formId)
    let questionIndex
    if (form) {
      questionIndex = form.questions.findIndex((q) => q._id == questionId)
    }
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      centered: true,
      size: 'md',
    })
    modalRef.componentInstance.title = 'Warning'
    modalRef.componentInstance.text = `Are you sure you want to delete this question (question #${
      (questionIndex ?? 0) + 1
    })`
    modalRef.closed.subscribe(async () => {
      const resp = await this.feedbackService.deleteQuestion(formId, questionId)
      this.handleResponse(resp)
    })
  }
}
