import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { ApiService } from "../services/api.service";
const moment = require('moment');
@Injectable({
    providedIn: 'root'
  })
export class GeneralUtils {
    constructor(
      public router: Router,
      private api: ApiService
    ) {
    }

    getAPIUrl() {
      return environment.apiUrl;
    }

    getImageHost() {
      return environment.imageHost;
    }

    isCurrentDate(moveInDate: Date, moveOutDate: Date) {
      let today = new Date();
      return (today >= new Date(moveInDate) && today <= new Date(moveOutDate));
    }

    isOverDate(moveInDate: Date, moveOutDate: Date) {
      let today = new Date();
      return (today > new Date(moveOutDate));
    }

    isUpcomingDate(moveInDate: Date, moveOutDate: Date) {
      let today = new Date();
      return (today > new Date(moveInDate));
    }

    getUrlPath() {
      return this.router.url.split('/')[1];
    }

    getApiModeString(): string {
      return (this.api.isProd()) ? "prod" : "dev";
    }

    isLongTermStay(moveInDate: Date, moveOutDate: Date): boolean {
      const diffMillisec = new Date(moveOutDate).getTime() - new Date(moveInDate).getTime()
      return diffMillisec / 1000 / 60 / 60 / 24 > 62;
    }

    isAtBeginningOfMonth(date: Date): boolean {
      return moment(date).date() == 1;
    }
}