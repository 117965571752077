import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from 'src/app/utils/utils';
import { Neighborhood } from './neighborhoods.types';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NeighborhoodsService {

  constructor(
    private util: Utils,
    private http: HttpClient,
    private authService: AuthService,
  ) { }


    async getNeighborhoods(): Promise<Neighborhood[]> {
      const headers =
        new HttpHeaders().set(
          'authorization', this.authService.getToken()
        );
      return new Promise<Neighborhood[]>((resolve, reject) => {
        const url = `${this.util.getAPIUrl()}admin/neighborhoods`;
        this.http.get<Neighborhood[]>(url, { headers }).subscribe({
          next: resolve,
          error: reject
        });
      });
    }


    async createNeighborhood(category: Neighborhood): Promise<Neighborhood> {
      const headers =
        new HttpHeaders().set(
          'authorization', this.authService.getToken()
        );
      return new Promise<Neighborhood>((resolve, reject) => {
        const url = `${this.util.getAPIUrl()}admin/neighborhoods`;
        this.http.post<Neighborhood>(url, category, { headers }).subscribe({
          next: resolve,
          error: reject
        });
      });
    }


    async modifyNeighborhood(neighborhoodId: string, neighborhood: Neighborhood): Promise<Neighborhood> {
      const headers =
        new HttpHeaders().set(
          'authorization', this.authService.getToken()
        );
      return new Promise<Neighborhood>((resolve, reject) => {
        const url = `${this.util.getAPIUrl()}admin/neighborhoods/${neighborhoodId}`;
        this.http.patch<Neighborhood>(url, neighborhood, { headers }).subscribe({
          next: resolve,
          error: reject
        });
      });
    }

    async deleteNeighborhood(neighborhoodId: string): Promise<void> {
      const headers =
        new HttpHeaders().set(
          'authorization', this.authService.getToken()
        );
      return new Promise<void>((resolve, reject) => {
        const url = `${this.util.getAPIUrl()}admin/neighborhoods/${neighborhoodId}`;
        this.http.delete<void>(url, { headers }).subscribe({
          next: resolve,
          error: reject
        });
      });
    }


    async addKeyword(neighborhoodId: string, keywordId: string): Promise<Neighborhood> {
      const headers =
        new HttpHeaders().set(
          'authorization', this.authService.getToken()
        );
      return new Promise<Neighborhood>((resolve, reject) => {
        const url = `${this.util.getAPIUrl()}admin/neighborhoods/${neighborhoodId}/${keywordId}`;
        this.http.put<Neighborhood>(url, keywordId, { headers }).subscribe({
          next: resolve,
          error: reject
        });
      });
    }


    async deleteKeyword(neighborhoodId: string, keywordId: string): Promise<Neighborhood> {
      const headers =
        new HttpHeaders().set(
          'authorization', this.authService.getToken()
        );
      return new Promise<Neighborhood>((resolve, reject) => {
        const url = `${this.util.getAPIUrl()}admin/neighborhoods/${neighborhoodId}/${keywordId}`;
        this.http.delete<Neighborhood>(url, { headers }).subscribe({
          next: resolve,
          error: reject
        });
      });
    }
}
