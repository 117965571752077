import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PendingFeedbackResponse, PendingFeedbackSchema } from '../services/feedback.type';
import { FeedbackService } from '../services/feedback.service';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-feedback-pending',
  templateUrl: './feedback-pending.component.html',
  styleUrls: ['./feedback-pending.component.css']
})
export class FeedbackPendingComponent implements OnInit {

  page: number = 1;
  searchControl = new FormControl('');
  response: PendingFeedbackResponse | undefined;
  loadingEmail: string | undefined;

  constructor(
    private feedbackService: FeedbackService, 
    private datePipe: DatePipe
  ) {}

  async ngOnInit() {
    this.loadPage(1);
    this.searchControl.valueChanges.subscribe(() => {
      this.loadPage(1);
    })
  }

  async loadPage(page: number) {
    this.page = page;
    this.response = await this.feedbackService.getPending(page, this.searchControl.value as string);
  }

  async sendEmail(pending: PendingFeedbackSchema, event: Event) {
    event.stopPropagation();
    this.loadingEmail = pending._id;
    try {
      await this.feedbackService.sendEmail(pending._id);
      await this.loadPage(this.page);
    } catch {}
    this.loadingEmail = undefined;
  }

  async triggerCronjob() {
    try {
      await this.feedbackService.triggerCronjob()
      await this.loadPage(this.page);
    } catch {}
  }

  formatDate(date: string | Date): string {
    return this.datePipe.transform(date, 'yyyy/MM/dd HH:mm') ?? '';
  }

}
