import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Utils } from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private authService: AuthService,
    private util: Utils,
    private http: HttpClient
  ) { }

  generateLoginToken(userId: string) {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    let url = `${this.util.getAPIUrl()}admin/generatetoken`;
    return this.http.post(url, {userId}, {headers});
  }

}
