import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatchingComponent } from './matching/matching.component';
import { NgIconsModule } from '@ng-icons/core';
import {
  bootstrapClipboard2DataFill,
  bootstrapGearFill,
  bootstrapArrowLeftCircleFill,
  bootstrapArrowRightCircleFill,
  bootstrapTagsFill,
  bootstrapPlusCircleFill,
  bootstrapPlusLg,
  bootstrapPlus,
  bootstrapTrashFill,
  bootstrapPencilFill,
  bootstrapPeopleFill,
  bootstrapPersonFill,
  bootstrapBuilding
} from '@ng-icons/bootstrap-icons';
import { ionFlask, ionAdd, ionAddCircle, ionTime, ionHome } from '@ng-icons/ionicons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MatchingSettingsComponent } from './matching-settings/matching-settings.component';
import { MatchingKeywordsComponent } from './matching-keywords/matching-keywords.component';
import { KeywordEditModalComponent } from './matching-keywords/keyword-edit-modal/keyword-edit-modal.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CategoryEditModalComponent } from './matching-keywords/category-edit-modal/category-edit-modal.component';
import { MatchingNeighborhoodsComponent } from './matching-neighborhoods/matching-neighborhoods.component';
import { NeighborhoodKeywordAddModalComponent } from './matching-neighborhoods/neighborhood-keyword-add-modal/neighborhood-keyword-add-modal.component';
import { NeighborhoodEditModalComponent } from './matching-neighborhoods/neighborhood-edit-modal/neighborhood-edit-modal.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { UserToUsersComponent } from './matching-testing/user-to-users/user-to-users.component';
import { UserToNeighborhoodsComponent } from './matching-testing/user-to-neighborhoods/user-to-neighborhoods.component';
import { WorkerComponent } from './matching-dashboard/worker/worker.component';
import { WorkerParamsComponent } from './matching-dashboard/worker/worker-params/worker-params.component';
import { GenerateComponent } from './matching-dashboard/generate/generate.component';
import { AiResultsComponent } from './matching-dashboard/ai-results/ai-results.component';
import { AiResultsDataComponent } from './matching-dashboard/ai-results/ai-results-data/ai-results-data.component';
import { AiResultsListComponent } from './matching-dashboard/ai-results/ai-results-list/ai-results-list.component';
import { TopKeywordsComponent } from './matching-dashboard/ai-results/top-keywords/top-keywords.component';
import { MatchingDashboardComponent } from './matching-dashboard/matching-dashboard.component';
import { NgChartsModule } from 'ng2-charts';
import { MatchingHistoryComponent } from './matching-history/matching-history.component';
import { PaginatorModule } from '../general/paginator/paginator.module'
import { SharedModule } from '../shared/shared.module';
import { SharedMatchingModule } from './shared/shared.module'
import { DatePipe } from '@angular/common';



@NgModule({
  declarations: [
    MatchingDashboardComponent,
    MatchingComponent,
    MatchingSettingsComponent,
    MatchingKeywordsComponent,
    KeywordEditModalComponent,
    CategoryEditModalComponent,
    MatchingNeighborhoodsComponent,
    NeighborhoodEditModalComponent,
    NeighborhoodKeywordAddModalComponent,
    UserToUsersComponent,
    UserToNeighborhoodsComponent,
    WorkerComponent,
    WorkerParamsComponent,
    GenerateComponent,
    AiResultsComponent,
    AiResultsDataComponent,
    AiResultsListComponent,
    TopKeywordsComponent,
    MatchingHistoryComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AppRoutingModule,
    SharedModule,
    NgIconsModule.withIcons({
      bootstrapClipboard2DataFill,
      bootstrapGearFill,
      bootstrapArrowLeftCircleFill,
      bootstrapArrowRightCircleFill,
      bootstrapTagsFill,
      bootstrapPlusCircleFill,
      bootstrapTrashFill,
      bootstrapPlusLg,
      bootstrapPlus,
      bootstrapPencilFill,
      bootstrapPeopleFill,
      bootstrapPersonFill,
      bootstrapBuilding,
      ionAdd,
      ionAddCircle,
      ionFlask,
      ionTime,
      ionHome
    }),
    NgxSpinnerModule,
    NgChartsModule,
    PaginatorModule,
    SharedMatchingModule
  ],
  exports: [
    MatchingDashboardComponent,
    MatchingComponent
  ],
  providers: [
    DatePipe
  ]
})
export class MatchingModule { }
