import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Bio } from './bios.types';

@Injectable({
  providedIn: 'root'
})
export class BiosService {

  bio: Bio = {
    id: '',
    bio: '',
    keywords: []
  };

  constructor(
    private apiService: ApiService,
  ) {}

  async getRandomBio(): Promise<Bio>{
    return new Promise((resolve, reject) => {
      this.apiService.get<Bio>('/bios/randombio').subscribe({
        next: (response) => {resolve(response);},
        error: (error: HttpErrorResponse) => {reject(error);}
      })
    })
  }

  async generate(n: number = 1): Promise<Bio[]>{
    return new Promise((resolve, reject) => {
      this.apiService.get<Bio[]>('/bios/generate?n=' + n.toString()).subscribe({
        next: (response) => {resolve(response);},
        error: (error: HttpErrorResponse) => {reject(error);}
      })
    })
  }
}
