import { Component, EventEmitter, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Model, Response } from '../ai-results.types';

@Component({
  selector: 'app-ai-results-list',
  templateUrl: './ai-results-list.component.html',
  styleUrls: ['./ai-results-list.component.scss']
})
export class AiResultsListComponent {

  @Output() onClose = new EventEmitter<void>();
  @Output() onClick = new EventEmitter<Model>();

  public models: Model[] = [];
  public currentPage: number = 1;
  public totalPages: number = 1;
  public isLoading: boolean = true;

  constructor(private api: ApiService) {}

  ngOnInit() {
    this.getModels(this.currentPage);
  }

  getModels(page: number): void {
    this.models = []
    //let modelsCached = JSON.parse(localStorage.getItem("models") ?? "{}")
    let modelsCached = JSON.parse("{}")

    if (modelsCached[page]) {
      this.models = modelsCached[page]
      this.totalPages = modelsCached["total_pages"];
      this.isLoading = false
    }
    else {
      this.isLoading = true;
      this.api.get<Response>(`/models?p=${page}`).subscribe((response: Response) => {
      modelsCached[page] = []
      for (const model of response.models) {
        // this.models.push(model);
        modelsCached[page].push(model)
      }
      modelsCached["total_pages"] = response.total_pages
      localStorage.setItem("models", JSON.stringify(modelsCached))

      this.models = modelsCached[page]
      this.totalPages = response.total_pages;
      this.isLoading = false;
    });}
  }

  formatDate(stringDate: string): string {
    const date = new Date(stringDate)
    return date.getFullYear() + "-" +
    (date.getMonth() + 1).toString().padStart(2, '0') + "-" +
    date.getDate().toString().padStart(2, '0') + " " +
    date.getHours().toString().padStart(2, '0') + ":" +
    date.getMinutes().toString().padStart(2, '0');
  }

  isFirstPage(): boolean {
    return this.currentPage == 1
  }

  isLastPage(): boolean {
    return this.currentPage == this.totalPages
  }

  onPageChange(next: boolean) {
    if (next) this.currentPage++;
    else this.currentPage--;
    this.getModels(this.currentPage);
  }
}
