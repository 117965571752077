import { Component, OnInit } from '@angular/core';
import { BiosService } from 'src/app/services/bios.service';
import { MatchingService } from 'src/app/services/matching.service';
import { MatchResponse } from '../../../../services/matching.types';
import { Bio } from '../../../../services/bios.types';
import { NeighborhoodsService } from '../../services/neighborhoods.service';
import { Neighborhood } from '../../services/neighborhoods.types';
import { KeywordsService } from '../../services/keywords.service';
import { Keyword, KeywordCategory } from '../../services/keywords.types';
import { NgxSpinnerService } from 'ngx-spinner';




@Component({
  selector: 'app-user-to-neighborhoods',
  templateUrl: './user-to-neighborhoods.component.html',
  styleUrls: ['./user-to-neighborhoods.component.scss']
})
export class UserToNeighborhoodsComponent implements OnInit {
  
  maxUsersNumber = 30;
  bio: Bio | undefined = undefined;
  neighborhoods: Neighborhood[] = [];
  expandedBio: number | undefined;
  response: MatchResponse | undefined;
  keywordCategories: KeywordCategory[] = [];
  private _loading = false;

  constructor(
    private biosService: BiosService,
    private matchingService: MatchingService,
    private neighborhoodsService: NeighborhoodsService,
    private keywordsService: KeywordsService,
    private spinner: NgxSpinnerService
    ) {}
  
  async ngOnInit(): Promise<void> {
    this.loading = true;
    const kwPromise = this.getKeywordCategories();
    const nhPromise = this.getNeighborhoods();
    const usPromise = this.generate();
    await kwPromise;
    await nhPromise;
    await usPromise;
    this.match();
    this.loading = false;
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    if (value) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
    this._loading = value;
  }

  get matchNumberText(): string {
    if (!this.response) return '';
    let c = 0;
    for (const match of this.response.matches)
      if (match.match_level !== 'neutral')
        c++;
    if (c <= 0) return 'No match'
    else if (c === 1) return '1 match'
    else if (c >= 2) return c.toString() + ' matches'
    return ''
  }

  async match() {
    if (!this.bio || this.neighborhoods.length <= 0 || this.keywordCategories.length <= 0) return;
    const primarySubject = {id: this.bio.id};
    const secondarySubjects = [];
    for (const neighborhood of this.neighborhoods) { secondarySubjects.push({id: neighborhood.matchingId}); }
    this.response = await this.matchingService.match(primarySubject, secondarySubjects);
  }

  async getKeywordCategories() {
    this.keywordCategories = await this.keywordsService.getCategories();
    this.response = undefined;
  }

  isCommonKeyword(keyword: string, index: number) {
    if (!this.response?.matches[index]) return false;
    for (const kw of this.response?.matches[index].common_keywords_raw)
      if (kw.keyword === keyword) return true;
    return false;
  }

  getCategorizedKeyword(id: string): Keyword | undefined {
    for (const category of this.keywordCategories) {
      for (const keyword of category.keywords) {
        if (keyword._id === id) {
          return keyword;
        }
      }
    }
    return undefined;
  }

  async getNeighborhoods() {
    this.neighborhoods = await this.neighborhoodsService.getNeighborhoods();
    this.response = undefined;
  }


  async generate() {
    this.bio = (await this.biosService.generate(1))[0];
    this.response = undefined;
    this.match();
  }


  async toggleExpand(index: number) {
    if (this.expandedBio === index) {
      this.expandedBio = undefined;
    } else {
      this.expandedBio = index;
    }
  }

  async delay(ms: number) { 
    return new Promise(res => setTimeout(res, ms));
  }
}
