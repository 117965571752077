<div class="row border-bottom py-2 fs-14">
    <div class="col">
        {{ invoice.amount_due / 100 | currency: 'CAD' : 'symbol-narrow' : '1.0-2' }}
    </div>
    <div class="col">
        {{ invoice.amount_remaining / 100 | currency: 'CAD': 'symbol-narrow': '1.0-2'}}
    </div>
    <div class="col">
        {{ invoice.customer_name }}
    </div>
    <div class="col">
        {{ invoice.customer_email }}
    </div>
    <div class="col">
        {{ getDate(invoice.created) | date: 'mediumDate'}}
    </div>
    <div class="col">
        <a [href]="getInvoiceUrlForStripe(invoice.id)">{{ getDate(invoice.due_date) | date: 'mediumDate'}}</a>
        
    </div>
    <div class="col">
        <button
            type="button"
            class="btn btn-primary"
            (click)="resendInvoice(invoice)">
            Resend
        </button>
    </div>
    <div class="col">
        <ng-container *ngIf="invoice.subscription && invoice.subscription.metadata.requestId">
            <a [routerLink]="['/reservation', invoice.subscription.metadata.requestId]">Link</a>
        </ng-container>
        <ng-container *ngIf="!invoice.subscription">
            -
        </ng-container>
         
    </div>
</div>