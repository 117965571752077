<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Created Models</h4>
    <button
        type="button"
        class="btn-close"
        aria-label="Close button"
        aria-describedby="modal-title"
        (click)="onClose.next()"
    ></button>
</div>
<div class="modal-body">
    <ul *ngIf="!isLoading" class="list-group">
        <div *ngIf="models.length > 0" class="list-group-item flex-column align-items- mw-100 text-white bg-dark">
            <div class="d-flex w-100 justify-content-between row">
                <small class="col-3">Model name</small>
                <small class="col-5 col-md-5">Creation date</small>
                <small class="col-2 text-center" placement="top" ngbTooltip="Davies-Bouldin score" container="body" tooltipClass="custom-tooltip" role="button">
                    D-B
                </small>
                <small class="col-2 text-center" placement="top" ngbTooltip="Silhouette score" container="body" tooltipClass="custom-tooltip" role="button">
                    S
                </small>
            </div>
        </div>

        <!-- No model -->
        <div *ngIf="models.length < 1" class="d-flex justify-content-center fs-5">
            There is no model to show
        </div>

        <!-- ´show models -->
        <ng-container *ngIf="models.length > 0">
            <a
                role="button"
                (click)="onClick.next(model)"
                *ngFor="let model of models"
                class="cursor-pointer list-group-item list-group-item-action flex-column align-items- mw-100"
                [class.current-model]="model.current_model"
            >
                <div class="d-flex w-100 justify-content-between row position-relative">
                    <h6 class="col-3 mb-0">{{model.model_tag}}</h6>
                    <h6 class="col-5 mb-0">{{formatDate(model.results.created)}}</h6>
                    <small class="col-2 text-center">{{model.results.scores.davies_bouldin.toFixed(2)}}</small>
                    <small class="col-2 text-center">{{model.results.scores.silhouette.toFixed(3)}}</small>                
                </div>
            </a>
        </ng-container>
    </ul>
    <div *ngIf="isLoading" class="d-flex justify-content-center align-items-center" style="min-height: 229px;">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
</div>
<div class="modal-footer d-flex" [ngClass]="models.length > 0 ? 'justify-content-between': 'justify-content-end'">
    <ul *ngIf="models.length > 0" class="pagination align-self-center">
        <li class="page-item" [ngClass]="{'disabled': isFirstPage()}"><button class="page-link" (click)="onPageChange(false)"><i class="bi bi-chevron-left px-2"></i></button></li>
        <li class="page-item" [ngClass]="{'disabled': isLastPage()}"><button class="page-link" (click)="onPageChange(true)"><i class="bi bi-chevron-right px-2"></i></button></li>
    </ul>
    <small *ngIf="models.length > 0">Page {{currentPage}} out of {{totalPages}}</small>
    <button type="button" ngbAutofocus class="btn btn-dark" (click)="onClose.next()">Close</button>
</div>