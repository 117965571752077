import { DateUtil } from "src/app/utils/dateUtil";

const moment = require('moment');

export class RecurringPeriod {
    str: string = '';
    start: Date;
    end: Date;
    formatter: string = 'll';

    constructor(startDate: Date, endDate: Date) {
        this.start = startDate;
        this.end = endDate;
        this.str = this.format();
    }

    private formatHelper(formatter: string = this.formatter) {
        const momentStart = moment(this.start);
        const momentEnd = moment(this.end); 

        this.formatter = formatter;
        if (momentStart.year() === momentEnd.year() 
            && momentStart.month() === momentEnd.month()
            && momentStart.date() === 1
            && momentEnd.date() === momentEnd.daysInMonth()
        ) {
            this.formatter = 'MMM yyyy';
            this.str = moment(this.start).format(this.formatter); 
        } else {
            this.str = `${moment(this.start).format(this.formatter)} - ${moment(this.end).format(this.formatter)}`;
        }
    }

    public format(formatter: string = this.formatter): string {
        this.formatHelper();
        return this.str;
    }

    public isFirstPeriodOf(reservation: any): boolean {
        const dateUtil: DateUtil = new DateUtil();
        const firstPayoutEnd = moment(reservation.moveInDate).endOf('month').add(1, 'month').toDate();

        return (
            dateUtil.isSameDate(reservation.moveInDate, this.start)
            && dateUtil.isSameDate(firstPayoutEnd, this.end)
        )
    }

    public isLastPeriodOf(reservation: any): boolean {
        const dateUtil: DateUtil = new DateUtil();
        const lastPayoutStart = moment(reservation.moveOutDate).startOf('month').toDate();

        return (
            dateUtil.isSameDate(reservation.moveOutDate, this.end)
            && dateUtil.isSameDate(lastPayoutStart, this.start)
        )
    }
}