import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from '../utils/utils';
import { Reservation } from '../models/rent-requests/reservation';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class RentRequestsService {
  constructor(
    private utils: Utils,
    private http: HttpClient,
    private authService: AuthService
  ) {}

  getReservationsByUserId(id: string, option?: string) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = '';
    if (typeof option !== 'undefined') {
      url = `${this.utils.getAPIUrl()}admin/user/${id}/reservations?option=${option}`;
    } else {
      url = `${this.utils.getAPIUrl()}admin/user/${id}/reservations`;
    }
    return this.http.get<[]>(url, { headers });
  }

  getPendingRequestsByUserId(id: string) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    const url = `${this.utils.getAPIUrl()}admin/user/${id}/pending-requests`;
    return this.http.get<[]>(url, { headers });
  }

  private _addQueryField(url: string, fieldName: string, field: string) {
    if (field.length > 0) return `${url}&${fieldName}=${field}`;
    return `${url}&${fieldName}`;
  }

  getReservations(
    city: string,
    status: string,
    search: string,
    skip: number,
    limit: number
  ) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = this.utils.getAPIUrl() + 'admin/reservations?';
    if (city.length > 0 && city.toLowerCase() != 'all')
      url = this._addQueryField(url, 'city', city);
    if (status.length > 0) url = this._addQueryField(url, 'status', status);
    if (search.length > 0) url = this._addQueryField(url, 'search', search);
    url = this._addQueryField(
      this._addQueryField(url, 'skip', skip.toString()),
      'limit',
      limit.toString()
    );
    return this.http.get<Array<Reservation>>(url, { headers });
  }

  getUpcomingReservation(secondsBefore: number) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = this.utils.getAPIUrl() + 'admin/reservations?status=upcoming';
    if (secondsBefore >= 0) {
      url = this._addQueryField(url, 'before', secondsBefore.toString());
    }
    return this.http.get<Array<Reservation>>(url, { headers });
  }

  getReservationById(id: string) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = this.utils.getAPIUrl() + 'admin/reservations/' + id;
    return this.http.get<Reservation>(url, { headers });
  }

  getTotalRentForEntireStay(id: string) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = this.utils.getAPIUrl() + 'rent-requests/' + id + '/calculate-rent';
    return this.http.get<any>(url, { headers });
  }

  changeReservationPrice(id: string, newPrice: number) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = this.utils.getAPIUrl() + 'admin/reservations/change-price';
    return this.http.post<any>(
      url,
      {
        rentRequestId: id,
        newPrice,
      },
      { headers }
    );
  }

  changeReservationDate(
    id: string,
    newMoveInDate: string,
    newMoveOutDate: string
  ) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = this.utils.getAPIUrl() + 'admin/reservations/change-dates';
    return this.http.post<any>(
      url,
      {
        rentRequestId: id,
        newMoveInDate,
        newMoveOutDate,
      },
      { headers }
    );
  }

  cancelReservation(id: string) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = this.utils.getAPIUrl() + 'admin/reservations/cancel';
    return this.http.post<any>(
      url,
      {
        rentRequestId: id,
      },
      { headers }
    );
  }

  calculateTotalPaid(reservation: Reservation) {
    let sum = 0;
    for (let i = 0; i < reservation.paymentStatusUser.length; ++i) {
      if (
        reservation.paymentStatusUser[i].status == 'PAID' &&
        reservation.paymentStatusUser[i].transactionId
      ) {
        sum += reservation.paymentStatusUser[i].transactionId.amount;
      }
    }
    if (
      sum == 0 &&
      reservation.transactionId &&
      reservation.transactionId.status == 'processed'
    ) {
      sum = reservation.transactionId.amount;
    }
    return sum;
  }

  getPendingRequests(
    params: {
      field: string;
      value: any;
    }[]
  ) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = `${this.utils.getAPIUrl()}admin/booking-requests?`;
    for (let param of params) {
      url = this._addQueryField(url, param.field, param.value.toString());
    }
    return this.http.get<any[]>(url, { headers });
  }

  getPendingRequestById(id: string) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = `${this.utils.getAPIUrl()}admin/booking-requests/${id}`;
    return this.http.get<any>(url, { headers });
  }

  sendEmailToHost(id: string) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = `${this.utils.getAPIUrl()}admin/resendBookingRequest/${id}`;
    return this.http.get<any>(url, { headers });
  }

  update(pendingRequest: any) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = `${this.utils.getAPIUrl()}admin/booking-requests/${
      pendingRequest._id
    }`;
    return this.http.put<any>(url, pendingRequest, { headers });
  }

  sendEmailToAdmin(id: string, userId: string) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = `${this.utils.getAPIUrl()}admin/send-email`;
    return this.http.post<any>(
      url,
      {
        id: id,
        to: 'admin',
        userId,
      },
      { headers }
    );
  }

  decline(payload: any) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = `${this.utils.getAPIUrl()}admin/booking-requests/decline`;
    return this.http.post<any>(url, payload, { headers });
  }

  swtichRooms(payload: any) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = `${this.utils.getAPIUrl()}admin/booking-requests/switch-rooms`;
    return this.http.post<any>(url, payload, { headers });
  }

  changeBookingRequestPrice(id: string, newPrice: number) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = this.utils.getAPIUrl() + 'admin/booking-requests/change-price';
    return this.http.post<any>(
      url,
      {
        rentRequestId: id,
        newPrice,
      },
      { headers }
    );
  }

  generateAgreementPDF(id: string) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url =
      this.utils.getAPIUrl() +
      'admin/reservations/generate-rental-agreement-pdf';
    return this.http.post<any>(
      url,
      {
        rentRequestId: id,
      },
      { headers }
    );
  }

  sendRentalAgreementToGuest(id: string) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url =
      this.utils.getAPIUrl() +
      'admin/reservations/send-rental-agreement-to-guest';
    return this.http.post<any>(
      url,
      {
        rentRequestId: id,
      },
      { headers }
    );
  }
}
