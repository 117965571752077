import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageModalComponent } from '../../general/modal/message-modal/message-modal.component';
import { StripeService } from 'src/app/services/stripe.service';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/utils/utils';
import { DateUtil } from 'src/app/utils/dateUtil';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  @Input() invoice: any = {} as any;

  constructor(
    private modalService: NgbModal,
    private stripeService: StripeService,
    private api: ApiService,
  ) { }

  ngOnInit(): void {
  }

  getDate(sec: number) {
    let date = new Date();
    date.setTime(sec * 1000);
    return date;


  }

  resendInvoice(invoice: any): void {
    console.log("resend invoice to " + invoice.id);
    const modalRef = this.modalService.open(MessageModalComponent);
    modalRef.componentInstance.title = "Sending...";
    modalRef.componentInstance.message = "Sending Email...";
    this.stripeService.resendInvoice(
      invoice.id
    ).subscribe(resp => {
      modalRef.componentInstance.title = "Success";
      modalRef.componentInstance.message = "Invoice Sent";
      console.log(resp);
    }, err => {
      console.log(err);
      modalRef.componentInstance.title = "Error";
      modalRef.componentInstance.message = "err";
    }) 
  }

  getInvoiceUrlForStripe(invoiceId: string): string {
    return `${environment.invoiceUrl}${invoiceId}?account=${environment.stripeAccountId}`
  }

}
