import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apartment } from '../models/apartment/apartment';
import { Utils } from '../utils/utils';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ListingService {

  constructor(
    private util: Utils,
    private http: HttpClient,
    private authService: AuthService
  ) { }
  
  private _addQueryField(url: string, fieldName: string, field: string) {
    if (field.length > 0)
      return `${url}&${fieldName}=${field}`
    return `${url}&${fieldName}`;
  }

  searchApartments(params: {
    field: string,
    value: any
  }[]) {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    let url = `${this.util.getAPIUrl()}admin/apartments?`;
    for (let param of params) {
      url = this._addQueryField(url, param.field, param.value.toString())
    }
    return this.http.get<Array<Apartment>>(url, {headers});
  }

  getApprovedRooms(id: String) {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    let url = `${this.util.getAPIUrl()}admin/apartments/${id}/approved` 
    return this.http.get<Array<any>>(url, {headers});
  }

  getApartmentById(id: string | null) {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    let url = `${this.util.getAPIUrl()}admin/apartments/${id}`;
    return this.http.get<Apartment>(url, {headers});
  }

  isInReservation(moveInDate: Date, moveOutDate: Date): boolean {
    return false;
  }

  getApartmentsWithOwnerId(id: string) {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    let url = `${this.util.getAPIUrl()}admin/user/${id}/apartments`;
    return this.http.get<[]>(url, {headers});
  }

  updateApartment(id: string, body: any) {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      ); 
    body.updateInfo = {
      performer: 'admin'
    }
    let url = `${this.util.getAPIUrl()}admin/apartments/${id}`;
    return this.http.put(url, body, { headers })
  }

  isWholeApartment(apartment: any): boolean {
    return apartment.wholeApartment;
  }

  isOccupied(apartment: any): boolean {
    return apartment.occupied;
  }

  isAvailable(apartment: any, bookedRooms: any): boolean {
    return !this.isOccupied(apartment) 
      && bookedRooms.length == 0;
  }

  isPartiallyBooked(apartment: any, bookedRooms: any): boolean {
    return !this.isOccupied(apartment) 
      && !this.isWholeApartment(apartment) 
      && bookedRooms.length < apartment.rooms.length 
      && bookedRooms.length > 0;
  }

  // to determine if it is fully booked
  isBooked(apartment: any, bookedRooms: any) {
    return (
      (!this.isOccupied(apartment) && this.isWholeApartment(apartment) && bookedRooms.length > 0) ||
      (!this.isOccupied(apartment) && !this.isWholeApartment(apartment) && bookedRooms.length > 0 && bookedRooms.length >= apartment.rooms.length)
    )
  }

  getBookedRooms(apartment: any) {
    let res: {
      moveInDate: Date,
      moveOutDate: Date,
      id: string
    }[] = [];
    if (!('rentRequests' in apartment)) apartment.rentRequests = apartment.pendingRequests;
    for (let i = 0; i < apartment.rentRequests.length; ++i) {
        const request = apartment.rentRequests[i];
        for (let id of request.roomIds) {
          let flag = false;
          for (let item of res) {
            if (item.id == id) {
              flag = true;
              break;
            }
          }
          if (!flag) {
            res.push({
              moveInDate: request.moveInDate,
              moveOutDate: request.moveOutDate,
              id: id
            })
          }
        }
    }  
    return res;
  }

  
}