import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { WorkersService } from 'src/app/services/workers.service';
import { Task, Worker } from 'src/app/services/workers.types';

@Component({
  selector: 'app-generate',
  templateUrl: './generate.component.html',
  styleUrls: ['./generate.component.scss']
})
export class GenerateComponent implements OnInit, OnDestroy {

  @Input() workerId = '';

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  
  worker: Worker | undefined = undefined;
  tasksCollapsed = true;

  constructor(
    private workersService: WorkersService
  ) {}

  ngOnInit(): void {
    // Update worker
    this.workersService.getWorker(this.workerId)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((worker) => {
      this.worker = worker;
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  getRunningLabel(): string {
    if (this.worker === undefined) {
      return 'Idle'
    }
    else if (this.worker.state.state === 'RUNNING') {
      const task = this.getTask(this.worker.state.current_task);
      if (task) {
        return 'Task ' + (this.worker.state.current_task + 1).toString() +
               '/' + this.worker.tasks.length + ' - ' + task.running_label;

      } else {
        return 'Initializing...'
      }
    } else {
      return 'Idle'
    }
  }

  getTask(index: number): Task | undefined {
    if (this.worker === undefined) {
      return undefined;
    }
    if (index < 0 || index > this.worker.tasks.length) {
      return undefined;
    }
    return this.worker.tasks[index];
  }

  getTaskStatus(index: number): string {
    if (this.worker === undefined || this.worker.state.current_task === -1)
      return ''
    else if (index === this.worker.state.current_task)
      return (this.worker.state.task_progress * 100.0).toFixed(1) + '%'
    else if (index < this.worker.state.current_task)
      return '100%.'
    else
      return ''
  }

  getTaskProgress(index: number): number {
    if (this.worker === undefined || this.worker.state.current_task === -1)
      return 0.0
    else if (index === this.worker.state.current_task)
      return this.worker.state.task_progress * 100.0
    else if (index < this.worker.state.current_task)
      return 100.0
    else
      return 0.0
  }

  start() {
    if (this.worker)
      this.workersService.start(this.worker.id);
  }

  stop() {
    if (this.worker)
      this.workersService.stop(this.worker.id);
  }
  

}
