import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIconsModule } from '@ng-icons/core';
import { bootstrapArrowLeftCircleFill, bootstrapArrowRightCircleFill, bootstrapBuilding, bootstrapClipboard2DataFill, bootstrapGearFill, bootstrapPencilFill, bootstrapPeopleFill, bootstrapPersonFill, bootstrapPlus, bootstrapPlusCircleFill, bootstrapPlusLg, bootstrapTagsFill, bootstrapTrashFill } from '@ng-icons/bootstrap-icons'
import { ionAdd, ionAddCircle, ionFlask, ionHome, ionTime } from '@ng-icons/ionicons'
import { NgxSpinnerModule } from 'ngx-spinner';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    SidebarComponent
  ],
  imports: [
    CommonModule,
    NgIconsModule.withIcons({ 
      bootstrapClipboard2DataFill, 
      bootstrapGearFill, 
      bootstrapArrowLeftCircleFill,
      bootstrapArrowRightCircleFill,
      bootstrapTagsFill,
      bootstrapPlusCircleFill,
      bootstrapTrashFill,
      bootstrapPlusLg,
      bootstrapPlus,
      bootstrapPencilFill,
      bootstrapPeopleFill,
      bootstrapPersonFill,
      bootstrapBuilding,
      ionAdd,
      ionAddCircle,
      ionFlask,
      ionTime,
      ionHome
    }),
    NgxSpinnerModule,
    NgbModule
  ],
  exports: [
    SidebarComponent
  ]
})
export class SharedModule { }
