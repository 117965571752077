<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Parameters</h4>
    <button
        type="button"
        class="btn-close"
        aria-label="Close button"
        aria-describedby="modal-title"
        (click)="modal.dismiss()"
    ></button>
</div>
<div class="modal-body">

    <div class="d-flex flex-column">
    <!-- Section -->
        <ul class="nav nav-tabs">
            <li class="nav-item" *ngFor="let section of sections">
                <a class="nav-link" role="button" [class.active]="activeSection === section.name" (click)="activeSection=section.name">{{section.name}}</a>
            </li>
        </ul>
        <ng-container *ngFor="let section of sections">
            <div *ngIf="activeSection === section.name" class="d-flex flex-column gap-2 p-2 border border-top-0 rounded-bottom">
                <!-- Parameter -->
                    <div
                        *ngFor="let param of section.params"
                        class="mw-100"
                    >
                        <div class="form-group d-flex flex-column">
                            <label class="form-label">{{param.infos.ui.name}}</label>
                            <!-- String -->
                            <ng-container *ngIf="param.infos.ui.type === 'string'">
                                <input class="form-control" [formControl]="param.control">
                            </ng-container>
                            <!-- Enum -->
                            <ng-container *ngIf="param.infos.ui.type === 'enum'">
                                <select class="form-select" [formControl]="param.control">
                                    <option *ngFor="let option of param.infos.ui.enum" [value]="option.value">{{option.name}}</option>
                                  </select>
                            </ng-container>
                            <!-- Float / Integer -->
                            <div *ngIf="param.infos.ui.type === 'integer' || param.infos.ui.type === 'float'" class="row mx-2">
                                <input #slider class="form-range px-0" style="width: 90%" type="range" [formControl]="param.control" min="{{param.infos.ui.min}}" max="{{param.infos.ui.max}}" step="{{param.infos.ui.step}}">
                                <div class="text-center p-0" style="width: 10%" >{{slider.value}}</div>
                            </div>
                            <!-- Boolean -->
                            <div *ngIf="param.infos.ui.type === 'boolean'">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch" [formControl]="param.control">
                                  </div>
                            </div>
                            <small class="form-text text-muted">{{param.infos.ui.description}}</small>
                        </div>
                    </div>
            </div>
        </ng-container>
            
    </div>
</div>
<div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-dark"    (click)="modal.dismiss()">Cancel</button>
    <button type="button" ngbAutofocus class="btn btn-primary" (click)="save()">Save</button>
</div>