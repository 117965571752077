import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
})
export class FeedbackComponent implements OnInit {
  routes = [
    {
      name: 'Questions',
      href: '/feedback/questions',
      icon: 'ionDocumentText',
    },
    {
      name: 'Answers',
      href: '/feedback/answers',
      icon: 'ionStatsChart',
    },
    {
      name: 'Pending',
      href: '/feedback/pending',
      icon: 'bootstrapBellFill',
    },
    {
      name: 'Settings',
      href: '/feedback/settings',
      icon: 'bootstrapGearFill',
    },
  ]

  constructor(public router: Router) {}

  ngOnInit(): void {}
}
