import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Route, RouteChild } from './sidebar.types';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @Input() routes: Route[] = [];

  sidenavCollapsed = false;
  collapsedRoutes: boolean[] = [];
  sidenavCollapsing = false;
  currentRouteIndex: number = -1;

  constructor(public router: Router) { }

  ngOnInit(): void {
    this.collapsedRoutes = new Array(this.routes.length).fill(true);
    for (let i = 0; i < this.routes.length; i++) {
      const route = this.routes[i];
      if (route.children && route.children.length > 0) {
        for (const child of route.children) {
          if (child.href === this.router.url) {
            this.currentRouteIndex = i;
          }
        }
      }
      else if (this.routes[i].href === this.router.url) {
        this.currentRouteIndex = i;
      }
    }
    this.applyCurrentRoute();
  }

  ngAfterViewInit(): void {
    
  }

  toggleSidenav() {
    if (this.sidenavCollapsing) return;
    if (this.sidenavCollapsed)
      this.expandSidenav()
    else
      this.collapseSidenav();
  }

  collapseSidenav() {
    if (this.sidenavCollapsing) return;
    this.sidenavCollapsing = true;
    if (this.collapsedRoutes.every(x => x === true))
      this.onRoutesCollapseEnd();
    else
      this.collapsedRoutes = new Array(this.routes.length).fill(true);
  }

  expandSidenav() {
    if (this.sidenavCollapsing) return;
    this.sidenavCollapsing = true;
    this.sidenavCollapsed = false;
  }

  onSidenavCollapseEnd() {
    this.sidenavCollapsing = false;
  }

  onSidenavExpandEnd() {
    this.sidenavCollapsing = false;
    this.applyCurrentRoute();
  }

  onRoutesCollapseEnd() {
    if (!this.sidenavCollapsing) return;
    this.sidenavCollapsed = true;
  }

  onRoutesExpandEnd() {
    if (this.sidenavCollapsing) this.sidenavCollapsing = false;
  }

  

  collapseAllRoutes() {
    this.collapsedRoutes = new Array(this.routes.length).fill(true);
  }

  expandAllRoutes() {
    this.collapsedRoutes = new Array(this.routes.length).fill(false);
  }


  onRouteClick(id: number) {
    if (this.sidenavCollapsing) return;
    const route = this.routes[id];
    this.currentRouteIndex = id;
    if (route.children && route.children.length > 0)
      if (this.sidenavCollapsed)
        this.expandSidenav();
    this.applyCurrentRoute();
  }

  onChildClick(child: RouteChild, event: Event) {
    event.stopPropagation();
    this.router.navigate([child.href]);
  }

  applyCurrentRoute() {
    const currentRoute = this.routes[this.currentRouteIndex];
    let url: string | undefined;
    if (currentRoute.children && currentRoute.children.length > 0) {
      let needsNavigate = true;
      for (const child of currentRoute.children) {
        if (child.href === this.router.url) {
          needsNavigate = false;
          break;
        }
      }
      if (needsNavigate) url = currentRoute.children[0].href;
      this.collapseAllRoutes();
      this.collapsedRoutes[this.currentRouteIndex] = false;
    }
    else {
      this.collapseAllRoutes();
      if (this.router.url !== currentRoute.href) url = currentRoute.href;
    }

    if (url) this.router.navigate([url]);
  }

}
