import { Component, OnInit, Input } from '@angular/core';
import { Reservation } from 'src/app/models/rent-requests/reservation';
import { RentRequestsService } from 'src/app/services/rent-requests.service';
import { RENT_REQUEST_STATUS } from 'src/app/utils/constant';
import { DateUtil } from 'src/app/utils/dateUtil';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {
  @Input() reservation: any = null as any;

  constructor(
    private rentRequestService: RentRequestsService,
    public dateUtil: DateUtil
  ) { }

  ngOnInit(): void {
  }

  isUpComing(): boolean {
    return new Date(this.reservation.moveInDate) > (new Date());
  }

  isCompleted(): boolean {

    return new Date(this.reservation.moveOutDate) < (new Date());
  }

  isActive(): boolean {
    const now = new Date();
    return new Date(this.reservation.moveInDate) < now && new Date(this.reservation.moveOutDate) > now;
  }

  isCancelled(): boolean {	  
    return this.reservation.status === 'DECLINED';	    
  }

  isCanceledByAdmin(): boolean {
    return this.reservation.status === RENT_REQUEST_STATUS.ADMIN_CANCELLED;
  }

  calculateTotalPaid() {
    return this.rentRequestService.calculateTotalPaid(this.reservation);
  }
  

}
