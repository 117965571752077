<mat-card>
    <mat-card-header>
        <mat-card-title>{{data.title}}</mat-card-title>
        <mat-card-subtitle>
            <p>
                {{data.subtitle}}
            </p>
            <p>
                {{ data.requestId }}
            </p>
            
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-grid-list cols="1" rowHeight="4:1">
          <mat-grid-tile>
              <mat-form-field appearance="fill" floatLabel="always">
                  <mat-label>Amount Due</mat-label>
                  <input matInput type="number" placeholder="0" [(ngModel)]="data.amountDue">
                  <span matPrefix>$&nbsp;</span>
              </mat-form-field> 
          </mat-grid-tile>
          <mat-grid-tile>
              <mat-form-field appearance="fill" floatLabel="always">
                  <mat-label>Actual Paid</mat-label>
                  <input matInput type="number" placeholder="0" [(ngModel)]="data.actualPaid">
                  <span matPrefix>$&nbsp;</span>
              </mat-form-field>  
          </mat-grid-tile>
          <mat-grid-tile>
              <mat-form-field appearance="fill">
                  <mat-label>Leave a Comment</mat-label>
                  <textarea matInput [(ngModel)]="data.note"></textarea>
              </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
    </mat-card-content>
    <mat-card-actions>
        <button mat-button [mat-dialog-close]="data">SAVE</button>
        <button mat-button (click)="onNoClick()">CANCEL</button>
    </mat-card-actions>
</mat-card>