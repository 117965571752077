import { Component, OnInit } from '@angular/core';
import { RentRequestsService } from 'src/app/services/rent-requests.service';
import { Reservation } from 'src/app/models/rent-requests/reservation';
import { Page } from 'src/app/models/general/page';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatorService } from 'src/app/services/paginator.service';
import { CityService } from 'src/app/services/city.service';
import { Utils } from 'src/app/utils/utils';
import { LoginService } from 'src/app/services/login.service';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css']
})
export class ReservationsComponent implements OnInit {
  selected: string = 'Montreal';
  selectedStatus: string = "All Reservations";
  reservationStatuses: string[] = [
    "Upcoming",
    "Completed",
    "Cancelled",
    "Active",
    "All Reservations"
  ]
  cities: string[] = [];
  query: string = "";
  reservations: Reservation[] = [];
  page: number = 1;
  totalSize: number = 0;

  constructor(
    private rentRequestService: RentRequestsService,
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    public pageService: PaginatorService,
    private router: Router,
    private cityService: CityService,
    private utils: Utils,
    public layoutService: LayoutService
  ) { }

  changeCity(city: string) {
    this.selected = city;
    this.router.navigate(['/reservation'], {
      queryParams: { city: this.selected, page: 1 },
      queryParamsHandling: 'merge'
    });
  }

  changeStatus(status: string) {
    this.selectedStatus = status;

    this.router.navigate(['/reservation'], {
      queryParams: { 
        status: this.selectedStatus, 
        page: 1 
      },
      queryParamsHandling: 'merge'
    });
  }

  doSearch() {
    this.router.navigate(
      ['/reservation'],
      {
        queryParams: {
          query: this.query,
          page: 1
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  searchReservations() {
    
    this.rentRequestService.getReservations(
      this.utils.capitalize(this.selected), 
      this.selectedStatus, 
      this.query,
      (this.page - 1) * this.pageService.getPageSize(),
      this.pageService.getPageSize()
    )
      .subscribe(resp => {
        this.reservations = (resp as any).reservations;
        this.totalSize = (resp as any).total;
      }, error => {
        this.router.navigate(['/login'])    
      })
  }

  onPageChange(pageEvent: Page) {
    this.router.navigate(
      ['/reservation'],
      {
        queryParams: {
          page: pageEvent.index
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  setPage(pg: number): void {
    this.page = pg;
  }

  ngOnInit(): void {
    this.cityService.getCities().subscribe((resp: any) => {
      resp.forEach((cityInfo: any) => {
        this.cities.push(cityInfo.name);
      })
    })
    
    this.cities.push("All");

    this.api.subscribeToAPIChange((prod: boolean) => {
      this.reservations = [];
      this.router.navigate(['/reservation']);
    })

    const currentQueryParams = this.activatedRoute.snapshot.queryParams;
    this.readQueryParameters(currentQueryParams);

    this.activatedRoute.queryParams.subscribe(params => {
      this.readQueryParameters(params);
      this.searchReservations();
    })
  }

  readQueryParameters(params: any): void {
    if ('city' in params) {
      this.selected = params['city'];
    } else {
      this.selected = 'Montreal';
    }

    if ('status' in params) {
      this.selectedStatus = params['status'];
    } else {
      this.selectedStatus = this.reservationStatuses[0];
    }

    if ('query' in params) {
      this.query = params['query'];
    } else {
      this.query = ""
    }

    if ('page' in params) {
      this.setPage(Number(params['page']));
    } else {
      this.page = 1;
    }
  }

}
