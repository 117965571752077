import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AiResultsComponent } from './ai-results/ai-results.component'

@Component({
  selector: 'app-matching-dashboard',
  templateUrl: './matching-dashboard.component.html',
  styleUrls: ['./matching-dashboard.component.scss']
})
export class MatchingDashboardComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  public aiVisualizer = AiResultsComponent;
  
  constructor() { }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}

