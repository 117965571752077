import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user/user';
import { Page } from 'src/app/models/general/page';
import { ApiService } from 'src/app/services/api.service';
import { PaginatorService } from 'src/app/services/paginator.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { LayoutService } from 'src/app/services/layout.service';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  userStatus: string[] = [
    "All Users",
    "Verified",
    "Unverified",
    "Blocked",
  ]
  selectedStatus: string = "All Users";
  query: string = "";
  users: Array<User> = null as any;
  page: number = 1;
  totalSize: number = 0;

  changeStatus(status: string) {
    this.selectedStatus = status;
    this.router.navigate(['/user'], {
      queryParams: { status: this.selectedStatus, page: 1 },
      queryParamsHandling: "merge"
    })
  }

  searchUsers() {
    this.userService.getAllUsers(
      this.selectedStatus, 
      this.query,
      (this.page - 1) * this.pageService.getPageSize(),
      this.pageService.getPageSize()
    ).subscribe(resp => {
      this.loginService.setLoggedIn(true);
      this.users = (resp as any).users;
      this.totalSize = (resp as any).total;
    }, error => {
      this.loginService.setLoggedIn(false);
      this.router.navigate(['/login']);
    })
  }

  constructor(
    private userService: UserService,
    private api: ApiService,
    private loginService: LoginService,
    public pageService: PaginatorService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    // subscribe to API change
    this.api.subscribeToAPIChange((prod: boolean) => {
      this.users = []
      this.router.navigate(['/user']);
    }) 

    // deal with current query parameter
    this.readQueryParameters(this.activatedRoute.snapshot.queryParams);
    this.router.navigate(['/user'], {
      queryParams: this.activatedRoute.snapshot.queryParams,
      queryParamsHandling: "merge"
    });

    // subscribe to query params change
    this.activatedRoute.queryParams.subscribe(params => {
      this.readQueryParameters(params);
      this.searchUsers();
    })
  }

  setPage(pg: number): void {
    this.page = pg;
  }

  onPageChange(pageEvent: Page) {
    this.router.navigate(
      ['/user'],
      {
        queryParams: {
          page: pageEvent.index
        },
        queryParamsHandling: "merge"
      }
    )
  }
  
  openUser(id: string) {
    window.open(
      '/user/' + id,
      '_blank'
    )
  }

  doSearch(): void {
    this.router.navigate(['/user'], {
      queryParams: {
        query: this.query,
        page: 1
      },
      queryParamsHandling: "merge"
    });
  }

  readQueryParameters(params: any): void {
    if ('status' in params) {
      this.selectedStatus = params['status'];
    } else {
      this.selectedStatus = this.userStatus[0];
    }

    if ('query' in params) {
      this.query = params['query'];
    } else {
      this.query = "";
    }

    if ('page' in params) {
      this.page = params['page']
    } else {
      this.page = 1;
    }
  }

}
