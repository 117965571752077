import { Component, Input, OnInit } from '@angular/core';
import { Constant } from 'src/app/utils/constant';

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.css']
})
export class CheckboxListComponent implements OnInit {
  @Input() indexes: any[] = [];
  @Input() values: string[] = [];
  @Input() indexStart: number = 0;
  @Input() indexEnd: number = -1;

  hasIndex(id: number): boolean {
    for (let index of this.indexes)
      if (index.id == id) return true;
    return false;
  }

  constructor(
    private constant: Constant
  ) { 
  }

  ngOnInit(): void {
    if (this.indexEnd == -1 && this.values.length > 0) {
      this.indexEnd = this.values.length - 1;
    } 
  }


}
