
<div class="container">
    <form action="{{getSearchURL()}}" method="get">
      <div class="row">
        <div class="col-auto" id="title">
          <span class="h2 d-inline" style="width: fit-to-content;">Listings</span>
        </div>
        <div class="col-auto">
          <div ngbDropdown class="city-dropdown">
            <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
              {{ selected }}
            </button>
            <div ngbDropdownMenu aria-labelledby="city-dropdown">
              <button ngbDropdownItem *ngFor="let city of cities" (click)="changeCity(city)">
                {{ city }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <div ngbDropdown class="status-dropdown">
            <button type="button" class="btn btn-outline-primary" ngbDropdownToggle>
              {{ selectedStatus }}
            </button>
            <div ngbDropdownMenu aria-labelledby="city-dropdown">
              <button ngbDropdownItem *ngFor="let statusItem of status" (click)="changeStatus(statusItem)">
                {{ statusItem }}
              </button>
            </div> 
          </div>
        </div>
        <div class="col" id="search">
          <div class="input-group">
            <input 
              [(ngModel)]="query" 
              type="text" class="form-control" placeholder="Search" aria-label="search" aria-describedby="button-addon2" name="search"
              (keydown.enter)="doSearch()">
            <button class="btn btn-outline-secondary" type="submit" id="button-addon2" (click)="doSearch()">
              Search
            </button>
          </div>
        </div>        
      </div>
  </form>
    
    <div class="row text-start border-top border-bottom py-2 my-2 fw-bold" *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
      <div class="col-3">
        #
      </div>
      <div class="col-2">
        Listing Title
      </div>
      <div class="col-2">
        Type
      </div>
      <div class="col-1">
        Bdrs #
      </div>
      <div class="col-2">
        Host Name
      </div>
      <div class="col-2">
        Status
      </div>
    </div>
    <ng-container *ngFor="let apartment of apartments; let i=index;">
      <div class="listing clickable" [routerLink]="['/listing', apartment._id]">
        <app-listing 
          [apartment]="apartment">
        </app-listing>
      </div>
        
        
    </ng-container>

    <div class="row float-end mt-2" *ngIf="totalSize > this.pageService.getPageSize()">
      <app-paginator 
          [length]="totalSize"
          [pageSize]="this.pageService.getPageSize()"
          (change)="onPageChange($event)"
          [page]="page"
          [rotate]="true"
          [ellipses]="false"
          [maxSize]="12"
        ></app-paginator> 
    </div>
</div>
  