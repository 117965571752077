import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BiosService } from 'src/app/services/bios.service';
import { MatchingService } from 'src/app/services/matching.service';
import { TopKeywordsComponent } from '../../matching-dashboard/ai-results/top-keywords/top-keywords.component';
import { Prediction, MatchResponse } from '../../../../services/matching.types';
import { Bio } from '../../../../services/bios.types';
import { Matching, MatchingStatus, MATCH_TEXT } from './user-to-users.types';




@Component({
  selector: 'app-user-to-users',
  templateUrl: './user-to-users.component.html',
  styleUrls: ['./user-to-users.component.scss']
})
export class UserToUsersComponent implements OnInit {
  
  usersNumberControl = new FormControl(15);
  maxUsersNumber = 30;
  bios: Bio[] = [];
  expandedBio: number | undefined;
  response: MatchResponse | undefined;

  constructor(
    private biosService: BiosService,
    private matchingService: MatchingService,
    private zone: NgZone,
    private modalService: NgbModal
    ) {}
  
  async ngOnInit(): Promise<void> {
    this.generateAll();
    this.usersNumberControl.valueChanges.subscribe(
      () => { this.response = undefined; }
    )
  }

  async match() {
    if (this.usersNumberControl.value === null) return;
    const primarySubject = {id: this.bios[0].id};
    const secondarySubjects = [];
    for (let i = 1; i <= this.usersNumberControl.value; i++) { secondarySubjects.push({id: this.bios[i].id}); }
    this.response = await this.matchingService.match(primarySubject, secondarySubjects);
  }

  async generateAll() {
    this.bios = await this.biosService.generate(this.maxUsersNumber + 1);
    this.response = undefined;
  }

  async generate(index: number) {
    this.bios[index] = (await this.biosService.generate(1))[0];
    this.response = undefined;
  }

  showKeywords(index: number) {
    if (!this.response || this.response.dev.length < index + 1) return;
    const keywords = this.response.dev[index].keywords;
    this.zone.run(() => {
      const modalRef = this.modalService.open(TopKeywordsComponent, { size: 'md', centered: true, scrollable: true });
      modalRef.componentInstance.keywords = keywords;
      modalRef.componentInstance.cluster = -1;
      modalRef.componentInstance.title = 'User Keywords';
    });
  }

  showCommonKeywords(index: number) {
    if (!this.response || this.response.matches.length < index) return;
    const keywords = this.response.matches[index-1].common_keywords_raw;
    this.zone.run(() => {
      const modalRef = this.modalService.open(TopKeywordsComponent, { size: 'md', centered: true, scrollable: true });
      modalRef.componentInstance.keywords = keywords;
      modalRef.componentInstance.cluster = -1;
      modalRef.componentInstance.title = 'Common Keywords';
    });
  }

  async toggleExpand(index: number) {
    if (this.expandedBio === index) {
      this.expandedBio = undefined;
    } else {
      this.expandedBio = index;
    }
  }

  

  async delay(ms: number) { 
    return new Promise(res => setTimeout(res, ms));
  }
}
