import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { KeywordsService } from '../../services/keywords.service';
import { Keyword, KeywordCategory } from '../../services/keywords.types';

@Component({
  selector: 'app-category-edit-modal',
  templateUrl: './category-edit-modal.component.html',
  styleUrls: ['./category-edit-modal.component.css']
})
export class CategoryEditModalComponent implements OnInit, AfterViewInit {

@ViewChild('form', { read: NgForm }) form: any;

  private category: KeywordCategory | undefined;
  public mode: 'edit' | 'add' = 'edit';
  public formGroup = new FormGroup({
    nameEn: new FormControl('',  [
      Validators.required
    ]),
    nameFr: new FormControl('',  [
      Validators.required
    ]),
    subtitleEn: new FormControl('',  [
      Validators.required
    ]),
    subtitleFr: new FormControl('',  [
      Validators.required
    ]),
    min: new FormControl(0),
    max: new FormControl(3),
  });

  constructor(
    public modal: NgbActiveModal,
    private keywordsService: KeywordsService
    ) { }

  ngOnInit(): void {
    
    if (!this.category) {
      this.modal.dismiss();
      return;
    }
    this.formGroup.controls.nameEn.setValue(this.category.name.en);
    this.formGroup.controls.nameFr.setValue(this.category.name.fr);
    this.formGroup.controls.subtitleEn.setValue(this.category.subtitle.en);
    this.formGroup.controls.subtitleFr.setValue(this.category.subtitle.fr);
    this.formGroup.controls.min.setValue(this.category.min);
    this.formGroup.controls.max.setValue(this.category.max);
  }

  ngAfterViewInit(): void {
    const form = document.getElementsByTagName('form')[0] as HTMLFormElement;
    form.addEventListener('submit', function (event) {
      if (!form.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
      }
    }, false)
  }


  async submit() {
    if (this.formGroup.invalid || !this.category) return;
    const payload: KeywordCategory = {
      _id: this.category._id,
      name: {
        en: this.formGroup.controls.nameEn.value as string,
        fr: this.formGroup.controls.nameFr.value as string
      },
      subtitle: {
        en: this.formGroup.controls.subtitleEn.value as string,
        fr: this.formGroup.controls.subtitleFr.value as string
      },
      min: this.formGroup.controls.min.value as number,
      max: this.formGroup.controls.max.value as number,
      showInMatchDetails: true,
      keywords: []
    };
    try {
      if (this.mode === 'edit')
        this.modal.close(await this.keywordsService.modifyCategory(this.category._id, payload))
      else
        this.modal.close(await this.keywordsService.createCategory(payload))
    } catch (e) {
      console.error(e);
      return;
    }
  }

  getErrorMessage(controlId: string): string {
    const formControl = this.formGroup.get(controlId);
    if (!formControl) return '';
    let prefix = '';
    switch (controlId) {
      case 'tag':
        prefix = 'Tag';
        break;
      case 'nameEn':
        prefix = 'English name';
        break;
      case 'nameFr':
        prefix = 'French name';
        break;
      case 'subtitleEn':
          prefix = 'English subtitle';
          break;
      case 'subtitleFr':
          prefix = 'French subtitle';
          break;
      default:
        prefix = 'Field'
    }
    if (!formControl.errors) return '';
    if (formControl.errors['required']) return prefix + ' is required.'
    return '';
  }

}
