import { NgModule } from "@angular/core";
import { DashboardComponent } from "./dashboard.component";
import { UpcomingCheckinComponent } from "./upcoming-checkin/upcoming-checkin.component";
import { CommonModule } from "@angular/common";


@NgModule({
    declarations: [
        DashboardComponent,
        UpcomingCheckinComponent
    ],
    exports: [
        UpcomingCheckinComponent,
        DashboardComponent
    ],
    imports: [
        CommonModule
    ]
})
export class DashboardModule {

}