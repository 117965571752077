import { Injectable } from "@angular/core";
const moment = require('moment');

@Injectable({
    providedIn: 'root'
})
export class DateUtil {
    public createDate(date: string | Date): Date {
        if (typeof date === 'object') {
          date = date.toISOString();
        }
        let dateParts = date.split("T");
        if (dateParts.length >= 1) {
            dateParts = dateParts[0].split("-");
            return new Date(
                Number(dateParts[0]), 
                Number(dateParts[1]) - 1, 
                Number(dateParts[2])
            );
        } else {
            dateParts = date.split("/");
            return new Date(
                Number(dateParts[2]),
                Number(dateParts[0]) - 1,
                Number(dateParts[1]),
            );
        }
        
    }

    isAtBeginningOfMonth(date: Date): boolean {
        return moment(date).date() == 1;
    }

    isAtEndOfMonth(date: Date): boolean {
        return moment(date).date() === moment(date).daysInMonth();
    }

    public isSameDate(a: Date, b: Date): boolean {
        const momentA = moment(a);
        const momentB = moment(b);

        return (momentA.year() === momentB.year()
        && momentA.month() === momentB.month()
        && momentA.date() === momentB.date());
    }

    public isSameMonth(a: Date, b: Date): boolean {
        const momentA = moment(a);
        const momentB = moment(b);

        return (momentA.month() === momentB.month());
    }

    public isSameYear(a: Date, b: Date): boolean {
        const momentA = moment(a);
        const momentB = moment(b);

        return (momentA.year() === momentB.year());
    }
}