<div class="modal-header">
  <h4 *ngIf="title" class="modal-title">{{title}}</h4>
  <h4 *ngIf="!title && cluster >= 0" class="modal-title">Cluster {{ cluster + 1 }} Top Keywords</h4>
  <h4 *ngIf="!title && cluster === -1" class="modal-title">Top Features</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body d-flex justify-content-center flex-wrap gap-2" style="max-height: 70vh;">
  <ng-container *ngFor="let keyword of keywords">
    <div *ngIf="!isHidden(keyword)" class="align-self-center">
      <span
        placement="top"
        [ngbTooltip]="keyword.weight.toFixed(3)"
        container="body" tooltipClass="custom-tooltip"
        class="badge badge-dark bg-dark"
        [style]="'border-radius: 50px;' + formatStyle(keyword)"
        >{{ keyword.keyword }}
        
        
        </span
      >
    </div>
  </ng-container>
</div>
