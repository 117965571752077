import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class PaginatorService {
  pageSize: number = 24;

  constructor() { }

  getPageSize(): number {
    return this.pageSize;
  }

  setPageSize(ps: number) {
    this.pageSize = ps;
  }
  
}
