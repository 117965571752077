<div class="container">
    <div class="row">
        <div class="col-auto" id="title">
            <h2>Booking Requests</h2>
        </div>

        <div class="col-auto ms-2 align-self-center" id="active_checkbox">
            <div class="form-check form-check-inline d-inline-block align-self-center">
                <input class="form-check-input" type="checkbox" type="checkbox" id="statusCheckbox"
                  (change)="activeOnly = !activeOnly">
                <label for="statusCheckbox" class="form-check-label">
                  <span class="fw-bold">
                    Show Active Only
                  </span>
                </label>
            </div>
        </div>

        <div class="col-auto" id="city_dropdown">
            <div ngbDropdown class="city-dropdown">
                <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                  {{ selectedCity }}
                </button>
                <div ngbDropdownMenu aria-labelledby="city-dropdown">
                  <button ngbDropdownItem *ngFor="let city of cities" (click)="changeCity(city)">
                    {{ city }}
                  </button>
                </div>
            </div>
        </div>

        <div class="col-auto" id="status_dropdown">
            <div ngbDropdown class="status-dropdown">
                <button type="button" class="btn btn-outline-primary" ngbDropdownToggle>
                  {{ selectedStatus }}
                </button>
                <div ngbDropdownMenu aria-labelledby="city-dropdown">
                  <button ngbDropdownItem *ngFor="let statusItem of status" (click)="changeStatus(statusItem)">
                    {{ statusItem }}
                  </button>
                </div> 
            </div>
        </div>

        <div class="col" id="search">
            <div class="input-group">
              <input 
                [(ngModel)]="query" 
                type="text" class="form-control" placeholder="Search" aria-label="search" aria-describedby="button-addon2" name="search"
                (keydown.enter)="doSearch()">
              <button class="btn btn-outline-secondary" type="submit" id="button-addon2" (click)="doSearch()">
                Search
              </button>
            </div>
          </div> 
    </div>

    <div class="row mt-2 py-2 border-bottom border-top" *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
        <div class="col fw-bold">
            Listing Title
        </div>
        <div class="col fw-bold">
            Move-in / Move-out
        </div>
        <div class="col fw-bold">
            Requested
        </div>
        <div class="col fw-bold">
            Guest Name
        </div>
        <div class="col fw-bold">
            Total to Pay
        </div>
        <div class="col fw-bold">
            Status
        </div>
    </div>

    <ng-container *ngFor="let bookingRequest of bookingRequests">
        <app-booking-request
            [bookingRequest]="bookingRequest">
        </app-booking-request>
    </ng-container>

    <div class="row float-end mt-2" *ngIf="totalSize > pageService.getPageSize()">
        <app-paginator 
            [length]="totalSize"
            [pageSize]="pageService.getPageSize()"
            (change)="onPageChange($event)"
            [page]="page"
            [rotate]="true"
            [ellipses]="false"
            [maxSize]="12"
        ></app-paginator> 
    </div>
</div>