<div class="container">
    <div class="row d-flex justify-content-end">
        <div class="col-auto align-self-center">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="autoChargeInput" [checked]="autoCharged" (change)="changeAutoCharge($event)">
                <label class="form-check-label" for="autoChargeLabel">
                  Auto Charged
                </label>
            </div>
        </div>
        <div class="col-auto">
            <!-- <mat-form-field appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select [(value)]="selectedStatus">
                    <mat-option></mat-option>
                  <mat-option *ngFor="let status of invoiceStatus" [value]="status"> {{ status | titlecase }}</mat-option>
                </mat-select>
            </mat-form-field> -->
            <div ngbDropdown class="status-dropdown">
              <button type="button" class="btn btn-outline-primary" ngbDropdownToggle>
                {{ selectedStatus | titlecase }}
              </button>
              <div ngbDropdownMenu aria-labelledby="city-dropdown">
                <button ngbDropdownItem *ngFor="let statusItem of invoiceStatus" (click)="selectInvoiceStatus(statusItem)">
                  {{ statusItem | titlecase }}
                </button>
              </div> 
            </div>
          </div>
        <div class="col-6 search">
            <div class="input-group">
                <input 
                    type="text" 
                    class="form-control" 
                    placeholder="Search by Customer Name, Email"
                    aria-label="Search" 
                    aria-describedby="button-addon2"
                    [(ngModel)]="query"
                    (keydown.enter)="searchInvoices()"
                >
                <button 
                    class="btn btn-outline-secondary" 
                    type="button" 
                    id="button-addon2"
                    (click)="searchInvoices()">Search</button>
            </div>
        </div>
        
    </div>

    <table mat-table [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef> Total </th>
            <td mat-cell *matCellDef="let invoice">
                <span class="mobile-label"> Total </span>
                {{ invoice.amount_due / 100 | currency: 'CAD' : 'symbol-narrow' : '1.0-2' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="remaining">
            <th mat-header-cell *matHeaderCellDef> Remaining </th>
              <td mat-cell *matCellDef="let invoice">
                  <span class="mobile-label"> Remaining </span>
                  {{ invoice.amount_remaining / 100 | currency: 'CAD': 'symbol-narrow': '1.0-2'}}
              </td>
          </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let invoice">
                    <span class="mobile-label"> Name </span>
                        {{ invoice.customer_name }}
                </td>
        </ng-container>

        <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let invoice">
                    <span class="mobile-label"> Email </span> 
                    {{ invoice.customer_email }}
                </td>
        </ng-container>

        <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef> Created </th>
                <td mat-cell *matCellDef="let invoice">
                    <span class="mobile-label"> Created </span>
                    {{ getDateFromTimestamp(invoice.created) }}
                </td>
        </ng-container>

        <ng-container matColumnDef="due">
            <th mat-header-cell *matHeaderCellDef> Due </th>
                <td mat-cell *matCellDef="let invoice">
                    <span class="mobile-label"> Due </span>
                    <a [href]="getInvoiceUrlForStripe(invoice.id)">{{ getDateFromTimestamp(invoice.created) }}</a>
                </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let invoice">
                    <span class="mobile-label"> Action </span>
                    <button mat-flat-button color="primary">Send</button>
                </td>
        </ng-container>

        <ng-container matColumnDef="link">
            <th mat-header-cell *matHeaderCellDef> Link </th>
                <td mat-cell *matCellDef="let invoice">
                    <span class="mobile-label"> Link </span>
                    <ng-container *ngIf="invoice.subscription && invoice.subscription.metadata.requestId">
                        <a [routerLink]="['/reservation', invoice.subscription.metadata.requestId]">Link</a>
                    </ng-container>
                    <ng-container *ngIf="!invoice.subscription">
                        -
                    </ng-container>
                </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div class="row mt-3 d-flex justify-content-end">
        <div class="col-auto">
            <div class="btn-group" role="group" aria-label="Basic example">
                <button 
                    mat-flat-button
                    [disabled]="!hasPrev()"
                    (click)="prevPage()"
                    color="primary">Prev</button>
                <button mat-flat-button disabled>...</button>
                <button 
                    mat-flat-button
                    [disabled]="!hasNext()"
                    (click)="nextPage()"
                    color="primary">Next</button>
              </div>
        </div>
        
    </div>
</div>

<ngx-spinner type="ball-scale-multiple" style="color: white">Loading...</ngx-spinner>