import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private http: HttpClient,
    private utils: Utils
  ) { }

  sendVerificationCode(email: string) {
    return this.http.post(
      this.utils.getAPIUrl() + 'users/sendemailcode',
      {
        email
      }
    )
  }
}
