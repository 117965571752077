import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { bootstrapArrowReturnLeft } from '@ng-icons/bootstrap-icons';
import { KeywordsService } from '../../services/keywords.service';
import { Keyword } from '../../services/keywords.types';

@Component({
  selector: 'app-keyword-edit-modal',
  templateUrl: './keyword-edit-modal.component.html',
  styleUrls: ['./keyword-edit-modal.component.css']
})
export class KeywordEditModalComponent implements OnInit, AfterViewInit {

@ViewChild('form', { read: NgForm }) form: any;

  private categoryId: string | undefined;
  private keyword: Keyword | undefined;
  public mode: 'edit' | 'add' = 'edit';
  public formGroup = new FormGroup({
    tag: new FormControl('',  [
      Validators.required,
      Validators.minLength(3),
      Validators.pattern('^[a-zA-Z -]*$')
    ]),
    nameEn: new FormControl('',  [
      Validators.required
    ]),
    nameFr: new FormControl('',  [
      Validators.required
    ]),
  });

  constructor(
    public modal: NgbActiveModal,
    private keywordsService: KeywordsService
    ) { }

  ngOnInit(): void {
    
    if (!this.keyword || !this.categoryId) {
      this.modal.dismiss();
      return;
    }
    this.formGroup.controls.tag.setValue(this.keyword.tag);
    this.formGroup.controls.nameEn.setValue(this.keyword.name.en);
    this.formGroup.controls.nameFr.setValue(this.keyword.name.fr);
    this.formGroup.valueChanges.subscribe(() => {console.log(this.formGroup)})
  }

  ngAfterViewInit(): void {
    const form = document.getElementsByTagName('form')[0] as HTMLFormElement;
    form.addEventListener('submit', function (event) {
      if (!form.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
      }
    }, false)
  }


  async submit() {
    if (this.formGroup.invalid || !this.categoryId || !this.keyword) return;
    const payload: Keyword = {
      _id: this.keyword._id,
      tag: this.formGroup.controls.tag.value as string,
      cleaned: '',
      name: {
        en: this.formGroup.controls.nameEn.value as string,
        fr: this.formGroup.controls.nameFr.value as string
      }
    };
    try {
      if (this.mode === 'edit')
        this.modal.close(await this.keywordsService.modifyKeyword(this.categoryId, this.keyword._id, payload));
      else
        this.modal.close(await this.keywordsService.addKeyword(this.categoryId, payload));
    } catch (e) {
      console.error(e);
      return;
    }
  }

  getErrorMessage(controlId: string): string {
    const formControl = this.formGroup.get(controlId);
    if (!formControl) return '';
    let prefix = '';
    switch (controlId) {
      case 'tag':
        prefix = 'Tag';
        break;
      case 'nameEn':
        prefix = 'English name';
        break;
      case 'nameFr':
        prefix = 'French name';
        break;
      default:
        prefix = 'Field'
    }
    if (!formControl.errors) return '';
    if (formControl.errors['required']) return prefix + ' is required.'
    else if (formControl.errors['minlength']) return prefix + ' must be 4 characters long.'
    else if (formControl.errors['pattern']) return prefix + ' must contain only letters, spaces and dashes.'
    return '';
  }

}
