import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeywordEditModalComponent } from './keyword-edit-modal/keyword-edit-modal.component';
import { Keyword, KeywordCategory } from '../services/keywords.types';
import { KeywordsService } from '../services/keywords.service';
import { CategoryEditModalComponent } from './category-edit-modal/category-edit-modal.component';
import { Category, CategoryEvent, ToggleEvent, TokenEvent } from '../shared/tokens-accordion/tokens-accordion.types';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-matching-keywords',
  templateUrl: './matching-keywords.component.html',
  styleUrls: ['./matching-keywords.component.css']
})
export class MatchingKeywordsComponent implements OnInit {

  categories: KeywordCategory[] = [];
  accordion: Category[] = [];
  private _loading = false;

  constructor(
    private modalService: NgbModal,
    private keywordsService: KeywordsService,
    private spinner: NgxSpinnerService
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.categories = await this.keywordsService.getCategories();
    this.refreshAccordion();
    this.loading = false;
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    if (value) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
    this._loading = value;
  }

  onCategoryAdd() {
    const modalRef = this.modalService.open(CategoryEditModalComponent, { centered: true, size: 'md', scrollable: true });
    modalRef.componentInstance.category = { name: { en: '', fr: '' }, subtitle: { en: '', fr: ''}, min: 0, max: 3 };
    modalRef.componentInstance.mode = 'create';
    modalRef.closed.subscribe((resp) => {this.handleResponse(resp)})
  }

  onCategoryEdit(event: CategoryEvent) {
    const category = this.getCategory(event.category.id);
    if (!category) return;
    const modalRef = this.modalService.open(CategoryEditModalComponent, { centered: true, size: 'md', scrollable: true });
    modalRef.componentInstance.category = category;
    modalRef.componentInstance.mode = 'edit';
    modalRef.closed.subscribe((resp) => {this.handleResponse(resp)})
  }

  async onCategoryDelete(event: CategoryEvent) {
    // Search in the categories
    let index = -1;
    for (let i = 0; i < this.categories.length; i++)
      if (this.categories[i]._id === event.category.id)
        index = i
    if (index === -1) return;

    // Delete everywhere
    await this.keywordsService.deleteCategory(event.category.id);
    this.categories.splice(index, 1);
    this.refreshAccordion();
  }

  onTokenAdd(event: CategoryEvent) {
    const modalRef = this.modalService.open(KeywordEditModalComponent, { centered: true, size: 'md', scrollable: true });
    modalRef.componentInstance.categoryId = event.category.id;
    modalRef.componentInstance.keyword = { tag: '', name: { en: '', fr: '' } };
    modalRef.componentInstance.mode = 'add';
    modalRef.closed.subscribe((resp) => {this.handleResponse(resp)})
  }

  onTokenEdit(event: TokenEvent) {
    const keyword = this.getKeyword(event.category.id, event.token.id);
    if (!keyword) return;
    const modalRef = this.modalService.open(KeywordEditModalComponent, { centered: true, size: 'md', scrollable: true });
    modalRef.componentInstance.categoryId = event.category.id;
    modalRef.componentInstance.keyword = keyword;
    modalRef.componentInstance.mode = 'edit';
    modalRef.closed.subscribe((resp) => {this.handleResponse(resp)})
  }

  async onToggle(toggleEvent: ToggleEvent) {
    // Find the category
    let keywordCategory = this.getCategory(toggleEvent.category.id);
    if (!keywordCategory) return;
    // Make a deep copy
    keywordCategory = JSON.parse(JSON.stringify(this.getCategory(toggleEvent.category.id)));
    if (!keywordCategory) return;
    // Process the change
    if (toggleEvent.toggleId === 'showInMatchDetails') {
      keywordCategory['showInMatchDetails'] = toggleEvent.value;
      this.handleResponse(await this.keywordsService.modifyCategory(toggleEvent.category.id, keywordCategory));
    }
  }

  async onTokenDelete(event: TokenEvent) {
    const resp = await this.keywordsService.deleteKeyword(event.category.id, event.token.id);
    this.handleResponse(resp);
  }


  handleResponse(response: KeywordCategory) {
    // Find category and update/create it
    let category = this.getCategory(response._id);
    if (!category) {
      category = response;
      this.categories.push(category);
    } else {
      category.keywords = response.keywords;
      category.name = response.name;
      category.subtitle = response.subtitle;
      category.min = response.min;
      category.max = response.max;
      category.showInMatchDetails = response.showInMatchDetails;
    }

    // Update the accordion
    this.updateAccordionCategory(category)
  }


  private refreshAccordion() {
    this.accordion = [];
    for (const category of this.categories)
      this.updateAccordionCategory(category);
  }


  private updateAccordionCategory(kwCat: KeywordCategory) {
    let accCat: Category | undefined;

    // Search the category
    for (const tmpAccCat of this.accordion)
      if (tmpAccCat.id === kwCat._id)
        accCat = tmpAccCat;
    // Create the category if needed
    if (!accCat) {
      accCat = {
        id: kwCat._id,
        name: kwCat.name.en,
        tokens: [],
        toggles: [{id: 'showInMatchDetails', name: 'Show in Match Details', value: kwCat.showInMatchDetails}]
      }
      this.accordion.push(accCat);
    }
    // Update the category
    accCat.name = kwCat.name.en
    accCat.tokens = [];
    if (accCat.toggles)
      accCat.toggles[0].value = kwCat.showInMatchDetails;
    for (const keyword of kwCat.keywords) {
      accCat.tokens.push({
        id: keyword._id,
        name: keyword.name.en
      })
    }
  }


  getCategory(categoryId: string): KeywordCategory | undefined {
    for (const category of this.categories)
      if (category._id === categoryId)
        return category;
    return undefined;
  }

  getKeyword(categoryId: string, keywordId: string): Keyword | undefined {
    const category = this.getCategory(categoryId);
    if (!category) return undefined;
    for (const keyword of category.keywords)
      if (keyword._id === keywordId)
        return keyword;
    return undefined;
  }


}
