import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Utils } from 'src/app/utils/utils'
import {
  FeedbackForm,
  Question,
  AnswersSchema,
  PendingFeedbackResponse,
  PendingFeedbackSchema,
} from './feedback.type'
import { ApiService } from 'src/app/services/api.service'
import { ToastService } from 'src/app/services/toast/toast-service'
import { LoginService } from 'src/app/services/login.service'

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(
    private util: Utils,
    private http: HttpClient,
    private apiService: ApiService,
    private toastService: ToastService,
    private loginService: LoginService,
    private router: Router,
  ) {}

  async getAnswers(): Promise<AnswersSchema[]> {
    return new Promise<AnswersSchema[]>((resolve, reject) => {
      this.apiService.get<AnswersSchema[]>('/feedback/answers').subscribe({
        next: resolve,
        error: reject,
      })
    })
  }

  async getPending(
    page: number,
    filter: string,
  ): Promise<PendingFeedbackResponse> {
    return new Promise<PendingFeedbackResponse>((resolve, reject) => {
      let url = `/feedback/pending?p=${page}`
      if (filter) url += `&f=${filter}`
      this.apiService.get<PendingFeedbackResponse>(url).subscribe({
        next: (response) => {
          resolve(response)
        },
        error: (error: PendingFeedbackResponse) => {
          this.loginService.setLoggedIn(false);
          this.router.navigate(['/login']);
          reject(error)
        },
      })
    })
  }

  async getForms(): Promise<FeedbackForm[]> {
    return new Promise<FeedbackForm[]>((resolve, reject) => {
      this.apiService.get<FeedbackForm[]>('/feedback').subscribe({
        next: resolve,
        error: reject,
      })
    })
  }

  async activateForm(formId: string): Promise<FeedbackForm[]> {
    return new Promise<FeedbackForm[]>((resolve, reject) => {
      this.apiService
        .put<FeedbackForm[]>(`/feedback/${formId}`, { name })
        .subscribe({
          next: (response) => {
            this.toastService.show('Form activated', {
              classname: 'bg-success text-light',
              delay: 2000,
            })
            resolve(response)
          },
          error: () => {
            this.toastService.show('Error while activating this form', {
              classname: 'bg-danger text-light',
              delay: 5000,
            })
            reject()
          },
        })
    })
  }

  async createForm(name: string): Promise<FeedbackForm> {
    return new Promise<FeedbackForm>((resolve, reject) => {
      this.apiService
        .post<FeedbackForm>('/feedback', { name })
        .subscribe({
          next: (response) => {
            this.toastService.show('Form created', {
              classname: 'bg-success text-light',
              delay: 5000,
            })
            resolve(response)
          },
          error: () => {
            this.toastService.show('Error while creating form', {
              classname: 'bg-danger text-light',
              delay: 5000,
            })
            reject()
          },
        })
    })
  }

  async modifyForm(form: FeedbackForm): Promise<FeedbackForm> {
    return new Promise<FeedbackForm>((resolve, reject) => {
      const url = `${this.util.getAPIUrl()}admin/feedback/${form._id}`
      this.http.patch<FeedbackForm>(url, form).subscribe({
        next: (response) => {
          this.toastService.show('Form updated', {
            classname: 'bg-success text-light',
            delay: 5000,
          })
          resolve(response)
        },
        error: () => {
          this.toastService.show('Error while updating question', {
            classname: 'bg-danger text-light',
            delay: 5000,
          })
          reject()
        },
      })
    })
  }

  async deleteForm(formId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = `${this.util.getAPIUrl()}admin/feedback/${formId}`
      this.http.delete<void>(url).subscribe({
        next: (response) => {
          this.toastService.show('Form deleted', {
            classname: 'bg-success text-light',
            delay: 5000,
          })
          resolve(response)
        },
        error: () => {
          this.toastService.show('Error while deleting form', {
            classname: 'bg-danger text-light',
            delay: 5000,
          })
          reject()
        },
      })
    })
  }

  async createQuestion(formId: string, question: Question): Promise<Question> {
    return new Promise<Question>((resolve, reject) => {
      this.apiService
        .post<Question>(`/feedback/${formId}`, question)
        .subscribe({
          next: (response) => {
            this.toastService.show('Question created', {
              classname: 'bg-success text-light',
              delay: 5000,
            })
            resolve(response)
          },
          error: () => {
            this.toastService.show('Error while creating question', {
              classname: 'bg-danger text-light',
              delay: 5000,
            })
            reject()
          },
        })
    })
  }

  async modifyQuestion(
    formId: string,
    questionId: string,
    updatedQuestion: Question,
  ) {
    return new Promise<FeedbackForm>((resolve, reject) => {
      const url = `${this.util.getAPIUrl()}admin/feedback/${formId}/${questionId}`
      this.http.patch<FeedbackForm>(url, updatedQuestion).subscribe({
        next: (response) => {
          this.toastService.show('Question updated', {
            classname: 'bg-success text-light',
            delay: 5000,
          })
          resolve(response)
        },
        error: () => {
          this.toastService.show('Error while updating question', {
            classname: 'bg-danger text-light',
            delay: 5000,
          })
          reject()
        },
      })
    })
  }

  async deleteQuestion(
    formId: string,
    questionId: string,
  ): Promise<FeedbackForm> {
    return new Promise<FeedbackForm>((resolve, reject) => {
      const url = `${this.util.getAPIUrl()}admin/feedback/${formId}/${questionId}`
      this.http.delete<FeedbackForm>(url).subscribe({
        next: (response) => {
          this.toastService.show('Question deleted', {
            classname: 'bg-success text-light',
            delay: 5000,
          })
          resolve(response)
        },
        error: () => {
          this.toastService.show('Error while deleting question', {
            classname: 'bg-danger text-light',
            delay: 5000,
          })
          reject()
        },
      })
    })
  }

  async sendEmail(pendingId: string): Promise<PendingFeedbackSchema> {
    return new Promise<PendingFeedbackSchema>((resolve, reject) => {
      this.apiService
        .get<PendingFeedbackSchema>(`/feedback/pending/${pendingId}/manualSend`)
        .subscribe({
          next: (response) => {
            this.toastService.show('Email sent.', {
              classname: 'bg-success text-light',
              delay: 5000,
            })
            resolve(response)
          },
          error: () => {
            this.toastService.show('Error while sending the email.', {
              classname: 'bg-danger text-light',
              delay: 5000,
            })
            reject()
          },
        })
    })
  }

  async triggerCronjob(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiService.get<void>('/feedback/trigger').subscribe({
        next: () => {
          this.toastService.show('Cronjob triggered.', {
            classname: 'bg-success text-light',
            delay: 5000,
          })
          resolve()
        },
        error: () => {
          this.toastService.show('Error while triggering Cronjob.', {
            classname: 'bg-danger text-light',
            delay: 5000,
          })
          reject()
        },
      })
    })
  }
}
