import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface PayoutConfirmModalData {
  title: 'string',
  subtitle: 'string',
  note: 'string',
  amountDue: 'number',
  actualPaid: 'number',
  requestId: 'string'
};

@Component({
  selector: 'app-payout-confrim-modal',
  templateUrl: './payout-confrim-modal.component.html',
  styleUrls: ['./payout-confrim-modal.component.css']
})
export class PayoutConfrimModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PayoutConfrimModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PayoutConfirmModalData
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}