import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map, Observable } from 'rxjs';
import { WorkerState } from './workers.types';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(private socket: Socket) { }

  onUpdate(): Observable<WorkerState> {
    return this.onEvent('update');
  }

  onEvent(event: string) { 
    return this.socket.fromEvent(event).pipe(
      map((raw: unknown) => {return JSON.parse(raw as string);})
    )
  }
}
