import { Component } from '@angular/core';
import { Route } from '../../shared/sidebar/sidebar.types';

@Component({
  selector: 'app-matching',
  templateUrl: './matching.component.html',
  styleUrls: ['./matching.component.css']
})
export class MatchingComponent {

  routes: Route[] = [
      {
        name: 'Dashboard',
        href: '/matching/dashboard',
        icon: 'bootstrapClipboard2DataFill'
      },
      {
        name: 'Testing',
        href: '/matching/testing',
        icon: 'ionFlask',
        children: [
          {
            name: 'Users',
            href: '/matching/testing/users',
            icon: 'bootstrapPersonFill'
          },
          {
            name: 'Neighborhoods',
            href: '/matching/testing/neighborhoods',
            icon: 'bootstrapBuilding'
          },
        ]
      },
      {
        name: 'Keywords',
        href: '/matching/keywords',
        icon: 'bootstrapTagsFill'
      },
      {
        name: 'Neighborhoods',
        href: '/matching/neighborhoods',
        icon: 'bootstrapPeopleFill'
      },
      {
        name: 'History',
        href: '/matching/history',
        icon: 'ionTime'
      },
      {
        name: 'Settings',
        href: '/matching/settings',
        icon: 'bootstrapGearFill'
      },
  ]

  constructor() {}

}
