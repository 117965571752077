<!-- <div class="container text-center"> -->
<div class="row py-2 border-bottom fs-14">
    <div class="col-3" id="user_id">
        <span class="text-secondary" *ngIf="user.isBlocked">
            {{ user._id}}
        </span>
        <span *ngIf="!user.isBlocked">
            {{ user._id}} 
        </span>
    </div>
    <div class="col-2 fw-bold" id="user_name">
        <span class="text-secondary" *ngIf="user.isBlocked">
            {{ user.first_name + " " + user.last_name }}
        </span>
        <span *ngIf="!user.isBlocked">
            {{ user.first_name + " " + user.last_name }}
        </span>
        
    </div>
    <div class="col-3 fw-bold" id="user_email">
        <span class="text-secondary" *ngIf="user.isBlocked">
            {{ user.email}}
        </span>
        <span *ngIf="!user.isBlocked">
            {{ user.email }}
        </span>
    </div>
    <div class="col-2 fw-bold" id="user_phone">
        <span class="text-secondary" *ngIf="user.isBlocked">
            {{ user.phone }}
        </span>
        <span *ngIf="!user.isBlocked">
            <ng-container *ngIf="user.phone">
                {{ user.phone }}
            </ng-container>
            <ng-container *ngIf="!user.phone">
                /
            </ng-container>
            
        </span>
    </div>
    <div class="col-2" id="user_status">
        <ng-container *ngIf="user.isBlocked">
            <span class="badge text-bg-secondary rounded-pill">
                Blocked
            </span>
        </ng-container>
        <ng-container *ngIf="!user.isBlocked">
            <span class="badge text-bg-success rounded-pill" *ngIf="user.emailVerified">
                Verified
            </span>
            <span class="badge text-bg-danger rounded-pill" *ngIf="!user.emailVerified">
                Unverified
            </span>
        </ng-container>
    </div>
</div>
<!-- </div> -->
