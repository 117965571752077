<div class="container">
  <div class="row">
    <div class="col-sm-2" *ngIf="isManagementPage() && !isDetailPage()">
      <div class="d-flex row" *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
          <li ngbNavItem="listings">
            <a ngbNavLink routerLink="/listing">Listings</a>
          </li>
          <li ngbNavItem="users">
            <a ngbNavLink routerLink="/user">Users</a>
          </li>
          <li ngbNavItem="booking-requests">
            <a ngbNavLink routerLink="/booking-request">Booking Requests</a>
          </li> 
          <li ngbNavItem="reservations">
            <a ngbNavLink routerLink="/reservation">Reservations</a>
          </li>
          <li ngbNavItem="invoices">
            <a ngbNavLink routerLink="/invoice">Invoices</a>
          </li>
          <li ngbNavItem="payouts">
            <a ngbNavLink routerLink="/payout">Payouts</a>
          </li>
          <li ngbNavItem="sent-messages">
            <a ngbNavLink routerLink="/sent-messages">Sent Messages</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-sm-10 justify-self-end" *ngIf="!isLoginPage()">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="isLoginPage()">
      <router-outlet></router-outlet> 
    </div>
    
  </div>
</div>

