<div class="row py-2 border-bottom clickable fs-14" [routerLink]="['/booking-request', bookingRequest._id]">
    <div class="col fs-14 text-truncate" id="apartment_name">
        {{ bookingRequest.apartment.name }}
    </div>
    <div class="col" id="apartment_duration">
        <span class="badge text-bg-light rounded-pill">
            {{ dateUtil.createDate(bookingRequest.moveInDate) | date: 'mediumDate'}} - {{ dateUtil.createDate(bookingRequest.moveOutDate) | date: 'mediumDate'}}
        </span>
        
    </div>
    <div class="col fs-14" id="request_date">
        {{ dateUtil.createDate(bookingRequest.createdAt) | date: 'mediumDate' }}
    </div>
    <div class="col fs-14" id="user_name">
        <a class="clickable text-decoration-none text-decoration-underline fw-14" [routerLink]="['/user', bookingRequest.userId._id]">
            {{ bookingRequest.user_full_name }}
        </a>
        <span class="text-secondary" *ngIf="!bookingRequest.userId">
            Null
        </span>
    </div>
    <div class="col fs-14" id="price">
        {{ bookingRequest.paymentData.roomRent | currency: 'CAD': 'symbol-narrow': '1.0-2' }}
    </div>
    <div class="col" id="request_status">
        <span class="badge text-bg-light fw-bold rounded-pill" *ngIf="isPendingAdmin()">Pending Admin</span>
        <span class="badge text-bg-secondary fw-bold rounded-pill" *ngIf="isPendingHost()">Pending Host</span>
        <span class="badge text-bg-danger bg-opacity-75 fw-bold rounded-pill" *ngIf="isDeclinedByAdmin()">Cancelled by Admin</span>
        <span class="badge text-bg-danger bg-opacity-75 fw-bold rounded-pill" *ngIf="isDeclinedByHost()">Cancelled by Host</span>
        <span class="badge text-bg-danger bg-opacity-75 fw-bold rounded-pill" *ngIf="isPaymentFailed()">Payment Failed</span>
        <span class="badge text-bg-warning bg-opacity-75 fw-bold rounded-pill" *ngIf="isClosedByHost()">Closed by Host</span>
        <span class="badge text-bg-warning bg-opacity-75 fw-bold rounded-pill" *ngIf="isClosedBySystem()">Closed by System</span>

    </div>
</div>
