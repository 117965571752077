<ngb-pagination
  [(page)]="page"
  [boundaryLinks]="true"
  (pageChange)="pageChanged(page)"
  [pageSize]="pageSize"
  [collectionSize]="length"
  [rotate]="rotate"
  [ellipses]="ellipses"
  [maxSize]="maxSize"
  >
</ngb-pagination>
