<div class="h-100 d-flex flex-row">


    <!-- Icons Sidebar -->

    <div class="h-100 d-flex flex-column justify-content-between bg-light py-3 user-select-none">
        <div class="d-flex flex-column flex-shrink-0" >
            <ul class="nav flex-column mb-auto">
                <li *ngFor="let route of routes; let i = index" class="nav-item border-bottom" [class.border-top]="i === 0">
                    <a  (click)="onRouteClick(i)" 
                        role="button"
                        class="d-flex flex-column"
                        [class.text-light]="route.href === router.url || (sidenavCollapsed && currentRouteIndex === i)"
                        [class.active]="route.href === router.url || (sidenavCollapsed && currentRouteIndex === i)"
                        [class.text-dark]="route.href !== router.url && !(sidenavCollapsed && currentRouteIndex === i)"
                        [class.expended-route]="route.children && route.children.length > 0 && collapsedRoutes[i] === false">
                        <!-- Main -->
                        <div class="d-flex px-4 py-3">
                            <div class="item-icon"><ng-icon [name]="route.icon" class="aligned"></ng-icon></div>
                            <div [(ngbCollapse)]="sidenavCollapsed" (hidden)="onSidenavCollapseEnd()" (shown)="onSidenavExpandEnd()" [horizontal]="true" class="fast-collapse">
                                <div class="item ps-3 flex-grow-1">{{route.name}}</div>
                            </div>
                        </div>
                        <!-- Children -->
                        <ul *ngIf="route.children && route.children.length > 0" class="nav flex-column mb-auto">
                            <div *ngFor="let child of route.children" [(ngbCollapse)]="collapsedRoutes[i]" (hidden)="onRoutesCollapseEnd()" (shown)="onRoutesExpandEnd()" class="fast-collapse">
                                <li >
                                    <a (click)="onChildClick(child, $event)"
                                        class="pe-4 py-2 d-flex"
                                        style="padding-left: 40px"
                                        [class.bg-dark]="child.href === router.url"
                                        [class.text-light]="child.href === router.url"
                                        [class.active]="child.href === router.url"
                                        [class.text-dark]="child.href !== router.url">
                                        <div class="item-icon"><ng-icon [name]="child.icon" class="aligned" size="14"></ng-icon></div>
                                        <div style="font-size: 14px;">
                                            <div class="ps-2 flex-grow-1">{{child.name}}</div>
                                        </div>
                                    </a>
                                </li>
                            </div>
                        </ul>
                    </a>
                </li>
            </ul>
        </div>
        <div class="d-flex flex-column flex-shrink-0 py-3 bg-light" >
            <ul class="nav mb-auto">
                <li class="nav-item">
                    <a (click)="toggleSidenav()" role="button" class="px-4 nav-link bg-light text-dark d-flex" aria-current="page">
                        <div class="item-icon open-close-icon">
                            <ng-icon name="bootstrapArrowLeftCircleFill" [class.collapsed]="sidenavCollapsed" class="aligned"></ng-icon>
                        </div>
                        <div [(ngbCollapse)]="sidenavCollapsed" [horizontal]="true">
                            <div class="ps-3 flex-grow-1">Collapse</div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>


    <div class="flex-grow-1 position-relative">
        <div class="content p-4 position-absolute top-0 start-0 bottom-0 end-0 overflow-auto">
            <ngx-spinner type="ball-spin-clockwise" color="#2F4858" [fullScreen]="false" bdColor="rgba(51,51,51,0.0)"></ngx-spinner>
            <div class="h-100 mx-auto" style="max-width: 900px">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>