<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{model.model_tag}}</h4>
    <button
        type="button"
        class="btn-close"
        aria-label="Close button"
        aria-describedby="modal-title"
        (click)="onDismiss.next()"
    ></button>
</div>
<div class="modal-body overflow-auto px-4" style="max-height: 70vh;">

    <!-- Loading -->
    <div *ngIf="isLoading" style="min-height: 229px;" class="d-flex justify-content-center align-items-center">
        <div  class="spinner-border text-primary" role="status"></div>
    </div>

    <!-- Show visualization -->
    <div *ngIf="!isLoading" class="d-flex flex-column gap-2">
        <div class="fw-bold">Visualization</div>
        <canvas #chart baseChart
            [datasets]="bubbleChartDatasets"
            [options]="bubbleChartOptions"
            [legend]="bubbleChartLegend"
            [type]="'bubble'">
        </canvas>
        <table class="table">
            <tbody>
                <tr>
                    <th scope="row">Clustering algorithm</th>
                    <td>{{model.results.algorithm}}</td>
                </tr>
                <tr>
                    <th scope="row">N-Grams</th>
                    <td>{{model.results.ngrams}}</td>
                </tr>
                <tr>
                    <th scope="row">Clusters</th>
                    <td>{{model.results.clusters.length}}</td>
                </tr>
                <tr>
                    <th scope="row">Elapsed time</th>
                    <td>{{model.results.elapsed_time}}</td>
                </tr>
                <tr>
                    <th scope="row">Frequency threshold</th>
                    <td>{{model.results.minimum_frequency.toFixed(2)}} - {{model.results.maximum_frequency.toFixed(2)}}</td>
                </tr>
                <tr>
                    <th scope="row">Keywords Weight</th>
                    <td>{{model.results.keywords_weight}}</td>
                </tr>
                <tr>
                    <th scope="row">Biographies Weight</th>
                    <td>{{model.results.bio_weight}}</td>
                </tr>
                <tr>
                    <th scope="row">Entries</th>
                    <td>{{model.results.entries}}</td>
                </tr>
                    <tr style="border-color: transparent">
                    <th scope="row">Features</th>
                    <td><a role="button" (click)="showKeywords(-1)">{{model.results.features}}</a></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary" [class.disabled]="isLoading" (click)="useModel()">Use this model</button>
    <button type="button" ngbAutofocus class="btn btn-dark"    (click)="onBack.next()">Back</button>
</div>