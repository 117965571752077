import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from 'src/app/utils/utils';
import { FeedbackSettings } from './settings.types';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private util: Utils,
    private http: HttpClient
  ) { }

  async getSettings(): Promise<FeedbackSettings> {
    return new Promise<FeedbackSettings>((resolve, reject) => {
      const url = `${this.util.getAPIUrl()}admin/feedback-settings`;
      this.http.get<FeedbackSettings>(url).subscribe({
        next: resolve,
        error: reject
      });
    });
  }

  async saveSettings(feedbackSettings: FeedbackSettings): Promise<FeedbackSettings> {
    return new Promise<FeedbackSettings>((resolve, reject) => {
      const url = `${this.util.getAPIUrl()}admin/feedback-settings/modify`;
      this.http.patch<FeedbackSettings>(url, feedbackSettings).subscribe({
        next: resolve,
        error: reject
      });
    });
  }
}
