<div class="modal-header">
  <h4 class="modal-title" id="modal-title">
    {{ mode === 'edit' ? 'Edit' : 'Create' }} Form
  </h4>
  <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="form-group mb-3">
    <label for="nameEn" class="form-label">Name</label>
    <div class="input-group has-validation mb-1">
      <input
        name
        [formControl]="nameControl"
        placeholder="Enter new form's name"
        class="form-control"
        [class.is-invalid]="nameControl.invalid && nameControl.touched"
        [class.is-valid]="nameControl.valid && nameControl.touched"
        (submit)="submit()"
        type="text"
        id="name"
      />
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="modal.dismiss()"
  >
    Cancel
  </button>
  <button
    class="btn btn-dark"
    [class.disabled]="nameControl.invalid || !nameControl.dirty"
    type="button"
    (click)="submit()"
  >
    {{ mode === 'edit' ? 'Save' : 'Create' }}
  </button>
</div>
