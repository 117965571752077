<div class="card">
  <div class="d-flex flex-row justify-content-between card-header">
    <div class="d-flex flex-column justify-content-center">
      <div>{{ worker?.name }}</div>
    </div>
    <div class="d-flex gap-1">
      <button
        type="button"
        *ngIf="worker?.state?.state !== 'RUNNING'"
        (click)="start()"
        class="btn btn-dark px-1 py-0"
      >
        <i class="fs-7 bi bi-play-fill"></i>
      </button>
      <button
        type="button"
        *ngIf="worker?.state?.state === 'RUNNING'"
        (click)="stop()"
        class="btn btn-dark px-1 py-0"
      >
        <i class="fs-7 bi bi-stop-fill"></i>
      </button>
      <button
        type="button"
        *ngIf="worker && worker.params.length > 0"
        (click)="openParamsDialog()"
        class="btn btn-dark px-1 py-0"
      >
        <i class="fs-7 bi bi-gear-fill"></i>
      </button>
    </div>
  </div>

  <div class="position-relative card-body d-flex flex-column">
    <div>
      <h5 class="card-title mb-3">{{ worker?.state?.state_message }}</h5>

      <ngb-progressbar
        *ngIf="getWorkerProgress() === -1"
        class="w-100"
        height="20px"
        type="dark"
        [value]="100"
        [striped]="true"
        [animated]="true"
        >{{ getWorkerProgressText() }}</ngb-progressbar
      >
      <ngb-progressbar
        *ngIf="getWorkerProgress() !== -1"
        class="w-100"
        height="20px"
        type="dark"
        [value]="getWorkerProgress()"
        >{{ getWorkerProgressText() }}</ngb-progressbar
      >

      <!-- Current task -->
      <div class="mt-3 d-flex flex-column align-items-center">
        <button
          type="button"
          class="btn btn-dark w-100"
          (click)="tasksCollapsed = !tasksCollapsed"
          [attr.aria-expanded]="!tasksCollapsed"
          aria-controls="collapseExample"
        >
          {{ getRunningLabel() }}
        </button>
      </div>

      <div
        class="tasks-container overflow-auto mt-2 pe-1"
        style="max-height: 400px"
      >
        <ul class="list-group" [(ngbCollapse)]="tasksCollapsed">
          <!-- Pipeline: Tasks List -->
          <ng-container *ngIf="worker?.type === 'pipeline'">
            <a
              *ngFor="let task of worker?.tasks; let i = index"
              class="list-group-item list-group-item-action flex-column align-items- mw-100"
            >
              <div class="d-flex w-100 justify-content-between">
                <h6 class="mb-2">{{ task.name }}</h6>
                <small>{{ this.getTaskStatus(i) }}</small>
              </div>
              <ngb-progressbar
                class="w-100"
                type="primary"
                [value]="getTaskProgress(i)"
              ></ngb-progressbar>
            </a>
          </ng-container>
          <!-- Loop: History List -->
          <ng-container *ngIf="worker?.type === 'loop'">
            <a
              *ngFor="let elem of worker?.state?.history; let i = index"
              class="list-group-item list-group-item-action flex-column align-items- mw-100 fw-normal"
              style="font-size: small"
            >
              {{ elem }}
            </a>
          </ng-container>
        </ul>
        <div *ngIf="resultsVisualizer" class="d-flex flex-row-reverse">
          <button
            type="button"
            (click)="openResultsDialog()"
            class="btn btn-link text-decoration-none p-0"
          >
            Show results
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
