<div id="g_id_onload"
     [attr.data-client_id]="googleClientId"
     data-context="signin"
     data-ux_mode="popup"
     data-auto_prompt="false"
     data-callback="googleLoginCallback"
     data-theme="dark"
></div>

<div class="container d-flex flex-column justify-content-center align-items-center" style="height: 700px">
    <h2>Login</h2>
    <form action="">
        <div class="username mb-3">
            <label for="username">Email</label>
            <input type="text" class="form-control" name="email" id="email" [(ngModel)]="email">
        </div>
        <div class="pwd mb-3">
            <label for="pwd">Password</label>
            <input type="password" class="form-control" name="password" id="password" [(ngModel)]="pwd">
        </div>
        <div class="verification mb-3">
            <label for="pwd">Verification Code
                <input type="password" class="form-control" name="verificationCode" id="verificationCode" [(ngModel)]="verificationCode">
            </label>
            <button class="ms-3 btn btn-success" [disabled]="!isAbleToSend()" *ngIf="pendingResend" (click)="sendEmailVerificationCode()">
                Resend in {{ timeLeftToResend }}s
            </button> 
            <button class="ms-3 btn btn-success" [disabled]="!isAbleToSend()" *ngIf="!pendingResend" (click)="sendEmailVerificationCode()">
                Send 
            </button>  
        </div>
        <div class="d-flex justify-content-center mt-2">
            <button class="d-block btn btn-success" (click)="login()">Login</button>
        </div>
    </form>

    <div>
        <hr>
        <h2 style="text-align: center;">Login with Google</h2>
        <div class="g_id_signin"
            data-type="standard"
            data-shape="rectangular"
            data-theme="filled_blue"
            data-text="continue_with"
            data-size="large"
            data-logo_alignment="left"
            data-width="300"
            data-locale="en"
        ></div>
    </div>


    <div class="alert alert-danger mt-2" role="alert" *ngIf="!loginSuccess">
        {{ err.message ? err.message : err.toString() }}<br>
        {{ err.error ? err.error : '' }}
    </div>
</div>