import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from 'src/app/services/login.service';
import { StripeService } from 'src/app/services/stripe.service';
import { MessageModalComponent } from '../general/modal/message-modal/message-modal.component';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { LayoutService } from 'src/app/services/layout.service';
const moment = require('moment');

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  unpaidInvoices: any = {};
  active = 1;
  page = 1;
  pageSize = 20;
  query: string = "";
  invoiceStatus: string[] = [
    "draft",
    "open",
    "paid",
    "void",
    "uncollectible",
    "past due"
  ]
  selectedStatus: string = "open";
  autoCharged: boolean = false;
  hasMore: boolean = false;
  isLoading: boolean = false;
  displayedColumns: string[] = ['total', 'remaining', 'name', 'weight', 'symbol', 'due', 'action', 'link'];
  dataSource = [];
  startAfter: string = "";
  endBefore: string = "";
  direction: string = "";

  constructor(
    private stripeService: StripeService,
    private loginService: LoginService,
    private router: Router,
    private modalService: NgbModal,
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    this.api.subscribeToAPIChange((prod: boolean) => {
      this.unpaidInvoices = []
      this.router.navigate(['/invoice']);
    })

    this.activatedRoute.queryParamMap.subscribe(params => {
      if (params.get("startAfter")) {
        this.startAfter = params.get("startAfter")!;
      } else {
        this.startAfter = "";
      }

      if (params.get("endBefore")) {
        this.endBefore = params.get("endBefore")!;
      } else {
        this.endBefore = "";
      }

      if (params.get("status"))  {
        this.selectedStatus = params.get('status')!;
      }

      if (params.get("query")) {
        this.query = params.get("query")!;
      } else {
        this.query = '';
      }

      if (params.get("autoCharged")) {
        this.autoCharged = true;
      } else {
        this.autoCharged = false;
      }

      if (params.get('page')) {
        this.page = Number(params.get('page')!);
      } else {
        this.page = 1;
      }

      if (params.get('direction')) {
        this.direction = params.get('direction')!;
      } else {
        this.direction = "";
      }

      this.getUnpaidInvoices({
        startAfter: this.startAfter,
        endBefore: this.endBefore,
        status: this.selectedStatus,
        query: this.query,
        autoCharged: this.autoCharged
      });

    })
    
  }

  getUnpaidInvoices(option: any = {}) {
    this.spinner.show();
    this.stripeService.getUnpaidInvoices(option).subscribe((resp) => {
      this.unpaidInvoices = (resp as any).data;
      this.hasMore = (resp as any).hasMore;
      this.loginService.setLoggedIn(true);
      this.spinner.hide();
      this.dataSource = this.unpaidInvoices;
    }, (err) => {
      this.loginService.setLoggedIn(false);
      this.router.navigate(['/login']);
      this.spinner.hide();
    })
  }

  nextPage(): void {
    this.router.navigate(['/invoice'], {
      queryParams: {
        endBefore: null,
        startAfter: this.unpaidInvoices[this.unpaidInvoices.length - 1].id,
        page: this.page+1,
        direction: 'next'
      },
      queryParamsHandling: 'merge' 
    })
  }

  prevPage(): void {
    this.router.navigate(['/invoice'], {
      queryParams: {
        startAfter: null,
        endBefore: this.unpaidInvoices[0].id,
        page: this.page-1,
        direction: 'prev'
      },
      queryParamsHandling: 'merge'
    })
  }

  hasNext(): boolean {
    if (this.direction === 'next' && this.hasMore) return true;
    if (this.direction === '' && this.hasMore) return true;
    if (this.direction === 'prev') return true;
    return false;
  }

  hasPrev(): boolean {
    if (this.direction === 'prev' && this.hasMore) return true;
    if (this.direction === 'next') return true;
    return false;
  }

  initPage(): void {
    this.page = 0;
  }

  searchInvoices(): void {
    this.router.navigate(['/invoice'], {
      queryParams: {
        page: 1,
        query: this.query,
        startAfter: null,
        endBefore: null,
        direction: null
      },
      queryParamsHandling: 'merge'
    }) 
  }

  resendInvoice(invoice: any): void {
    console.log("resend invoice to " + invoice.id);
    const modalRef = this.modalService.open(MessageModalComponent);
    modalRef.componentInstance.title = "Sending...";
    modalRef.componentInstance.message = "Sending Email...";
    this.stripeService.resendInvoice(
      invoice.id
    ).subscribe(resp => {
      modalRef.componentInstance.title = "Success";
      modalRef.componentInstance.message = "Invoice Sent";
    }, err => {
      modalRef.componentInstance.title = "Error";
      modalRef.componentInstance.message = "err";
    }) 
  }

  selectInvoiceStatus(select: string) {
    this.router.navigate(['/invoice'], {
      queryParams: {
        status: select
      },
      queryParamsHandling: 'merge'
    })
  }

  changeAutoCharge(event: any) {
    this.router.navigate(['/invoice'], {
      queryParams: {
        autoCharged: event.target.checked ? true : null
      },
      queryParamsHandling: 'merge'
    })
  }
  
  getDateFromTimestamp(sec: number) {
    return moment(new Date(sec*1000)).format("DD/MM/yyyy")
  }

  getInvoiceUrlForStripe(invoiceId: string): string {
    return `${environment.invoiceUrl}${invoiceId}?account=${environment.stripeAccountId}`
  }
}
