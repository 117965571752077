import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  constructor(
    private breakpointObserver: BreakpointObserver
  ) { }

  isLessThanSizeForFullDisplay(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 1000px)');
  }
}