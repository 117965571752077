import { Injectable } from '@angular/core';
import { Utils } from '../utils/utils';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private storageService: StorageService,
    private utils: Utils,
  ) { }

  getToken(): string {
    let token = this.storageService.get(this.utils.getApiModeString());
    if (!token) {
      return "" ;
    }
    return token;
  }

  setToken(token: string) {
    this.storageService.save(
      this.utils.getApiModeString(), 
      token
    );
  }

  setLoggedId(id: string): void {
    this.storageService.save("userId", id);
  }

  getLoggedId() {
    return this.storageService.get("userId");
  }
}
