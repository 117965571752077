import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PayoutService } from 'src/app/services/payout.service';
import { MatTableDataSource } from '@angular/material/table';
import { RENT_REQUEST_STATUS } from 'src/app/utils/constant';
import { CityService } from 'src/app/services/city.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { Moment } from 'moment';
const moment = require('moment');
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSelectChange } from '@angular/material/select';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DateUtil } from 'src/app/utils/dateUtil';
import { PaymentService } from 'src/app/services/payment.service';
import { PayoutConfrimModalComponent } from '../general/modal/payout-confrim-modal/payout-confrim-modal.component';
import { MatDialog } from '@angular/material/dialog';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-payouts',
  templateUrl: './payouts.component.html',
  styleUrls: ['./payouts.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  encapsulation: ViewEncapsulation.None,
})
export class PayoutsComponent implements OnInit {
  payouts: any[] = [];
  totalNumberOfPayouts: number = 0;
  currentDate = new Date();
  selectedMonth: number = this.currentDate.getMonth() + 1;
  selectedYear: number = this.currentDate.getFullYear();
  selectedCity: string = 'Montreal';
  selectedDateObject: Date = new Date(this.selectedYear, this.selectedMonth - 1);
  limit: number = 20;
  dataSource: any;
  displayedColumns: string[] = ['address', 'bedroom',  'payout_date', 'owner', 'payout', 'actual', 'tag', 'paid_checkbox', 'status'];
  accumulatedRowspan: boolean[] = []
  cities: any;
  date = new FormControl(moment());
  pageSize: number = 20;
  pageIndex: number = 1;

  constructor(
    private payoutService: PayoutService,
    public cityService: CityService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public dateUtil: DateUtil,
    private paymentService: PaymentService,
    public dialog: MatDialog
  ) { 
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.getCities();
      this.initPaginator(params);
      this.getPayouts();
    })
  }

  getCities() {
    this.cityService.getCities().subscribe((resp) => {
      this.cities = resp;
    })
  }

  initPaginator(params: any): void {
    if ('page' in params && Number(params.page) > 0) {
      this.pageIndex = Number(params.page);
    } else {
      this.pageIndex = 1;
    }
    console.log(this.pageIndex)

    if ('city' in params) {
      this.selectedCity = params.city;
    }
    
    // month is 1-based
    if ('month' in params && Number(params.month) >= 1 && Number(params.month) <= 12) {
      this.selectedMonth = Number(params.month);
    }

    if ('year' in params) {
      this.selectedYear = Number(params.year);
    }

  }

  getPayouts() {
    this.payoutService.getPayouts({
      selectedPeriod: {
        year: this.selectedYear,
        month: this.selectedMonth
      },
      city: this.selectedCity,
      skip: (this.pageIndex - 1) * this.limit,
      limit: this.limit
    }).subscribe((resp) => {
      this.payouts = (resp as any).results;
      this.totalNumberOfPayouts = (resp as any).count;
      this.dataSource = new MatTableDataSource<any>(this.payouts);
      this.initRowspan();
    })
  }

  initRowspan(): void {
    let index = 1;
    this.accumulatedRowspan = [true];
    while (index < this.payouts.length) {
      const prevAddr = this.payouts[index - 1]._id;
      const addr = this.payouts[index]._id;
      if (prevAddr === addr) {
        this.accumulatedRowspan.push(false)
      } else {
        this.accumulatedRowspan.push(true);
      }
      index++
    } 
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value ?? moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    // this.selectedMonth = normalizedMonthAndYear.month() + 1;
    // this.selectedYear = normalizedMonthAndYear.year();
    this.date.setValue(ctrlValue);
    datepicker.close();

    this.router.navigate(['/payout'], {
      queryParams: {
        page: 1,
        month: normalizedMonthAndYear.month() + 1,
        year: normalizedMonthAndYear.year()
      },
      queryParamsHandling: "merge"
    })

    // this.getPayouts();
  }

  getOwnerId(payout: any): string {
    return payout.apartment.owner.userId;
  }

  getOwnerName(payout: any): string {
    if (this.isListedByHivenue(payout)) {
      return payout.latestApartment[0].owner.realName;
    }
    return payout.reservationId.apartment.owner.name;
  }

  getPayoutValue(payout: any): number {
    return payout.amountDue;
  }

  getActualPaidValue(payout: any): number {
    return payout.actualPaid;
  }


  isPaid(payout: any): boolean {
    return payout.status === RENT_REQUEST_STATUS.PAID 
      || payout.status === RENT_REQUEST_STATUS.PARTIALLY_PAID;
  }

  isStudio(payout: any): boolean {
    return payout.reservationId.apartment.isStudio;
  }

  isFirstPayout(payout: any): boolean {
    return payout.isFirstPayout;
  }

  isLastPayout(payout: any): boolean {
    return payout.isLastPayout;
  }

  isListedByHivenue(payout: any): boolean {
    return 'listedByHivenue' in payout.latestApartment[0] && payout.latestApartment[0].listedByHivenue === true;
  }

  getPaymentStatusString(payout: any): string {
    switch (payout.status) {
      case RENT_REQUEST_STATUS.PAID:
        return 'Paid';
      case RENT_REQUEST_STATUS.NO:
        return 'Unpaid';
      case RENT_REQUEST_STATUS.PARTIALLY_PAID:
        return 'Partially Paid';
    }
    return payout.status;
  }

  getAddress(addr: string): string {
    return addr.split(',')[0]
  }

  getRowspan(index: number) {
    const next = this.accumulatedRowspan.indexOf(true, index+1);
    if (next === -1) {
      return this.accumulatedRowspan.length - index + 1;
    }
    return this.accumulatedRowspan.indexOf(true, index+1) - index;
  }

  createPayout(payout: any) {
    let startDate = this.dateUtil.createDate(payout.moveInDate);
    let endDate = this.dateUtil.createDate(payout.moveOutDate);
    // if is not the first payout then it will be an normal payout which always starts at the 
    // beginning of the month
    if (!this.isFirstPayout(payout)) {
      startDate = moment([this.selectedYear, this.selectedMonth, 1]).toDate();
    }
    // if it is not the ending month then it will be an normal payout which alwyas ends
    // at the end of the month
    if (!(this.selectedMonth === moment(payout.moveOutDate).month() 
      && this.selectedYear === moment(payout.moveOutDate).year())) {
        endDate = moment([this.selectedYear, this.selectedMonth, 1]).endOf('M').toDate();
    }
  }

  handleCityChange(event: MatSelectChange) {
    this.selectedCity = event.value;
    this.router.navigate(['/payout'], {
      queryParams: { city: this.selectedCity, page: 1 },
    })
  }

  handlePageChange(event: PageEvent) {
    if (event.previousPageIndex !== event.pageIndex) {
      this.router.navigate(['/payout'], {
        queryParams: { page: event.pageIndex + 1 },
        queryParamsHandling: "merge"
      })
    }
  }

  handlePaymentCheck(payout: any, event: MatCheckboxChange) {
    if (event.checked) {
      payout.status = RENT_REQUEST_STATUS.PAID;
      payout.actualPaid = payout.amountDue;
      payout.paidDate = new Date();
    } else { // remove payout
      payout.status = RENT_REQUEST_STATUS.NO;
    }
    this.paymentService.paymentPaidToHost(payout).subscribe((resp) => {
    })
  }


  handlePayoutEdit(payout: any) {
    // open popup
    const dialogRef = this.openDialog({
      title: `Payout to ${payout.reservationId.apartment.owner.name}`,
      subtitle: `From ${moment.utc(payout.period.startDate).format("DD MMMM, YYYY")} to ${moment.utc(payout.period.endDate).format("DD MMMM, YYYY")}`,
      amountDue: payout.amountDue,
      actualPaid: payout.actualPaid,
      note: payout.note,
      requestId: payout.reservationId._id
    });

    dialogRef.afterClosed().subscribe(result => {
      payout.amountDue = result.amountDue;
      payout.actualPaid = result.actualPaid;
      payout.note = result.note
    })
  }

  openDialog(data: any) {
    return this.dialog.open(PayoutConfrimModalComponent, {
      data: data
    });
  }
}