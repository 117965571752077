import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIconsModule } from '@ng-icons/core';
import { bootstrapExclamationTriangleFill } from '@ng-icons/bootstrap-icons';
import { TokensAccordionComponent } from './tokens-accordion/tokens-accordion.component';



@NgModule({
  declarations: [
    ConfirmModalComponent,
    TokensAccordionComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    NgIconsModule.withIcons({ 
      bootstrapExclamationTriangleFill
    }),
  ],
  exports: [
    TokensAccordionComponent
  ]
})
export class SharedMatchingModule { }
