import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Keyword } from '../ai-results.types';
import { WeightLevel } from './top-keywords.types';

const WEIGHT_LEVELS: WeightLevel[] = [
  {
    minWeight: 0.20,
    style: {
      'font-size': '20px',
      'background-color': '#262626 !important'
    }
  },
  {
    minWeight: 0.15,
    style: {
      'font-size': '18px',
      'background-color': '#505050 !important'
    }
  },
  {
    minWeight: 0.10,
    style: {
      'font-size': '15px',
      'background-color': '#707070 !important'
    }
  },
  {
    minWeight: 0.07,
    style: {
      'font-size': '13px',
      'background-color': '#707070 !important'
    }
  }

]

const DEFAULT_WEIGHT: WeightLevel = {
  minWeight: 0.10,
  style: {
    'font-size': '15px',
    'background-color': '#262626 !important'
  }
}

@Component({
  selector: 'app-top-keywords',
  templateUrl: './top-keywords.component.html',
  styleUrls: ['./top-keywords.component.scss']
})
export class TopKeywordsComponent implements OnInit, AfterViewInit {

  public keywords: Keyword[] = [];
  public cluster: number = -1;
  public title: string | undefined;

  constructor(public modal: NgbActiveModal){}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  isHidden(keyword: Keyword): any {
    if (this.cluster === -1)
      return keyword.weight === 0.0;
    for (const level of WEIGHT_LEVELS)
      if (keyword.weight > level.minWeight)
        return false;
    return true;
  }

  getLevel(keyword: Keyword): WeightLevel {
    if (this.cluster === -1)
      return DEFAULT_WEIGHT;
    let curLevel: WeightLevel = {
      minWeight: 0.0,
      style: {}
    }
    for (const level of WEIGHT_LEVELS)
      if (level.minWeight > curLevel.minWeight && keyword.weight >= level.minWeight)
      curLevel = level;
    return curLevel;
  }

  formatStyle(keyword: Keyword): string {
    const level = this.getLevel(keyword);
    let formattedStyle = ''
    for (const [k, v] of Object.entries(level.style)) {
      formattedStyle += k + ':' + v + ';'
    }
    return formattedStyle;
  }

}
