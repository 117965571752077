
<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Add Keyword</h4>
    <button
        type="button"
        class="btn-close"
        (click)="modal.dismiss()"
    ></button>
</div>
<div class="modal-body" style="max-height: 60vh;">
    <ngb-accordion *ngIf="categories && categories.length > 0" class="mb-4" [closeOthers]="true">
        <ngb-panel *ngFor="let category of categories" [id]="category._id">

            <!-- Category Title -->
            <ng-template ngbPanelTitle>
                <div class="w-100 pe-3 d-flex justify-content-between">
                    <!-- Category Name -->
                    <span>{{category.name.en}}</span>
                </div>
            </ng-template>

            <!-- Category Content (Tokens) -->
            <ng-template ngbPanelContent>
                <div class="d-flex flex-column gap-2">
                    <!-- Tokens Cloud -->
                    <div class="d-flex justify-content-center flex-wrap gap-2">
                        <ng-container *ngFor="let keyword of category.keywords">
                            <div *ngIf="alreadyChosenKeywords.indexOf(keyword._id) === -1" class="align-self-center">
                                <div class="badge bg-light text-dark fw-bold d-flex gap-1 py-1 px-1 keyword border"
                                    style="border-radius: 50px; font-size: 12px" [class.selected]='keyword._id === selectedKeywordId' (click)="onKeywordClick(keyword._id)" role="button">
                                    <div class="align-middle" style="line-height: 26px">{{ keyword.name.en }}</div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button class="btn btn-dark" [class.disabled]="!this.selectedKeywordId" type="button" (click)="submit()">Add</button>
</div>
