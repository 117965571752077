import {
  AfterViewInit,
  Component,
  OnInit,
  Type,
  ViewChild,
} from '@angular/core'
import {
  FormArray,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FeedbackService } from '../../../services/feedback.service'
import {
  CheckboxOptions,
  FeedbackForm,
  InputType,
  Question,
  QuestionOptions,
  RangeOptions,
  SelectOptions,
  TextOptions,
  UserStatus,
} from '../../../services/feedback.type'

@Component({
  selector: 'app-form-add-modal',
  templateUrl: './question-add-modal.component.html',
  styleUrls: ['./question-add-modal.component.css'],
})
export class QuestionAddModalComponent implements OnInit {
  @ViewChild('form', { read: NgForm }) form: any

  private question: Question | undefined
  private formId: string | undefined
  public inputType = InputType
  public userStatus = UserStatus
  public mode: 'edit' | 'add' = 'edit'

  // initial form values
  public formGroup = new FormGroup({
    title: new FormGroup({
      fr: new FormControl('', [Validators.required]),
      en: new FormControl('', [Validators.required]),
    }),
    required: new FormControl(true),
    inputType: new FormControl(InputType.TEXT, [Validators.required]),
    userStatus: new FormControl(UserStatus.BOTH as UserStatus, [
      Validators.required,
    ]),
    textInputOptions: new FormGroup({
      fr: new FormControl(''),
      en: new FormControl(''),
    }),
    rangeInputOptions: new FormGroup({
      min: new FormControl(0, [Validators.required]),
      max: new FormControl(100, [Validators.required]),
    }),
    checkboxAndSelectInputOptions: new FormArray(
      [
        new FormGroup(
          {
            fr: new FormControl('', Validators.required),
            en: new FormControl('', Validators.required),
          },
          Validators.required,
        ),
      ],
      [Validators.minLength(1)],
    ),
    // checkboxAndSelectInputOptions: new FormArray(
    //   [] as FormGroup<{
    //     fr: FormControl<string | null>
    //     en: FormControl<string | null>
    //   }>[],
    //   [Validators.minLength(1)],
    // ),
  })

  constructor(
    public modal: NgbActiveModal,
    private feedbackService: FeedbackService,
  ) {}

  ngOnInit(): void {
    if (!this.question || !this.formId) {
      this.modal.dismiss()
      return
    }
    this.formGroup.controls.title.setValue({
      fr: this.question.title.fr,
      en: this.question.title.en,
    })
    this.formGroup.controls.required.setValue(this.question.required)
    this.formGroup.controls.inputType.setValue(this.question.inputType)
    this.formGroup.controls.userStatus.setValue(this.question.userStatus)
    this.setInputOptions()
  }

  isInvalidForm() {
    const validArray = Object.entries(this.formGroup.controls).map((entry) => {
      const [key, value] = entry
      if (
        key == 'textInputOptions' &&
        this.formGroup.controls.inputType.value != InputType.TEXT
      )
        return true
      if (key == 'rangeInputOptions') {
        if (this.formGroup.controls.inputType.value != InputType.RANGE)
          return true
        return this.isRangeValid()
      }
      if (key == 'checkboxAndSelectInputOptions') {
        if (
          this.formGroup.controls.inputType.value != InputType.CHECKBOX &&
          this.formGroup.controls.inputType.value != InputType.SELECT
        )
          return true
        return this.isChoicesValid()
      }

      return value.valid
    })
    return validArray.includes(false)
  }

  isRangeValid(): boolean {
    if (
      this.formGroup.controls.rangeInputOptions.controls.min.value === null ||
      this.formGroup.controls.rangeInputOptions.controls.max.value === null
    )
      return false
    return (
      this.formGroup.controls.rangeInputOptions.controls.max.value >
      this.formGroup.controls.rangeInputOptions.controls.min.value
    )
  }

  isChoicesValid(): boolean {
    const validArray = this.formGroup.controls.checkboxAndSelectInputOptions.controls.map(
      (control) => control.valid,
    )
    return !validArray.includes(false)
  }

  buildInputOptions(): QuestionOptions {
    switch (this.formGroup.controls.inputType.value) {
      case InputType.TEXT:
        return {
          [InputType.TEXT]: {
            placeholder: this.formGroup.controls.textInputOptions.value,
          },
        } as QuestionOptions
      case InputType.RANGE:
        return {
          [InputType.RANGE]: this.formGroup.controls.rangeInputOptions.value,
        } as QuestionOptions
      case InputType.SELECT:
        return {
          [InputType.SELECT]: {
            values: this.formGroup.controls.checkboxAndSelectInputOptions.value,
          },
        } as QuestionOptions
      case InputType.CHECKBOX:
        return {
          [InputType.CHECKBOX]: {
            values: this.formGroup.controls.checkboxAndSelectInputOptions.value,
          },
        } as QuestionOptions
      default:
        return this.formGroup.controls.textInputOptions.value as QuestionOptions
    }
  }

  async submit() {
    if (this.isInvalidForm() || !this.question || !this.formId) return
    const payload: Question = {
      _id: '',
      title: {
        fr: this.formGroup.controls.title.controls.fr.value as string,
        en: this.formGroup.controls.title.controls.en.value as string,
      },
      options: this.buildInputOptions(),
      required: this.formGroup.controls.required.value as boolean,
      inputType: this.formGroup.controls.inputType.value as InputType,
      userStatus: this.formGroup.controls.userStatus.value as UserStatus,
    }
    try {
      if (this.mode === 'edit')
        this.modal.close(
          await this.feedbackService.modifyQuestion(
            this.formId,
            this.question._id,
            payload,
          ),
        )
      else
        this.modal.close(
          await this.feedbackService.createQuestion(this.formId, payload),
        )
    } catch (e) {
      console.error(e)
      return
    }
  }

  onInputTypeChange(type: InputType) {
    this.formGroup.controls.inputType.setValue(type)
  }

  addChoice() {
    this.formGroup.controls.checkboxAndSelectInputOptions.push(
      new FormGroup(
        {
          fr: new FormControl('', Validators.required),
          en: new FormControl('', Validators.required),
        },
        Validators.required,
      ),
    )
  }

  removeChoice(index: number) {
    this.formGroup.controls.checkboxAndSelectInputOptions.removeAt(index)
  }

  setInputOptions(): void {
    if (!this.question) return
    switch (this.question.inputType) {
      case InputType.TEXT:
        this.formGroup.controls.textInputOptions.setValue(
          (this.question.options as TextOptions).text.placeholder,
        )
        break
      case InputType.CHECKBOX:
        this.formGroup.controls.checkboxAndSelectInputOptions.removeAt(0)
        ;(this.question.options as CheckboxOptions).checkbox.values.forEach(
          (value) =>
            this.formGroup.controls.checkboxAndSelectInputOptions.push(
              new FormGroup(
                {
                  fr: new FormControl(value.fr, Validators.required),
                  en: new FormControl(value.en, Validators.required),
                },
                Validators.required,
              ),
            ),
        )
        break
      case InputType.SELECT:
        this.formGroup.controls.checkboxAndSelectInputOptions.removeAt(0)
        ;(this.question.options as SelectOptions).select.values.forEach(
          (value) =>
            this.formGroup.controls.checkboxAndSelectInputOptions.push(
              new FormGroup(
                {
                  fr: new FormControl(value.fr, Validators.required),
                  en: new FormControl(value.en, Validators.required),
                },
                Validators.required,
              ),
            ),
        )
        break
      case InputType.RANGE:
        this.formGroup.controls.rangeInputOptions.controls.min.setValue(
          (this.question.options as RangeOptions).range.min,
        )
        this.formGroup.controls.rangeInputOptions.controls.max.setValue(
          (this.question.options as RangeOptions).range.max,
        )
        break
      default:
        break
    }
  }
}
