import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  @Input() title = 'Please confirm';
  @Input() text = 'Are you sure you want to apply these changes?';
  @Input() icon = 'bootstrapExclamationTriangleFill';
  @Input() confirmText = 'Yes';
  @Input() dismissText = 'No';

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
