import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { WorkersService } from 'src/app/services/workers.service';
import { Task, Worker, WorkerParamUpdate } from 'src/app/services/workers.types';
import { WorkerParamsComponent } from './worker-params/worker-params.component';

@Component({
  selector: 'app-worker',
  templateUrl: './worker.component.html',
  styleUrls: ['./worker.component.scss']
})
export class WorkerComponent implements OnInit, OnDestroy {

  @Input() workerId = '';
  @Input() resultsVisualizer: any | undefined = undefined

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  
  worker: Worker | undefined = undefined;
  tasksCollapsed = true;

  constructor(
    private workersService: WorkersService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.workersService.getWorker(this.workerId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((worker) => {
        this.worker = worker;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  getRunningLabel(): string {
    if (this.worker === undefined) {
      return 'Idle'
    }
    else if (this.worker.state.state === 'RUNNING') {
        const task = this.getTask(this.worker.state.current_task);
        if (task) {
          let label = '';
          if (this.worker.type === 'pipeline') {
            label +=  'Task ' + (this.worker.state.current_task + 1).toString() +
                      '/' + this.worker.tasks.length + ' - ' 
          }
          label += task.running_label
          return label;

        } else {
          return 'Initializing...'
        }
    } else {
      return 'Idle'
    }
  }

  getTask(index: number): Task | undefined {
    if (this.worker === undefined) {
      return undefined;
    }
    if (index < 0 || index > this.worker.tasks.length) {
      return undefined;
    }
    return this.worker.tasks[index];
  }

  getTaskStatus(index: number): string {
    if (this.worker === undefined || this.worker.state.current_task === -1)
      return ''
    else if (index === this.worker.state.current_task)
      return (this.worker.state.task_progress * 100.0).toFixed(1) + '%'
    else if (index < this.worker.state.current_task)
      return '100%.'
    else
      return ''
  }

  getTaskProgress(index: number): number {
    if (this.worker === undefined || this.worker.state.current_task === -1)
      return 0.0
    else if (index === this.worker.state.current_task)
      return this.worker.state.task_progress * 100.0
    else if (index < this.worker.state.current_task)
      return 100.0
    else
      return 0.0
  }

  getWorkerProgress(): number {
    if (this.worker === undefined || this.worker.state.current_task === -1)
      return 0.0
    if (this.worker.type === 'pipeline')
      return this.worker.state.worker_progress * 100;
    else
      return -1
  }

  getWorkerProgressText(): string {
    if (this.worker === undefined || this.worker.state.state !== 'RUNNING') {
      return ''
    }
    if (this.worker.state.unit === '%'){
      return (this.worker.state.worker_progress*100).toFixed(1) + ' %'
    } else {
      return (this.worker.state.worker_progress).toFixed() + ' ' + this.worker.state.unit;
    }
  }

  start() {
    if (this.worker) this.workersService.start(this.worker.id);
  }

  stop() {
    if (this.worker) this.workersService.stop(this.worker.id);
  }

  getTextLabel(key: string): string {
    return key[0].toUpperCase() + key.substring(1).toLowerCase()
  }

  openResultsDialog() {
    if (this.resultsVisualizer) {
      const modalRef = this.modalService.open(this.resultsVisualizer, { centered: true, size: 'lg' });
      modalRef.componentInstance.worker = this.worker;
    }   
  }

  openParamsDialog() {
    const modalRef = this.modalService.open(WorkerParamsComponent, { centered: true, size: 'md' });
    modalRef.componentInstance.params = this.worker?.params;
    modalRef.closed.subscribe((updatedParams: WorkerParamUpdate[]) => {
      // Update on the server
      this.workersService.updateParams(this.workerId, updatedParams).subscribe({
          next: () => {
            this.workersService.updateWorkers();
          }
      });
    })
  }

}
