import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BookingRequestsRoutingRoutingModule } from './booking-requests-routing-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BookingRequestsRoutingRoutingModule
  ]
})
export class BookingRequestsRoutingModule { }
