<div class="d-flex flex-column gap-3" style="min-height: 100%;">

    <!-- Component Header -->
    <div class="row">
        <h3 class="col-12 col-md-6 col-lg-5">Pending Feedbacks</h3>
        <div class="col-12 col-md-6 col-lg-7">
            <input
              type="text"
              class="form-control"
              [formControl]="searchControl"
              placeholder="Search"
              aria-label="search"
              aria-describedby="button-addon2"
              name="search"
            />
        </div>
    </div>
    <ng-container *ngIf="response && response.pendings && response.pendings.length > 0">
        <ngb-accordion
        *ngIf="response && response.pendings && response.pendings.length > 0"
        [closeOthers]="true"
        activeIds="static-1"
        >
            <ng-container *ngFor="let pending of response.pendings">
            <ngb-panel>
                <ng-template ngbPanelTitle>
                    <div class="w-100 pe-3 d-flex justify-content-between">
                        <span class="fw-bold"> {{pending.userId.email}} </span>
                        <div class="d-flex justify-content-end align-items-center gap-2">
                            <span class="fw-bold text-dark">
                                {{ pending.userStatus.toUpperCase() }}
                            </span>
                            <div
                                class="rounded-circle"
                                style="width:12px;height:12px"                        
                                [style.background-color]="pending.emailStatus === 'sent' ? 'green': 'orange'"
                            >
                            </div>
                            <button
                                type="button"
                                class="btn btn-dark px-2 py-1"
                                (click)="sendEmail(pending, $event)"
                            >
                                <i *ngIf="loadingEmail !== pending._id " style="font-size:14px;" class="bi bi-send-fill"></i>
                                <div *ngIf="loadingEmail === pending._id " style="width:14px;height:14px" class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="d-grid">
                    <div class="mb-3">
                        <strong>First Mail Attempt:</strong>
                        {{formatDate(pending.createdAt)}}
                    </div>
                    <div class="mb-3">
                        <strong>Apartment ID:</strong>
                        {{pending.rentRequest.apartmentId._id}}
                    </div>
                    <div class="mb-3">
                        <strong>Apartment Name:</strong>
                        {{pending.rentRequest.apartmentId.name}}
                    </div>
                    <div class="mb-3">
                        <strong>Move In Date:</strong>
                        {{ formatDate(pending.rentRequest.moveInDate) }}
                    </div>
                    <div class="mb-3">
                        <strong>Move Out Date:</strong>
                        {{ formatDate(pending.rentRequest.moveOutDate) }}
                    </div>
                    </div>
                </ng-template>
            </ngb-panel>
            </ng-container>
        </ngb-accordion>
        <div class="w-100 mt-3 d-flex justify-content-between">
            <button 
                class="btn btn-dark mb-3"
                (click)="triggerCronjob()">
                Trigger Cronjob
            </button>
            <app-paginator
                [length]="response.itemCount"
                [pageSize]="response.pageSize"
                [page]="response.pageNumber"
                (change)="loadPage($event.index)"
            ></app-paginator>
        </div>
    </ng-container>
</div>


