
<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{mode === 'edit' ? 'Edit' : 'Create'}} Category</h4>
    <button
        type="button"
        class="btn-close"
        (click)="modal.dismiss()"
    ></button>
</div>
<div class="modal-body">
    <form #form [formGroup]="formGroup">
        <div class="form-group mb-3">
            <label for="nameEn" class="form-label">Name</label>
            <div class="input-group has-validation mb-1">
                <span class="input-group-text font-monospace" id="inputGroupPrepend">en</span>
                <input 
                    formControlName="nameEn"
                    class="form-control"
                    [class.is-invalid]="formGroup.controls.nameEn.invalid"
                    [class.is-valid]="formGroup.controls.nameEn.valid"
                    type="text"
                    id="nameEn">
                <div *ngIf="getErrorMessage('nameEn')" class="invalid-feedback">
                    {{getErrorMessage('nameEn')}}
                </div>
            </div>
            <div class="input-group has-validation mb-3">
                <span class="input-group-text font-monospace" id="inputGroupPrepend">fr</span>
                <input 
                    formControlName="nameFr"
                    class="form-control"
                    [class.is-invalid]="formGroup.controls.nameFr.invalid"
                    [class.is-valid]="formGroup.controls.nameFr.valid"
                    type="text"
                    id="nameFr">
                <div *ngIf="getErrorMessage('nameFr')" class="invalid-feedback">
                    {{getErrorMessage('nameFr')}}
                </div>
            </div>

            <div class="input-group has-validation mb-1">
                <span class="input-group-text font-monospace" id="inputGroupPrepend">en</span>
                <input 
                    formControlName="subtitleEn"
                    class="form-control"
                    [class.is-invalid]="formGroup.controls.subtitleEn.invalid"
                    [class.is-valid]="formGroup.controls.subtitleEn.valid"
                    type="text"
                    id="subtitleEn">
                <div *ngIf="getErrorMessage('subtitleEn')" class="invalid-feedback">
                    {{getErrorMessage('subtitleEn')}}
                </div>
            </div>
            <div class="input-group has-validation mb-3">
                <span class="input-group-text font-monospace" id="inputGroupPrepend">fr</span>
                <input 
                    formControlName="subtitleFr"
                    class="form-control"
                    [class.is-invalid]="formGroup.controls.subtitleFr.invalid"
                    [class.is-valid]="formGroup.controls.subtitleFr.valid"
                    type="text"
                    id="subtitleFr">
                <div *ngIf="getErrorMessage('subtitleFr')" class="invalid-feedback">
                    {{getErrorMessage('subtitleFr')}}
                </div>
            </div>

            <label for="min" class="form-label">Minimum selection</label>
            <div class="d-flex gap-2">
                <input #minRange formControlName="min" type="range" class="form-range flex-grow-1" min="0" max="3" step="1" id="min">
                <div style="width: 10px;">{{formGroup.controls.min.value}}</div>
            </div>

            <label for="max" class="form-label">Maximum selection</label>
            <div class="d-flex gap-2">
                <input #maxRange formControlName="max" type="range" class="form-range flex-grow-1" min="3" max="9" step="1" id="min">
                <div style="width: 10px;">{{formGroup.controls.max.value}}</div>
            </div>

        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button class="btn btn-dark" [class.disabled]="formGroup.invalid" type="button" (click)="submit()">{{mode === 'edit' ? 'Save' : 'Create'}}</button>
</div>
