import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from '../services/settings.service';
import { FeedbackSettings } from '../services/settings.types';

@Component({
  selector: 'app-matching-settings',
  templateUrl: './matching-settings.component.html',
  styleUrls: ['./matching-settings.component.css']
})
export class MatchingSettingsComponent implements OnInit {

  feedbackSettings = {} as FeedbackSettings;

  public formGroup = new FormGroup({
    timeFeedbackInputTenant: new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$")]),
    timeFeedbackInputHost: new FormControl('',[Validators.required, Validators.pattern("^[0-9]*$")]),
  });

  constructor(private feedbackSettingsService: SettingsService) { }

  async ngOnInit() {
    this.feedbackSettings = await this.feedbackSettingsService.getSettings();

    this.formGroup.controls.timeFeedbackInputTenant.setValue(this.feedbackSettings.percentBeforeFeedbackTenant.toString());
    this.formGroup.controls.timeFeedbackInputHost.setValue(this.feedbackSettings.hostFeedbackAfterStay.toString());
  }

  async save(): Promise<void> {
    this.feedbackSettings.percentBeforeFeedbackTenant = Number(this.formGroup.controls.timeFeedbackInputTenant.value)
    this.feedbackSettings.hostFeedbackAfterStay = Number(this.formGroup.controls.timeFeedbackInputHost.value)

    this.feedbackSettings = await this.feedbackSettingsService.saveSettings(this.feedbackSettings);
  }
}
