

    <div class="row justify-content-center m-3">
        <div class="col col-12 col-md-11 col-lg-10 col-xl-9 card p-0">
            <div class="card-header">
                Input
            </div>
            <div class="position-relative card-body d-flex flex-column">

                <!-- Number of users -->
                <div class="text-center">Number of users</div>
                <div class="row justify-content-center mb-3">
                    <div class="d-flex gap-2 col-12 col-sm-9 col-md-8 col-lg-6 col-xl-5">
                        <input class="form-range flex-grow-1" #slider [formControl]="usersNumberControl" type="range" min="1" [max]="maxUsersNumber" step="1">
                        <div style="width:20px;">{{slider.value}}</div>
                    </div>
                </div>

                <div
                    class="bios-container overflow-auto mt-2 pe-1"
                    style="max-height: 400px"
                >
                <ul class="list-group">
                    <!-- Pipeline: Tasks List -->
                    <ng-container *ngFor="let bio of bios; let i = index">
                    <ng-container *ngIf="usersNumberControl.value !== null && i <= usersNumberControl.value">
                    <a
                        
                        class="list-group-item list-group-item-action d-flex flex-row gap-2 align-items-center mw-100 fw-normal bg-light py-0 ps-0 position-relative"
                        style="font-size: small;"
                        [class.border-top-0]="i !== 0"
                        [class.border-bottom-0]="i !== usersNumberControl.value && i === expandedBio"
                        [class.rounded-bottom]="i === usersNumberControl.value && i !== expandedBio"
                        type="button"
                    >
                        <div type="button" class="flex-grow-1 ps-3"  (click)="toggleExpand(i)" style="font-family: monospace, monospace; height: 43px; line-height: 43px; vertical-align: center;">{{ bio.id }}</div>
                        <button *ngIf="i !== 0" [class.disabled]="response === undefined" type="button" (click)="showCommonKeywords(i)" class="btn btn-dark p-0" style="height: 26px" container="body" ngbTooltip="Common keywords" tooltipClass="custom-tooltip" placement="top"><i class="bi bi-people-fill px-1"></i></button>
                        <button type="button" [class.disabled]="response === undefined" (click)="showKeywords(i)" class="btn btn-dark p-0" style="height: 26px" container="body" ngbTooltip="User keywords" tooltipClass="custom-tooltip" placement="top"><i class="bi bi-person-fill px-1"></i></button>
                        <span
                            *ngIf="response && i !== 0 && response.matches[i-1].match_level === 'good'"
                            class="badge badge-dark bg-dark top-50 start-50 translate-middle"
                            style="border-radius: 50px; font-size: 13px; background-color: #b9cb45 !important; position: absolute"
                            >Good Match!</span
                        >
                        <span
                            *ngIf="response && i !== 0 && response.matches[i-1].match_level === 'great'"
                            class="badge badge-dark bg-dark top-50 start-50 translate-middle"
                            style="border-radius: 50px; font-size: 13px; background-color: #48cd66 !important; position: absolute"
                            >Great Match!</span
                        >
                
                    </a>
                    <div [ngbCollapse]="i !== expandedBio">
                    <a
                        class="list-group-item bio-details d-flex flex-row gap-2 align-items-center mw-100 fw-normal"
                        [class.rounded-bottom]="i === usersNumberControl.value"
                        style="font-size: small"
                    >
                    <div class="d-flex flex-column flex-grow-1 gap-3">
                        <div>{{bio.bio}}</div>
                        <div class="d-flex flex-row justify-content-center align-items-center flex-wrap gap-2">
                            <div *ngFor="let keyword of bio.keywords" class="align-self-center">
                                <span
                                    class="badge badge-dark bg-dark"
                                    style="border-radius: 50px; font-size: 13px; background-color: #707070 !important"
                                    >{{ keyword }}</span
                                >
                            </div>
                        </div>
                    </div>
                    <button type="button" (click)="generate(i)" class="btn btn-light p-0" style="height: 40px"><i class="bi bi-repeat px-2"></i></button>
                    </a>
                    </div>
                    
                </ng-container>
            </ng-container>
                  </ul>
                  </div>
            <div class="d-flex justify-content-between">
                <button type="button" (click)="generateAll()" class="btn btn-dark px-1 mt-2"><i class="bi bi-repeat pe-1"></i>Refresh</button>
                <button type="button" (click)="match()" class="btn btn-dark px-1 mt-2"><i class="bi bi-arrow-left-right pe-1"></i>Match</button>
            </div>
        </div>
        </div>
    </div>
    

