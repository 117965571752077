<div class="container">
    <div class="row border-bottom border-top py-2 mt-2" *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
        <div class="col fw-bold">Time</div>
        <div class="col fw-bold">Listing Title</div>
        <div class="col fw-bold">Name</div>
        <div class="col fw-bold">Phone</div>
        <div class="col fw-bold">Email</div>
        <div class="col fw-bold">Move In Month</div>
        <div class="col fw-bold">Budget</div>
        <div class="col fw-bold">Length of Stay</div>
        <div class="col fw-bold">Message</div>
    </div>
    <div class="row border-bottom py-2 clickable" (click)="showDetails(sentMessage)" *ngFor="let sentMessage of sentMessages">
        <div class="col fw-bold text-truncate">
            {{ dateUtil.createDate(sentMessage.timestamp) | date: 'mediumDate' }}
        </div>
        <div class="col fw-bold text-truncate">
            {{ sentMessage.apartmentId.name }}
        </div>
        <div class="col fw-bold text-truncate">
            {{ sentMessage.formContent.name }}
        </div>
        <div class="col fw-bold text-truncate">
            {{ sentMessage.formContent.phone }}
        </div>
        <div class="col fw-bold text-truncate">
            {{ sentMessage.formContent.email }}
        </div>
        <div class="col fw-bold text-truncate">
            {{ sentMessage.formContent.moveInMonth }}
        </div>
        <div class="col fw-bold text-truncate">
            {{ sentMessage.formContent.budget }}
        </div>
        <div class="col fw-bold text-truncate">
            {{ sentMessage.formContent.lengthOfStay }}
        </div>
        <div class="col fw-bold text-truncate">
            {{ sentMessage.formContent.message }}
        </div>
    </div>
</div>
