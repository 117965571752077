import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/models/user/user';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  @Input() user: User = null as any;

  constructor() { }

  ngOnInit(): void {
  }

}
