<app-tokens-accordion
    [categories]="accordion"
    title="Neighborhoods Management"
    categoryName="neighborhood"
    tokenName="keyword"
    [editableToken]="false"
    [loading]="loading"
    (onCategoryAdd)="onCategoryAdd()"
    (onCategoryEdit)="onCategoryEdit($event)"
    (onCategoryDelete)="onCategoryDelete($event)"
    (onTokenAdd)="onTokenAdd($event)"
    (onTokenDelete)="onTokenDelete($event)"
></app-tokens-accordion>