import { Component, OnInit } from '@angular/core'
import { Page } from 'src/app/models/general/page'
import { FeedbackService } from '../../services/feedback.service'
import { AnswersSchema, UserStatus } from '../../services/feedback.type'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-feedback-answers',
  templateUrl: './feedback-answers.component.html',
  styleUrls: ['./feedback-answers.component.css'],
})
export class FeedbackAnswersComponent implements OnInit {
  userStatus: string[] = [UserStatus.BOTH, UserStatus.HOST, UserStatus.TENANT]

  allAnswers: AnswersSchema[] = []
  filteredAnswers: AnswersSchema[] = []
  selectedUserStatus: string = UserStatus.BOTH
  querySearch: string = ''

  // for pagination
  pageSize = 25;
  page: number = 1;
  pageStart: number = 0;
  pageEnd: number = this.pageSize - 1;

  constructor(
    private feedbackService: FeedbackService,
    private datePipe: DatePipe
  ) {}

  async ngOnInit(): Promise<void> {
    this.allAnswers = await this.feedbackService.getAnswers()
    this.filteredAnswers = this.allAnswers
    this.initPaginator();
  }

  filterAnswer(userStatus: string): void {
    this.selectedUserStatus = userStatus
    this.filteredAnswers = this.allAnswers
    this.initPaginator();

    if (this.selectedUserStatus === UserStatus.BOTH) {
      if (this.querySearch) {
        this.filteredAnswers = this.filteredAnswers.filter(
          (answer) =>
            (answer.userId.email as string)
              .toLowerCase()
              .includes(this.querySearch.toLowerCase()) ||
            (answer.apartmentId.name as string)
              .toLowerCase()
              .includes(this.querySearch.toLowerCase()),
        )
      }
    } else {
      if (this.querySearch) {
        this.filteredAnswers = this.filteredAnswers.filter(
          (answer) =>
            ((answer.userId.email as string)
              .toLowerCase()
              .includes(this.querySearch.toLowerCase()) ||
              (answer.apartmentId.name as string)
                .toLowerCase()
                .includes(this.querySearch.toLowerCase())) &&
            answer.userStatus === this.selectedUserStatus,
        )
      } else {
        this.filteredAnswers = this.filteredAnswers.filter(
          (answer) => answer.userStatus === this.selectedUserStatus,
        )
      }
    }
  }
  /////////////////////////////////
  //Paginator Section Starts Here//
  /////////////////////////////////
  onPageChange(pageEvent: Page) {
    this.page = pageEvent.index;
    this.pageStart = (this.page - 1) * pageEvent.size;
    this.pageEnd = this.pageStart + pageEvent.size - 1;
    if (this.pageEnd >= this.filteredAnswers.length - 1) {
      this.pageEnd = this.filteredAnswers.length - 1
    }
  }

  initPaginator() {
    if (this.filteredAnswers.length > this.pageSize) {
      this.page = 1;
      this.pageStart = 0;
      this.pageEnd = this.pageStart + this.pageSize - 1;
    }
  }

  formatDate(date: string | Date): string {
    return this.datePipe.transform(date, 'yyyy/MM/dd HH:mm') ?? '';
  }
}
