
<div class="modal-header">
    <ng-icon [name]="icon" size="28" class="me-2"></ng-icon>
    <h4 class="modal-title" id="modal-title">{{title}}</h4>
    <button
        type="button"
        class="btn-close"
        (click)="modal.dismiss()"
    ></button>
</div>
<div class="modal-body">
    {{text}}
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">{{dismissText}}</button>
    <button class="btn btn-dark" type="button" (click)="modal.close()">{{confirmText}}</button>
</div>
