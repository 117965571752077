<div class="card">
    <div class="d-flex flex-row justify-content-between card-header">
        <div class="d-flex flex-column justify-content-center ">
            <div>{{worker?.name}}</div>
        </div>
        <div class="d-flex gap-1">
            <button type="button" (click)="start()" class="btn btn-dark px-1 py-0"><i class="fs-7 bi bi-play-fill"></i></button>
            <button type="button" (click)="stop()" class="btn btn-dark px-1 py-0"><i class="fs-7 bi bi-stop-fill"></i></button>
            <button type="button" class="btn btn-dark px-1 py-0"><i class="fs-7 bi bi-gear-fill"></i></button>
        </div>
      </div>
  
  <div class="position-relative card-body d-flex flex-column">
    
    <h5 class="card-title mb-3">{{worker?.state?.state_message}}</h5>
    
    <!-- Current task -->
    <div class="d-flex flex-column align-items-center">
      <button
        type="button"
        class="btn btn-dark w-100"
        (click)="tasksCollapsed = !tasksCollapsed"
        [attr.aria-expanded]="!tasksCollapsed"
        aria-controls="collapseExample"
      >
      {{getRunningLabel()}}
      </button>
    </div>

    <!-- Tasks List -->
    <ul class="list-group mt-2" [(ngbCollapse)]="tasksCollapsed">
      <a
        *ngFor="let task of worker?.tasks; let i = index"
        class="list-group-item list-group-item-action flex-column align-items- mw-100"
      >
        <div class="d-flex w-100 justify-content-between">
            <h6 class="mb-2">{{task.name}}</h6>
            <small>{{this.getTaskStatus(i)}}</small>
        </div>
          <ngb-progressbar class="w-100" type="success" [value]="getTaskProgress(i)"></ngb-progressbar>
      </a>
    </ul>
  </div>
</div>
