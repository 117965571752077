import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { SettingsService } from '../services/settings.service'
import { FeedbackSettings } from '../services/settings.types'

@Component({
  selector: 'app-feedback-settings',
  templateUrl: './feedback-settings.component.html',
  styleUrls: ['./feedback-settings.component.css'],
})
export class FeedbackSettingsComponent implements OnInit {
  feedbackSettings = {} as FeedbackSettings
  loading: boolean = false

  public formGroup = new FormGroup({
    timeFeedbackInputTenant: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    timeFeedbackInputHost: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
    sendInterval: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
    ]),
  })

  constructor(private feedbackSettingsService: SettingsService) {}

  async ngOnInit() {
    this.feedbackSettings = await this.feedbackSettingsService.getSettings()

    this.formGroup.controls.timeFeedbackInputTenant.setValue(
      this.feedbackSettings.percentBeforeFeedbackTenant.toString(),
    )
    this.formGroup.controls.timeFeedbackInputHost.setValue(
      this.feedbackSettings.hostFeedbackAfterStay.toString(),
    )
    this.formGroup.controls.sendInterval.setValue(
      this.feedbackSettings.sendInterval.toString(),
    )
  }

  async save(): Promise<void> {
    this.loading = true
    this.feedbackSettings.percentBeforeFeedbackTenant = Number(
      this.formGroup.controls.timeFeedbackInputTenant.value,
    )
    this.feedbackSettings.hostFeedbackAfterStay = Number(
      this.formGroup.controls.timeFeedbackInputHost.value,
    )
    this.feedbackSettings.sendInterval = Number(
      this.formGroup.controls.sendInterval.value,
    )

    try {
      this.feedbackSettings = await this.feedbackSettingsService.saveSettings(
        this.feedbackSettings,
      )
    } catch {}
    this.loading = false
  }
}
