import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Page } from 'src/app/models/general/page';

const FILTER_PAG_REGEX = /[^0-9]/g;

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {
  @Input() length: number = 0;
  @Input() pageSize: number = 24;
  @Input() page: number = 1;
  @Input() rotate: boolean = false;
  @Input() ellipses: boolean = false;
  @Input() maxSize: number = 0;
  // @Output() change = new EventEmitter<PageEvent>;
  @Output() change = new EventEmitter<Page>();
  

  constructor() { }

  ngOnInit(): void {
    
  }

  pageChanged(pageNumber: number) {
    this.change.emit({
      'index': pageNumber,
      'size': this.pageSize
    } as Page);
  }

  selectPage(page: string) {
		this.page = parseInt(page, 10) || 1;
    // this.pageChanged(this.page);
	}

	formatInput(input: HTMLInputElement) {
		input.value = input.value.replace(FILTER_PAG_REGEX, '');
	}

}
