import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FeedbackQuestionsComponent } from './feedback-questions/feedback-questions/feedback-questions.component'
import { FeedbackAnswersComponent } from './feedback-answers/feedback-answers/feedback-answers.component'
import { FeedbackComponent } from './feedback/feedback/feedback.component'
import { NgIconsModule } from '@ng-icons/core'
import {
  bootstrapClipboard2DataFill,
  bootstrapGearFill,
  bootstrapArrowLeftCircleFill,
  bootstrapArrowRightCircleFill,
  bootstrapTagsFill,
  bootstrapPlusCircleFill,
  bootstrapPlusLg,
  bootstrapPlus,
  bootstrapTrashFill,
  bootstrapPencilFill,
  bootstrapBellFill
} from '@ng-icons/bootstrap-icons'
import { ionDocumentText, ionStatsChart, ionSend } from '@ng-icons/ionicons'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { AppRoutingModule } from 'src/app/app-routing.module'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { FeedbackAddModalComponent } from './feedback-questions/form-add-modal/form-add-modal.component'
import { FeedbackSettingsComponent } from './feedback-settings/feedback-settings.component';
import { QuestionAddModalComponent } from './feedback-questions/questions-add-modal/question-add-modal/question-add-modal.component'
import { PaginatorModule } from '../general/paginator/paginator.module';
import { FeedbackPendingComponent } from './feedback-pending/feedback-pending.component';
import { SharedModule } from '../shared/shared.module'

@NgModule({
  declarations: [
    FeedbackQuestionsComponent,
    FeedbackAnswersComponent,
    FeedbackSettingsComponent,
    FeedbackComponent,
    FeedbackAddModalComponent,
    QuestionAddModalComponent,
    FeedbackPendingComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AppRoutingModule,
    SharedModule,
    NgIconsModule.withIcons({
      bootstrapClipboard2DataFill,
      bootstrapGearFill,
      bootstrapArrowLeftCircleFill,
      bootstrapArrowRightCircleFill,
      bootstrapTagsFill,
      bootstrapPlusCircleFill,
      bootstrapTrashFill,
      bootstrapPlusLg,
      bootstrapPlus,
      bootstrapPencilFill,
      bootstrapBellFill,
      ionStatsChart,
      ionDocumentText,
      ionSend
    }),
    PaginatorModule
  ],
  exports: [FeedbackQuestionsComponent, FeedbackComponent, FeedbackSettingsComponent],
})
export class FeedbackModule {}
