import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { SocketService } from './socket.service';
import { ToastService } from './toast/toast-service';
import { LoginService } from './login.service';
import { Worker, WorkerParamUpdate, WorkerState } from './workers.types'

@Injectable({
  providedIn: 'root'
})
export class WorkersService {

  private workers: {[id: string]: BehaviorSubject<Worker | undefined>} = {};

  constructor(
    private api: ApiService,
    private toastService: ToastService,
    private socketService: SocketService,
    private loginService: LoginService,
    private router: Router,
    ) {

    this.updateWorkers();

    this.socketService.onUpdate().subscribe(
      (state) => {
        this.updateWorkerState(state);
      }
    );

  }

  public updateWorkers(): void {
    this.api.get<Worker[]>('/workers').subscribe(
      {
        next: (workers) => {
          for (let worker of workers) {
            this.getWorker(worker.id).next(worker);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.loginService.setLoggedIn(false);
          this.router.navigate(['/login']);
          console.log(error);
        }
      }
    )
  }

  getWorker(id: string): BehaviorSubject<Worker | undefined> {
    if (this.workers[id] === undefined)
      this.workers[id] = new BehaviorSubject<Worker | undefined>(undefined);
    return this.workers[id];
  }

  private updateWorkerState(state: WorkerState) {
    const workerSub = this.getWorker(state.id);
    const worker = workerSub.value;
    if (!worker) return;
    worker.state = state;
    workerSub.next(worker);
  }

  start(id: string): Observable<string> {
    const subject = new Subject<string>;
    const worker = this.workers[id];
    if (!worker || !worker.value) {
      subject.error('Unknown worker.');
      this.toastService.show('Unknown worker.', { classname: 'bg-danger text-light', delay: 5000 });
      return subject;
    }
    localStorage.removeItem("models")
    return this.api.do('/workers/' + worker.value.id + '/start');
  }

  stop(id: string): Observable<string> {
    const subject = new Subject<string>;
    const worker = this.workers[id];
    if (!worker || !worker.value) {
      subject.error('Unknown worker.');
      this.toastService.show('Unknown worker.', { classname: 'bg-danger text-light', delay: 5000 });
      return subject;
    }
    return this.api.do('/workers/' + worker.value.id + '/stop');
  }

  updateParams(id: string, updatedParams: WorkerParamUpdate[]): Observable<string> {
    const subject = new Subject<string>;
    const worker = this.workers[id];
    if (!worker || !worker.value) {
      subject.error('Unknown worker.');
      this.toastService.show('Unknown worker.', { classname: 'bg-danger text-light', delay: 5000 });
      return subject;
    }
    return this.api.patch('/workers/' + worker.value.id + '/params', updatedParams);
  }
}
