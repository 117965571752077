import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/services/layout.service';
import { DateUtil } from 'src/app/utils/dateUtil';
import { DatePipe } from '@angular/common';
import { SentMessagesService } from 'src/app/services/sent-messages.service';
import { LoginService } from 'src/app/services/login.service';
import { MessageModalComponent } from '../general/modal/message-modal/message-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sent-messages',
  templateUrl: './sent-messages.component.html',
  styleUrls: ['./sent-messages.component.css']
})
export class SentMessagesComponent implements OnInit {
  sentMessages: any[] = [];

  constructor(
    public layoutService: LayoutService,
    public dateUtil: DateUtil,
    private datePipe: DatePipe,
    private loginService: LoginService,
    private sentMessagesService: SentMessagesService,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getSentMessages();
  }

  getSentMessages()  {
    this.sentMessagesService.getAllSentMessages()
      .subscribe(resp => {
        this.loginService.setLoggedIn(true);
        this.sentMessages = resp;
      }, error => {
        this.loginService.setLoggedIn(false);
        this.router.navigate(['/login']);
      })
  }

  showDetails(sentMessage: any) {
    const msg =
      `Message sent by user ${sentMessage.senderId} on ${this.datePipe.transform(this.dateUtil.createDate(sentMessage.timestamp))} for apartment "${sentMessage.apartmentId.name}" (id: ${sentMessage.apartmentId._id}): \n` +
      `Name: ${sentMessage.formContent.name}\n` +
      `Phone: ${sentMessage.formContent.phone}\n` +
      `Email: ${sentMessage.formContent.email}\n` +
      `Move in month: ${sentMessage.formContent.moveInMonth}\n` +
      `Budget: ${sentMessage.formContent.budget}\n` +
      `Length of Stay: ${sentMessage.formContent.lengthOfStay}\n` +
      `Message: ${sentMessage.formContent.message}\n`;

    const msgModal = this.modalService.open(MessageModalComponent);
    msgModal.componentInstance.title = "Sent Message Details";
    msgModal.componentInstance.message = msg;
  }
}
