<ngb-carousel *ngIf="photos" [interval]="0">
  <ng-container *ngFor="let photo of photos; let i = index">
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper">
        <div class="card">
          <img 
          src="{{getImgSrc(photo.source)}}"
          alt="Random first slide" 
          class="d-block w-100 card-img-top"
        />
          <div class="card-body">

            <p class="text-center" *ngIf="photo.tag.length > 0">
              <span class="badge text-bg-secondary rounded-pill">
                {{ photo.tag }}
              </span>
            </p>
            <p class="cardtext">
              {{ photo.description }}
            </p>
            
          </div>
          <div class="card-footer text-center">
            <small class="text-muted">{{i+1}}{{"/ "}}{{photos.length}}</small>
          </div> 
        </div>
        
      </div>
    </ng-template>
  </ng-container>
</ngb-carousel>