import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from '../utils/utils';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SentMessagesService {

  constructor(
    private utils: Utils,
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getSentMessagesByUserId(id: string) {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = `${this.utils.getAPIUrl()}admin/user/${id}/sentmessages`;
    return this.http.get<[]>(url, { headers });
  }

  getAllSentMessages() {
    const headers = new HttpHeaders().set(
      'authorization',
      this.authService.getToken()
    );
    let url = `${this.utils.getAPIUrl()}admin/sentmessages`;
    return this.http.get<[]>(url, { headers });
  }
}
