

    <div *ngIf="bio && neighborhoods.length > 0 && keywordCategories.length > 0" class="row justify-content-center m-3">
        <div class="col col-12 card p-0">
            <div class="card-header">
                Input
            </div>
            <div class="position-relative card-body d-flex flex-column gap-2">

                <div class="d-flex flex-column px-3 gap-2" style="background-color: #ebebeb; padding: 20px; border-radius: 37px;">
                    <div class="d-flex justify-content-between">
                        <div class="flex-grow-1" style="height: 30px; line-height: 40px; vertical-align: middle;">{{bio.id}}</div>
                        <button type="button" (click)="generate()" class="btn btn-light p-0" style="height: 30px; width: 40px"><i class="bi bi-repeat px-2"></i></button>
                    </div>
                    <div>{{bio.bio}}</div>
                    <div class="d-flex flex-row justify-content-center align-items-center flex-wrap gap-2">
                        <div *ngFor="let keyword of bio?.keywords" class="align-self-center">
                            <span
                                class="badge badge-dark"
                                style="border-radius: 50px; font-size: 13px; background-color: #707070 !important;"
                                >{{keyword}}</span
                            >
                        </div>
                    </div>
                    <div class="text-center">{{matchNumberText}}</div>
                </div>

                <div
                    class="bios-container overflow-auto mt-2 pe-1"
                    style="max-height: 400px"
                >
                
                <ul class="list-group">
                    <!-- Pipeline: Tasks List -->
                    <ng-container *ngFor="let neighborhood of neighborhoods; let i = index">
                    <a
                        
                        class="list-group-item list-group-item-action d-flex flex-row gap-2 align-items-center mw-100 fw-normal bg-light py-0 ps-0 position-relative"
                        style="font-size: small;"
                        [class.border-top-0]="i !== 0"
                        [class.border-bottom-0]="i !== neighborhoods.length-1 && i === expandedBio"
                        [class.rounded-bottom]="i === neighborhoods.length-1 && i !== expandedBio"
                        type="button"
                    >
                        <div type="button" class="flex-grow-1 ps-3"  (click)="toggleExpand(i)" style="font-family: monospace, monospace; height: 43px; line-height: 43px; vertical-align: center;">{{ neighborhood.name.en }}</div>
                        <span
                            *ngIf="response && i !== 0 && response.matches[i].match_level === 'good'"
                            class="badge badge-dark bg-dark top-50 start-50 translate-middle"
                            style="border-radius: 50px; font-size: 13px; background-color: #b9cb45 !important; position: absolute"
                            >Good Match!</span
                        >
                        <span
                            *ngIf="response && i !== 0 && response.matches[i].match_level === 'great'"
                            class="badge badge-dark bg-dark top-50 start-50 translate-middle"
                            style="border-radius: 50px; font-size: 13px; background-color: #48cd66 !important; position: absolute"
                            >Great Match!</span
                        >
                
                    </a>
                    <div [ngbCollapse]="i !== expandedBio">
                    <a
                        class="list-group-item bio-details d-flex flex-row gap-2 align-items-center mw-100 fw-normal"
                        [class.rounded-bottom]="i === neighborhoods.length"
                        style="font-size: small"
                    >
                    <div class="d-flex flex-column flex-grow-1 gap-3">
                        <div class="d-flex flex-row justify-content-center align-items-center flex-wrap gap-2">
                            <ng-container *ngFor="let keyword of neighborhood.keywords">
                                <div *ngIf="getCategorizedKeyword(keyword) as catKeyword" class="align-self-center">
                                    <span
                                        class="badge badge-dark"
                                        [style.background-color]="isCommonKeyword(catKeyword.cleaned, i) ? '#ff7a59 !important' : '#707070 !important'"
                                        style="border-radius: 50px; font-size: 13px;"
                                        >{{ catKeyword.name.en }}</span
                                    >
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    
                    </a>
                    </div>
                    
            </ng-container>
                  </ul>
                  </div>
        </div>
        </div>
    </div>
    

