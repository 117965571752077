import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from '../utils/utils';
import { User } from '../models/user/user';
import { DetailedUser } from '../models/user/detailedUser';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private utils: Utils,
    private http: HttpClient,
    private authService: AuthService
  ) { }

  private _addQueryField(url: string, fieldName: string, field: string) {
    if (field.length > 0)
      return `${url}&${fieldName}=${field}`
    return `${url}&${fieldName}`;
  }

  getUserInfo(id: string) {
    const headers =
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    let url = `${this.utils.getAPIUrl()}admin/users/${id}`;
    return this.http.get<DetailedUser>(url, {headers});
  }

  getAllUsers(
    status: string,
    search: string,
    skip: number,
    limit: number
  ) {
    const headers =
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    let url = `${this.utils.getAPIUrl()}admin/users?`;
    if (status.length > 0)
      url = this._addQueryField(url, "status", status)
    if (search.length > 0)
      url = this._addQueryField(url, "search", search)
    url = this._addQueryField(url, "skip", skip.toString());
    url = this._addQueryField(url, "limit", limit.toString());
    return this.http.get<Array<User>>(url, {headers});
  }

  calculateAge(birthDate: Date) {
    let today = new Date();
    var age = today.getFullYear() - new Date(birthDate).getFullYear();
    var m = today.getMonth() - new Date(birthDate).getMonth();
    return age;
  }



}
