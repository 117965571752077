import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MatchResponse, Prediction, BioRetrieveMethod } from './matching.types';
import { ToastService } from './toast/toast-service';
import { HistoryResponse, MatchingHistory } from '../models/matching/matchingHistory';


@Injectable({
  providedIn: 'root'
})
export class MatchingService {

  clusters: number[] = [];

  constructor(
    private toastService: ToastService,
    private apiService: ApiService
  ) { }

  async match(primarySubect: BioRetrieveMethod, secondarySubjects: BioRetrieveMethod[]): Promise<MatchResponse> {
    return new Promise((resolve, reject) => {
      const matchRequest = {primary_subject: primarySubect, secondary_subjects: secondarySubjects};
      this.apiService.post<MatchResponse>('/models/match', matchRequest).subscribe({
        next: (response) => {resolve(response);},
        error: (error: HttpErrorResponse) => {reject(error);}
      })
    })
  }

  async getMatchHistory(page: number, filter: string): Promise<HistoryResponse> {
    return new Promise((resolve, reject) => {
      let url = `/match-history?p=${page}`;
      if (filter) url += `&f=${filter}`;
      this.apiService.get<HistoryResponse>(url).subscribe({
        next: (response) => {resolve(response);},
        error: (error: HttpErrorResponse) => {reject(error);}
      })
    })
  }

}
