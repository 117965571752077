import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListingsComponent } from './components/listings/listings.component';
import { UsersComponent } from './components/users/users.component';
import { ReservationsComponent } from './components/reservations/reservations.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { BookingRequestsComponent } from './components/booking-requests/booking-requests.component';
import { LoginComponent } from './components/login/login.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { MatchingComponent } from './components/matching/matching/matching.component';
import { MatchingDashboardComponent } from './components/matching/matching-dashboard/matching-dashboard.component';
import { UserToUsersComponent } from './components/matching/matching-testing/user-to-users/user-to-users.component';
import { UserToNeighborhoodsComponent } from './components/matching/matching-testing/user-to-neighborhoods/user-to-neighborhoods.component';
import { MatchingKeywordsComponent } from './components/matching/matching-keywords/matching-keywords.component';
import { MatchingNeighborhoodsComponent } from './components/matching/matching-neighborhoods/matching-neighborhoods.component';
import { MatchingHistoryComponent } from './components/matching/matching-history/matching-history.component';
import { FeedbackComponent } from './components/feedback/feedback/feedback/feedback.component'
import { FeedbackQuestionsComponent } from './components/feedback/feedback-questions/feedback-questions/feedback-questions.component'
import { FeedbackAnswersComponent } from './components/feedback/feedback-answers/feedback-answers/feedback-answers.component'
import { MatchingSettingsComponent } from './components/matching/matching-settings/matching-settings.component'
import { FeedbackSettingsComponent } from './components/feedback/feedback-settings/feedback-settings.component';
import { FeedbackPendingComponent } from './components/feedback/feedback-pending/feedback-pending.component';
import { PayoutsComponent } from './components/payouts/payouts.component';
import { SentMessagesComponent } from './components/sent-messages/sent-messages.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: 'listing', pathMatch: 'full'},
  { path: 'listing', component: ListingsComponent},
  { path: 'user', component: UsersComponent},
  { path: 'reservation', component: ReservationsComponent},
  { path: 'booking-request', component: BookingRequestsComponent},
  { path: 'dashboard', component: DashboardComponent},
  { path: 'invoice', component: InvoicesComponent },
  { path: 'payout', component: PayoutsComponent },
  { path: 'sent-messages', component: SentMessagesComponent },
  {
    path: 'feedback',
    component: FeedbackComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'questions',
      },
      {
        path: 'questions',
        component: FeedbackQuestionsComponent,
      },
      {
        path: 'answers',
        component: FeedbackAnswersComponent,
      },
      {
        path: 'pending',
        component: FeedbackPendingComponent,
      },
      {
        path: 'settings',
        component: FeedbackSettingsComponent,
      },
    ],
  },
  {
    path: 'matching',
    component: MatchingComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        component: MatchingDashboardComponent,
      },
      {
        path: 'testing',
        redirectTo: 'testing/users'
      },
      {
        path: 'testing/users',
        component: UserToUsersComponent,
      },
      {
        path: 'testing/neighborhoods',
        component: UserToNeighborhoodsComponent,
      },
      {
        path: 'keywords',
        component: MatchingKeywordsComponent,
      },
      {
        path: 'settings',
        component: MatchingSettingsComponent,
      },
      {
        path: 'neighborhoods',
        component: MatchingNeighborhoodsComponent,
      },
      {
        path: 'history',
        component: MatchingHistoryComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }