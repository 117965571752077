import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookingRequestDetailComponent } from '../booking-request-detail/booking-request-detail.component';

const routes: Routes = [
  {path: 'booking-request/:id', component: BookingRequestDetailComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookingRequestsRoutingRoutingModule { }
