import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RentRequestsService } from 'src/app/services/rent-requests.service';
import { DateUtil } from 'src/app/utils/dateUtil';

@Component({
  selector: 'app-upcoming-checkin',
  templateUrl: './upcoming-checkin.component.html',
  styleUrls: ['./upcoming-checkin.component.css']
})
export class UpcomingCheckinComponent implements OnInit {
  // set default to 24hrs
  secondsBefore: number = 3600 * 24;
  reservations: any = [];

  constructor(
    private rentRequestsService: RentRequestsService,
    public dateUtil: DateUtil,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.rentRequestsService.getUpcomingReservation(this.secondsBefore).subscribe(resp => {
      this.reservations = (resp as any).reservations;
    }, err => {
      this.router.navigate(['/login']);
    })
  }

  getNumberOfLocations(): number {
    return this.reservations.length;
  }

}
