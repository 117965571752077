<div class="d-flex flex-column gap-3" style="min-height: 100%;">

    <!-- Component Header -->
    <div class="d-flex flex-row justify-content-between">
        <h3>{{title}}</h3>
        <div>
            <button type="button" class="btn btn-dark" (click)="onCategoryAdd.emit()">New {{categoryName}}<ng-icon
                    name="ionAddCircle" class="aligned ms-2" size="22"></ng-icon></button>
        </div>
    </div>

    <!-- Accordion -->
    <ngb-accordion *ngIf="categories && categories.length > 0" class="mb-4" [closeOthers]="true">
        <ngb-panel *ngFor="let category of categories" [id]="category.id">

            <!-- Category Title -->
            <ng-template ngbPanelTitle>
                <div class="w-100 pe-3 d-flex justify-content-between">
                    <!-- Category Name -->
                    <span>{{category.name}}</span>
                    <div class="d-flex gap-2">
                        <!-- Additional info -->
                        <div class="bg-light text-dark align-middle text-center rounded" style="line-height: 26px; min-width: 26px;">{{category.additionalInfo ? category.additionalInfo : ''}}</div>
                        <!-- Edit Button -->
                        <button type="button" (click)="editCategory(category, $event)"
                            class="btn btn-primary-outline px-1 py-0"><ng-icon name="bootstrapPencilFill"
                                class="aligned" size="16"></ng-icon></button>
                        <!-- Delete Button -->
                        <button type="button" (click)="deleteCategory(category, $event)"
                            class="btn btn-primary-outline px-1 py-0"><ng-icon name="bootstrapTrashFill" class="aligned"
                                size="16"></ng-icon></button>
                    </div>
                </div>
            </ng-template>

            <!-- Category Content (Tokens) -->
            <ng-template ngbPanelContent>
                <div class="d-flex flex-column gap-2">
                    <!-- Header (actions) -->
                    <div class="d-flex flex-row-reverse justify-content-between align-items-center">
                        <button type="button" style="font-size: 14px;" class="btn btn-dark"
                            (click)="onTokenAdd.emit({category})">Add a {{tokenName}}<ng-icon name="ionAddCircle"
                                class="aligned ms-2" size="18"></ng-icon></button>
                        <div *ngIf="category.toggles && category.toggles.length > 0" class="d-flex flex-column">
                            <div *ngFor="let toggle of category.toggles" class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" [checked]="toggle.value" (change)="onToggleChange(category, toggle.id, $event)">
                                <label class="form-check-label">{{toggle.name}}</label>
                          </div>
                        </div>
                    </div>
                    <!-- Tokens Cloud -->
                    <div class="d-flex justify-content-center flex-wrap gap-2">
                        <div *ngFor="let token of category.tokens" class="align-self-center">
                            <div class="badge bg-primary text-dark fw-bold d-flex gap-2 py-2 ps-4 pe-3"
                                style="border-radius: 50px; font-size: 14px">
                                <div class="align-middle" style="line-height: 26px">{{ token.name }}</div>
                                <div>
                                    <button *ngIf="editableToken" type="button" (click)="onTokenEdit.emit({category, token})"
                                        class="btn btn-primary text-dark px-1 py-0"><ng-icon name="bootstrapPencilFill"
                                            class="aligned" size="16"></ng-icon></button>
                                    <button type="button" (click)="deleteToken(category, token)"
                                        class="btn btn-primary text-dark px-1 py-0"><ng-icon name="bootstrapTrashFill"
                                            class="aligned" size="16"></ng-icon></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>

    <!-- Empty -->
    <div *ngIf="!loading && (!categories || categories.length === 0)" class="flex-grow-1 d-flex flex-column justify-content-center">
        <h4 class="text-center" >No {{categoryName}}, add one!</h4>
    </div>
</div>