<div class="container px-4 py-4">
    <div class="row">
        <div class="col-10">
            <h4>Upcoming Checkins</h4>
            <p class="fw-bold fs-14 text-secondary">
                In {{ getNumberOfLocations() }} different locations.
            </p>
            <h5></h5>
        </div>
    </div>
    
    
    
    <div class="row py-3 border-bottom" *ngFor="let reservation of reservations">
        <div class="col-8 fw-bold fs-14">
            {{ reservation.userId.first_name + " " + reservation.userId.last_name}}
        </div>
        <div class="col-4 text-secondary fw-bold fs-14">
            Starting from {{ dateUtil.createDate(reservation.moveInDate) | date: 'mediumDate'}}
        </div>
    </div>
</div>