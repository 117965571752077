<div class="row text-start rounded-0 py-2 border-top fs-14">
    <div class="col text-truncate fs-14" id="apartment_name">
        {{ reservation.apartment.name }}
    </div>
    <div class="col fs-14"  id="reservation_duration">
        <span class="badge rounded-pill text-bg-secondary">
            {{ dateUtil.createDate(reservation.moveInDate) | date: 'mediumDate'}} - {{ dateUtil.createDate(reservation.moveOutDate) | date: 'mediumDate'}}
        </span>
        
    </div>
    <div class="col fs-14" id="request_date">
        {{ dateUtil.createDate(reservation.createdAt) | date: 'mediumDate'}}
    </div>
    <div class="col fs-14" id="user_name">
        <a [routerLink]="['/user', reservation.userId._id]" class="text-decoration-none text-decoration-underline" *ngIf="reservation.userId">
            {{ reservation.userId.first_name + " " + reservation.userId.last_name }}
        </a>
        <span class="text-secondary" *ngIf="!reservation.userId">
            Null
        </span>
    </div>
    <div class="col fs-14" id="price">
        {{ calculateTotalPaid() | currency: 'CAD' : 'symbol-narrow': '1.0-2'}}
    </div>
    <div class="col fs-14" id="request_status">
        <span class="badge text-bg-success rounded-pill" *ngIf="isUpComing() && !isCanceledByAdmin()">
            Upcoming
        </span>
        <span class="badge text-bg-warning rounded-pill" *ngIf="isActive() && !isCanceledByAdmin()">
            Active
        </span>
        <span class="badge text-bg-primary rounded-pill" *ngIf="isCompleted() && !isCanceledByAdmin()">
            Completed
        </span>
        <span class="badge text-bg-danger rounded-pill" *ngIf="isCanceledByAdmin() && !isCanceledByAdmin()">
            Canceled by Admin
        </span>
    </div>
</div>