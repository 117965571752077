import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Utils } from "../utils/utils";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class StripeService {
    constructor(
        private utils: Utils,
        private http: HttpClient,
        private cookie: CookieService,
        private authService: AuthService
    ) {

    }

    getUnpaidInvoices(option: any = {}) {
        const headers = 
          new HttpHeaders().set(
            'authorization', this.authService.getToken()
        );
        let url = `${this.utils.getAPIUrl()}admin/invoices/open?`
        for (const property in option) {
            url = `${url}${property}=${option[property]}&`;
        }
        return this.http.get(url, {headers})
    }

    resendInvoice(invoiceId: string) {
        const headers = 
            new HttpHeaders().set(
                'authorization', this.authService.getToken()
            ); 
        let url = `${this.utils.getAPIUrl()}admin/invoices/send`
        return this.http.post(
            url, 
            {
                invoice_id: invoiceId
            },
            {headers}
        )
    }

    receivePaymentFromHost(id: string, paymentInfo: any) {
        const headers = 
            new HttpHeaders().set(
                'authorization', this.authService.getToken()
            ); 
        let url = `${this.utils.getAPIUrl()}admin/payments/received-from-host`;
        return this.http.post(url, {
            rentRequestId: id,
            paymentInfo: paymentInfo
        }, {headers})
    }

    payToHost(id: string, paymentInfo: any) {
        const headers = 
            new HttpHeaders().set(
                'authorization', this.authService.getToken()
            ); 
        let url = `${this.utils.getAPIUrl()}admin/payments/paid-to-host`;
        return this.http.post(url, {
            rentRequestId: id,
            paymentInfo: paymentInfo
        }, {headers})
    }
}