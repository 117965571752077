import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Category, CategoryEvent, TokenEvent, Token, CustomToggle, ToggleEvent } from './tokens-accordion.types';

@Component({
  selector: 'app-tokens-accordion',
  templateUrl: './tokens-accordion.component.html',
  styleUrls: ['./tokens-accordion.component.css']
})
export class TokensAccordionComponent implements OnInit {

  @Input() title: string = 'Tokens Management';
  @Input() categories: Category[] = [];
  @Input() categoryName: string = 'category';
  @Input() tokenName: string = 'token';
  @Input() editableToken: boolean = true;
  @Input() loading: boolean = false;
  @Output() onCategoryAdd = new EventEmitter<void>();
  @Output() onCategoryEdit = new EventEmitter<CategoryEvent>();
  @Output() onCategoryDelete = new EventEmitter<CategoryEvent>();
  @Output() onTokenAdd = new EventEmitter<CategoryEvent>();
  @Output() onTokenEdit = new EventEmitter<TokenEvent>();
  @Output() onTokenDelete = new EventEmitter<TokenEvent>();
  @Output() onToggle = new EventEmitter<ToggleEvent>();

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  editCategory(category: Category, event: Event) { 
    event.stopPropagation();
    this.onCategoryEdit.emit({category});
  }

  deleteCategory(category: Category, event: Event) { 
    event.stopPropagation();
    const modalRef = this.modalService.open(ConfirmModalComponent, { centered: true, size: 'md' });
    modalRef.componentInstance.title = 'Warning';
    modalRef.componentInstance.text = `Are you sure you want to delete this ${this.categoryName} (${category.name})?`;
    modalRef.closed.subscribe(() => {
      this.onCategoryDelete.emit({category});
    })
  }

  deleteToken(category: Category, token: Token) { 
    const modalRef = this.modalService.open(ConfirmModalComponent, { centered: true, size: 'md' });
    modalRef.componentInstance.title = 'Warning';
    modalRef.componentInstance.text = `Are you sure you want to delete this ${this.tokenName} (${token.name})?`;
    modalRef.closed.subscribe(() => {
      this.onTokenDelete.emit({category, token});
    })
  }

  onToggleChange(category: Category, toggleId: string, event: Event) {
    if (!event.target) return;
    const checked = (event.target as any).checked;
    this.onToggle.emit({category, toggleId, value: checked});
  }

}
