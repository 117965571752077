import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { KeywordsService } from '../services/keywords.service';
import { KeywordCategory } from '../services/keywords.types';
import { NeighborhoodsService } from '../services/neighborhoods.service';
import { Neighborhood } from '../services/neighborhoods.types';
import { Category, CategoryEvent, TokenEvent } from '../shared/tokens-accordion/tokens-accordion.types';
import { NeighborhoodEditModalComponent } from './neighborhood-edit-modal/neighborhood-edit-modal.component';
import { NeighborhoodKeywordAddModalComponent } from './neighborhood-keyword-add-modal/neighborhood-keyword-add-modal.component';

@Component({
  selector: 'app-matching-neighborhoods',
  templateUrl: './matching-neighborhoods.component.html',
  styleUrls: ['./matching-neighborhoods.component.css']
})
export class MatchingNeighborhoodsComponent implements OnInit {

  neighborhoods: Neighborhood[] = [];
  categories: KeywordCategory[] = [];
  accordion: Category[] = [];
  private _loading = false;

  constructor(
    private modalService: NgbModal,
    private neighborhoodsService: NeighborhoodsService,
    private keywordsService: KeywordsService,
    private spinner: NgxSpinnerService
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.categories = await this.keywordsService.getCategories();
    this.neighborhoods = await this.neighborhoodsService.getNeighborhoods();
    this.refreshAccordion();
    this.loading = false;
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    if (value) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
    this._loading = value;
  }

  onCategoryAdd() {
    const modalRef = this.modalService.open(NeighborhoodEditModalComponent, { centered: true, size: 'md', scrollable: true });
    modalRef.componentInstance.neighborhood = { name: { en: '', fr: '' } };
    modalRef.componentInstance.mode = 'create';
    modalRef.closed.subscribe((resp) => {this.handleResponse(resp)})
  }

  onCategoryEdit(event: CategoryEvent) {
    const neighborhood = this.getNeighborhood(event.category.id);
    if (!neighborhood) return;
    const modalRef = this.modalService.open(NeighborhoodEditModalComponent, { centered: true, size: 'md', scrollable: true });
    modalRef.componentInstance.neighborhood = neighborhood;
    modalRef.componentInstance.mode = 'edit';
    modalRef.closed.subscribe((resp) => {this.handleResponse(resp)})
  }

  async onCategoryDelete(event: CategoryEvent) {
    // Search in the categories
    let index = -1;
    for (let i = 0; i < this.neighborhoods.length; i++)
      if (this.neighborhoods[i]._id === event.category.id)
        index = i
    if (index === -1) return;

    // Delete everywhere
    await this.neighborhoodsService.deleteNeighborhood(event.category.id);
    this.neighborhoods.splice(index, 1);
    this.refreshAccordion();
  }

  onTokenAdd(event: CategoryEvent) {
    const modalRef = this.modalService.open(NeighborhoodKeywordAddModalComponent, { centered: true, size: 'md', scrollable: true });
    modalRef.componentInstance.neighborhoodId = event.category.id;
    const alreadyChosenKeywords = []
    for (const token of event.category.tokens) alreadyChosenKeywords.push(token.id)
    modalRef.componentInstance.alreadyChosenKeywords = alreadyChosenKeywords;
    modalRef.componentInstance.mode = 'add';
    modalRef.componentInstance.categories = this.categories;
    modalRef.closed.subscribe((resp) => {this.handleResponse(resp)})
  }

  async onTokenDelete(event: TokenEvent) {
    const resp = await this.neighborhoodsService.deleteKeyword(event.category.id, event.token.id);
    this.handleResponse(resp);
  }


  handleResponse(response: Neighborhood) {
    // Find category and update/create it
    let neighborhood = this.getNeighborhood(response._id);
    if (!neighborhood) {
      neighborhood = response;
      this.neighborhoods.push(neighborhood);
    } else {
      neighborhood.keywords = response.keywords;
      neighborhood.name = response.name;
      neighborhood.google_api_name = response.google_api_name;
      neighborhood.count = response.count;
    }

    // Update the accordion
    this.updateAccordionNeighborhood(neighborhood)
  }


  private refreshAccordion() {
    this.accordion = [];
    for (const neighborhood of this.neighborhoods)
      this.updateAccordionNeighborhood(neighborhood);
  }


  private updateAccordionNeighborhood(neighborhood: Neighborhood) {
    let category: Category | undefined;

    // Search the category
    for (const tmpCategory of this.accordion)
      if (tmpCategory.id === neighborhood._id)
        category = tmpCategory;
    // Create the category if needed
    if (!category) {
      category = {
        id: neighborhood._id,
        name: neighborhood.name.en,
        additionalInfo: '',
        tokens: []
      }
      this.accordion.push(category);
    }
    // Update the category
    category.name = neighborhood.name.en
    category.tokens = [];
    category.additionalInfo = (!neighborhood.count || neighborhood.count <= 0) ? '0' : neighborhood.count.toString();
    for (const keyword of neighborhood.keywords) {
      let keywordName = this.getKeywordName(keyword);
      if (!keywordName) keywordName = '?'
      category.tokens.push({
        id: keyword,
        name: keywordName,
      })
    }
  }

  getKeywordName(keywordId: string): string | undefined
  {
    for (const category of this.categories) {
      for (const keyword of category.keywords) {
        if (keyword._id === keywordId) {
          return keyword.name.en;
        }
      }
    }
    return undefined;
  }

  getNeighborhood(neighborhoodId: string): Neighborhood | undefined {
    for (const neighborhood of this.neighborhoods)
      if (neighborhood._id === neighborhoodId)
        return neighborhood;
    return undefined;
  }
}
