import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { KeywordCategory } from '../../services/keywords.types';
import { NeighborhoodsService } from '../../services/neighborhoods.service';

@Component({
  selector: 'app-neighborhood-keyword-add-modal',
  templateUrl: './neighborhood-keyword-add-modal.component.html',
  styleUrls: ['./neighborhood-keyword-add-modal.component.css']
})
export class NeighborhoodKeywordAddModalComponent implements OnInit {

@ViewChild('form', { read: NgForm }) form: any;

  private neighborhoodId: string | undefined;
  public alreadyChosenKeywords: string[] = [];
  public categories: KeywordCategory[] = [];
  public selectedKeywordId: string | undefined;

  constructor(
    public modal: NgbActiveModal,
    private neighborhoodsService: NeighborhoodsService
    ) { }

  ngOnInit(): void {
    
    if (!this.neighborhoodId) {
      this.modal.dismiss();
      return;
    }
  }


  async submit() {
    if (!this.neighborhoodId || !this.selectedKeywordId) return;
    try {
      this.modal.close(await this.neighborhoodsService.addKeyword(this.neighborhoodId, this.selectedKeywordId));
    } catch (e) {
      console.error(e);
      return;
    }
  }

  onKeywordClick(keywordId: string) {
    if (this.selectedKeywordId === keywordId) this.selectedKeywordId = undefined;
    else this.selectedKeywordId = keywordId;
  }

}
