import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Model } from './ai-results.types';

@Component({
  selector: 'app-ai-results',
  templateUrl: './ai-results.component.html',
  styleUrls: ['./ai-results.component.scss']
})
export class AiResultsComponent implements OnInit {

  public worker: Worker | undefined;
  public shownedModel: Model | undefined;
  public selectedModelId = 'model-001'

  constructor(
    public modal: NgbActiveModal
    ) {}

  ngOnInit(): void {}

  showResults(model: Model) {
    this.shownedModel = model;
  }

  hideResults() {
    this.shownedModel = undefined;
  }

  setSelectedModel(model: Model) {
    this.selectedModelId = model.results.id;
    this.hideResults();
  }
  
}
