import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from 'src/app/utils/utils';
import { Keyword, KeywordCategory } from './keywords.types';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class KeywordsService {

  constructor(
    private util: Utils,
    private http: HttpClient,
    private authService: AuthService,
  ) { }


    async getCategories(): Promise<KeywordCategory[]> {
      const headers =
        new HttpHeaders().set(
          'authorization', this.authService.getToken()
        );
      return new Promise<KeywordCategory[]>((resolve, reject) => {
        const url = `${this.util.getAPIUrl()}admin/keywords`;
        this.http.get<KeywordCategory[]>(url, { headers }).subscribe({
          next: resolve,
          error: reject
        });
      });
    }


    async createCategory(category: KeywordCategory): Promise<KeywordCategory> {
      const headers =
        new HttpHeaders().set(
          'authorization', this.authService.getToken()
        );
      return new Promise<KeywordCategory>((resolve, reject) => {
        const url = `${this.util.getAPIUrl()}admin/keywords`;
        this.http.post<KeywordCategory>(url, category, { headers }).subscribe({
          next: resolve,
          error: reject
        });
      });
    }


    async modifyCategory(categoryId: string, category: KeywordCategory): Promise<KeywordCategory> {
      const headers =
        new HttpHeaders().set(
          'authorization', this.authService.getToken()
        );
      return new Promise<KeywordCategory>((resolve, reject) => {
        const url = `${this.util.getAPIUrl()}admin/keywords/${categoryId}`;
        this.http.patch<KeywordCategory>(url, category, { headers }).subscribe({
          next: resolve,
          error: reject
        });
      });
    }

    async deleteCategory(categoryId: string): Promise<void> {
      const headers =
        new HttpHeaders().set(
          'authorization', this.authService.getToken()
        );
      return new Promise<void>((resolve, reject) => {
        const url = `${this.util.getAPIUrl()}admin/keywords/${categoryId}`;
        this.http.delete<void>(url, { headers }).subscribe({
          next: resolve,
          error: reject
        });
      });
    }


    async addKeyword(categoryId: string, keyword: Keyword): Promise<KeywordCategory> {
      const headers =
        new HttpHeaders().set(
          'authorization', this.authService.getToken()
        );
      return new Promise<KeywordCategory>((resolve, reject) => {
        const url = `${this.util.getAPIUrl()}admin/keywords/${categoryId}`;
        this.http.post<KeywordCategory>(url, keyword, { headers }).subscribe({
          next: resolve,
          error: reject
        });
      });
    }


    async modifyKeyword(categoryId: string, keywordId: string, keyword: Keyword): Promise<KeywordCategory> {
      const headers =
        new HttpHeaders().set(
          'authorization', this.authService.getToken()
        );
      return new Promise<KeywordCategory>((resolve, reject) => {
        const url = `${this.util.getAPIUrl()}admin/keywords/${categoryId}/${keywordId}`;
        this.http.patch<KeywordCategory>(url, keyword, { headers }).subscribe({
          next: resolve,
          error: reject
        });
      });
    }


    async deleteKeyword(categoryId: string, keywordId: string): Promise<KeywordCategory> {
      const headers =
        new HttpHeaders().set(
          'authorization', this.authService.getToken()
        );
      return new Promise<KeywordCategory>((resolve, reject) => {
        const url = `${this.util.getAPIUrl()}admin/keywords/${categoryId}/${keywordId}`;
        this.http.delete<KeywordCategory>(url, { headers }).subscribe({
          next: resolve,
          error: reject
        });
      });
    }
}
