import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user/user';
import { LoginService } from 'src/app/services/login.service';
import { UserService } from 'src/app/services/user.service';
import { Utils } from 'src/app/utils/utils';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ListingService } from 'src/app/services/listing.service';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css']
})
export class ListingComponent implements OnInit {
  @Input() apartment: any = null as any; 
  imgHost = this.utils.getImageHost();
  orders: 
  {
    moveInDate: Date,
    moveOutDate: Date,
    roomIds: string[]
  }[] = [];
  host: User = {} as User;
  isSmallScreen: boolean = false;
  bookedRooms: any;

  constructor(
    private utils: Utils,
    private userService: UserService,
    private loginService: LoginService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private listingService: ListingService
  ) { }

  ngOnInit(): void {
    this.isSmallScreen = this.breakpointObserver.isMatched('(max-width: 1000px)');
    this.bookedRooms = this.listingService.getBookedRooms(this.apartment);
    this.userService.getUserInfo(this.apartment.owner.userId)
      .subscribe(resp => {
        this.host = resp;
        this.loginService.setLoggedIn(true);
      }, error => {
        this.loginService.setLoggedIn(false);
        this.router.navigate(['/login']);
      })
  }

  isWholeApartment() {
    return this.listingService.isWholeApartment(this.apartment);
  }

  isRentRequestsEmpty() {
    return this.apartment.pendingRequests.length == 0;
  }

  isAvailable() {
    return this.listingService.isAvailable(this.apartment, this.bookedRooms);
  }

  isBooked() {
    return this.listingService.isBooked(this.apartment, this.bookedRooms);
  }

  isPartiallyBooked() {
    return this.listingService.isPartiallyBooked(this.apartment, this.bookedRooms);
  }

  isOccupied() {
    return this.listingService.isOccupied(this.apartment);
  }

  getBookedRooms() {
    return this.apartment.roomIds;
  }
}
