<div class="d-flex flex-column gap-3">
  <div class="d-flex flex-row justify-content-between">
    <h3>Feedback Answers</h3>
    <div class="col px-4">
      <div class="input-group">
        <input
          [(ngModel)]="querySearch"
          type="text"
          class="form-control"
          (ngModelChange)="filterAnswer(this.selectedUserStatus)"
          placeholder="Search"
          aria-label="search"
          aria-describedby="button-addon2"
          name="search"
        />
      </div>
    </div>
    <div ngbDropdown class="user-status-dropdown">
      <button
        type="button"
        class="btn btn-dark"
        id="dropdownBasic1"
        ngbDropdownToggle
      >
        {{ this.selectedUserStatus }}
      </button>
      <div ngbDropdownMenu aria-labelledby="user-status-dropdown">
        <button
          ngbDropdownItem
          *ngFor="let userStatus of this.userStatus"
          (click)="filterAnswer(userStatus)"
        >
          {{ userStatus }}
        </button>
      </div>
    </div>
  </div>
  <ngb-accordion
    *ngIf="filteredAnswers"
    [closeOthers]="true"
    activeIds="static-1"
  >
    <ng-container *ngFor="let answers of filteredAnswers; let i = index">
      <ngb-panel *ngIf="i >= pageStart && i <= pageEnd">
          <ng-template ngbPanelTitle>
            <div class="w-100 pe-3 d-flex justify-content-between">
              <span class="fw-bold col-5">{{ answers.apartmentId.name }}</span>
              <span class="text-decoration-underline col-5">
                Feedback By: {{ answers.userId.email }}
              </span>
              <span class="fw-bold text-dark col-2">
                {{ answers.userStatus.toUpperCase() }}
              </span>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="d-grid">
              <div class="mb-3">
                <strong>Answered date:</strong>
                {{ formatDate(answers.createdAt) }}
              </div>
              <div class="mb-3">
                <strong>Form name:</strong>
                {{ answers.formId.name }}
              </div>
              <div class="mb-3">
                <strong>Rent request ID:</strong>
                {{ answers.rentRequestId._id }}
              </div>
              <div class="mb-3">
                <strong>Move in date:</strong>
                {{ formatDate(answers.rentRequestId.moveInDate) }}
              </div>
              <div class="mb-3">
                <strong>Move out date:</strong>
                {{ formatDate(answers.rentRequestId.moveOutDate) }}
              </div>
              <div
                *ngFor="let answer of answers.answers"
                class="col-12 justify-content-center flex-wrap border p-3"
              >
                <div class="fw-bold pb-1">
                  {{ answer.questionTitle }}
                </div>
                <div>
                  {{ answer.answer }}
                </div>
              </div>
            </div>
          </ng-template>
      </ngb-panel>
    </ng-container>
  </ngb-accordion>
</div>
<div class="row float-end mt-2" *ngIf="filteredAnswers.length > pageSize">
  <app-paginator
    [length]="filteredAnswers.length"
    [pageSize]="pageSize"
    (change)="onPageChange($event)"
    [page]="page"
  ></app-paginator> 
</div>