import { Component, OnInit } from '@angular/core';
import { RentRequestsService } from 'src/app/services/rent-requests.service';
import { Constant } from 'src/app/utils/constant';
import { Page } from 'src/app/models/general/page';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatorService } from 'src/app/services/paginator.service';
import { LoginService } from 'src/app/services/login.service';
import { CityService } from 'src/app/services/city.service';
import { Utils } from 'src/app/utils/utils';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-booking-requests',
  templateUrl: './booking-requests.component.html',
  styleUrls: ['./booking-requests.component.css']
})
export class BookingRequestsComponent implements OnInit {
  selectedCity: string = this.constant.getCities()[0];
  activeOnly: boolean = false;
  status: string[] = [
    'All Requests',
    'Pending Admin',
    'Pending Host',
    'Payment Failed',
    'Cancelled'
  ]
  selectedStatus = this.status[0];
  query: string = "";
  bookingRequests: any[] = [];
  // for pagination
  pageSize = 24;
  page: number = 1;
  totalSize: number = 0;
  cities: string[] = [];

  constructor(
    private constant: Constant,
    private rentRequestsService: RentRequestsService,
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public pageService: PaginatorService,
    private loginService: LoginService,
    private cityService: CityService,
    private utils: Utils,
    public layoutService: LayoutService
  ) { }

  changeCity(city: string) {
    this.selectedCity = city;
    
    this.router.navigate(['/booking-request'], {
      queryParams: { city: this.selectedCity },
      queryParamsHandling: 'merge'
    });
  }

  changeStatus(statusItem: string) {
    this.selectedStatus = statusItem;

    this.router.navigate(['/booking-request'], {
      queryParams: { status: this.selectedStatus },
      queryParamsHandling: 'merge'
    })
  }

  private getParams() {
    let params: {
      field: string,
      value: any
    }[] = [];

    if (this.activeOnly)
      params.push({
        field: 'active',
        value: this.activeOnly
      })
    
    if (this.selectedStatus == 'Pending Admin')
      params.push({
        field: 'pending_admin',
        value: true
      })
    
    if (this.selectedStatus == 'Pending Host')
      params.push({
        field: 'pending_host',
        value: true
      })
    
    if (this.selectedStatus == 'Payment Failed')
      params.push({
        field: 'payment_failed',
        value: true
      })

    if (this.selectedStatus == 'Cancelled') 
      params.push({
        field: 'cancelled',
        value: true
      })

    if (this.query.length > 0)
      params.push({
        field: 'query',
        value: this.query
      })
    
    if (this.selectedCity != "All")
      params.push({
        field: 'city',
        value: this.utils.capitalize(this.selectedCity)
      })
    
    params.push({
      field: 'skip',
      value: (this.page-1) * this.pageService.getPageSize()
    })
    params.push({
      field: 'limit',
      value: this.pageService.getPageSize()
    })

    return params;
    
  }

  searchBookingRequests() {
    this.rentRequestsService.getPendingRequests(this.getParams())
      .subscribe(resp => {
        this.bookingRequests = (resp as any)[0].results;
        this.totalSize = (resp as any)[0].count[0].count;
        this.loginService.setLoggedIn(true);
        console.log(this.totalSize)
      }, error => {
        this.loginService.setLoggedIn(false);
        this.router.navigate(['/login']);
      })
  }

  setPage(pg: number) {
    this.page = pg; 
  }

  ngOnInit(): void {
    this.cityService.getCities().subscribe((resp: any) => {
      resp.forEach((cityInfo: any) => {
        this.cities.push(cityInfo.name);
      })
    })
    this.cities.push("All");

    this.api.subscribeToAPIChange((prod: boolean) => {
      this.bookingRequests = [];
      this.router.navigate(['/booking-request']);
    })
    console.log(this.activatedRoute.snapshot.queryParams)
    // this.readQueryParameters(this.activatedRoute.snapshot.queryParams);
    this.router.navigate(['/booking-request'],  {
      queryParams: this.activatedRoute.snapshot.queryParams,
      queryParamsHandling: 'merge'
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.readQueryParameters(params);
      this.searchBookingRequests();
    })
  }

  onPageChange(pageEvent: Page) {
    this.router.navigate(
      ['/booking-request'],
      {
        queryParams: {
          page: pageEvent.index
        },
        queryParamsHandling: 'merge'
      }
    )
  }

  readQueryParameters(params: any): void {
    if ('status' in params)  {
      this.selectedStatus = params['status'];
    } else {
      this.selectedStatus = this.status[0];
    }

    if ('city' in params) {
      this.selectedCity = params['city'];
    } else {
      this.selectedCity = 'Montreal';
    }

    if ('query' in params) {
      this.query = params['query'];
    } else {
      this.query = "";
    }

    if ('page' in params) {
      this.page = Number(params['page']);
    } else {
      this.page = 1;
    }
  }

  doSearch(): void {
    this.router.navigate(['/booking-request'], {
      queryParams: { query: this.query, page: 1 },
      queryParamsHandling: 'merge'
    });
  }

}
