import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { ToastService } from './toast/toast-service';
import { environment } from '../../environments/environment';
import urljoin from 'url-join'
import { ActionResult, PatchResult } from './api.types';

const API_PREFIX = 'admin'
const MATCHING_API_PREFIX = 'api'

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private subject = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private cookieService: CookieService,
  ) { }

  isProd() {
    return environment.production;
  }

  subscribeToAPIChange(func: any) {
    this.subject.subscribe(func);
  }

  get<T>(path: string): Observable<T> {
    let url = urljoin(environment.apiUrl, API_PREFIX, path);
    const headers = new HttpHeaders().set(
      'authorization', String(localStorage.getItem(environment.production ? 'prod' : 'dev'))
    );
    return this.http.get<T>(url, { headers });
  }

  post<T>(path: string, body: any): Observable<T> {
    let url = urljoin(environment.apiUrl, API_PREFIX, path);
    const headers = new HttpHeaders().set(
      'authorization', String(localStorage.getItem(environment.production ? 'prod' : 'dev'))
    );
    return this.http.post<T>(url, body, { headers });
  }

  put<T>(path: string, body: any): Observable<T> {
    let url = urljoin(environment.apiUrl, API_PREFIX, path);
    const headers = new HttpHeaders().set(
      'authorization', String(localStorage.getItem(environment.production ? 'prod' : 'dev'))
    );
    return this.http.put<T>(url, body, { headers });
  }

  patch(path: string, body: any): Observable<string> {
    let url = urljoin(environment.apiUrl, API_PREFIX, path);
    const headers = new HttpHeaders().set(
      'authorization', String(localStorage.getItem(environment.production ? 'prod' : 'dev'))
    );
    const subject = new Subject<string>;
    this.http.patch<PatchResult>(url, body, { headers }).subscribe({
      next: (result) => {
        subject.next(result.message);
        this.toastService.show(result.message, { classname: 'bg-success text-light', delay: 5000 });
      },
      error: (error: HttpErrorResponse) => {
        if (error.error?.message !== undefined && error.error?.success === false) {
          subject.error(error.error?.message);
          this.toastService.show(error.error?.message, { classname: 'bg-danger text-light', delay: 5000 });
        } else {
          subject.error('Error ' + error.status.toString() + ' received.');
          this.toastService.show('Error ' + error.status.toString() + ' received.', { classname: 'bg-danger text-light', delay: 5000 });
        }
      }
    });
    return subject.asObservable();
  }

  do(path: string): Observable<string> {
    let url = urljoin(environment.apiUrl, API_PREFIX, path);
    const headers = new HttpHeaders().set(
      'authorization', String(localStorage.getItem(environment.production ? 'prod' : 'dev'))
    );
    const subject = new Subject<string>;
    this.http.get<ActionResult>(url, { headers }).subscribe({
      next: (result) => {
        subject.next(result.message);
        this.toastService.show(result.message, { classname: 'bg-success text-light', delay: 5000 });
      },
      error: (error: HttpErrorResponse) => {
        if (error.error?.message !== undefined && error.error?.success === false) {
          subject.error(error.error?.message);
          this.toastService.show(error.error?.message, { classname: 'bg-danger text-light', delay: 5000 });
        } else {
          subject.error('Error ' + error.status.toString() + ' received.');
          this.toastService.show('Error ' + error.status.toString() + ' received.', { classname: 'bg-danger text-light', delay: 5000 });
        }
      }
    });
    return subject.asObservable();
  }
}
