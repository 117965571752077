<div class="container">
    <div class="row">
        <ng-container *ngFor="let value of values; let i = index">
            <ng-container *ngIf="i >= indexStart && i <= indexEnd">
                <div class="col-auto">
                    <div class="input-group ms-2">
                        <div class="">
                          <input 
                                class="form-check-input mt-0" 
                                type="checkbox" 
                                value="{{value}}" 
                                [checked]="hasIndex(i)" 
                                disabled="true"
                                >
                          <span class="ms-2">{{value}}</span>
                        </div>
                      </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
