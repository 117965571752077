<div class="container">
    <div class="row">
        <div class="col-6" id="title">
            <h2>Users</h2>
        </div>
        <div class="col-auto">
            <div ngbDropdown class="statusDropdown">
                <button type="button"class="btn btn-outline-primary" id="userStatusDropdown" ngbDropdownToggle>
                    {{ selectedStatus}}
                </button>
                <div ngbDropdownMenu aria-labelledby="userStatusDropdown">
                    <button ngbDropdownItem *ngFor="let status of userStatus" (click)="changeStatus(status)">
                        {{ status }}
                    </button>
                </div>
            </div>
        </div>
        <div class="col" id="search">
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Search" [(ngModel)]="query" aria-describedby="searchBtn" (keydown.enter)="doSearch()">
                <button class="btn btn-outline-secondary" id="searchBtn" (click)="doSearch()">Search</button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="row py-2 border-bottom border-top"  *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
            <div class="col-3 fw-bold">
                #
            </div>
            <div class="col-2 fw-bold">
                User Name
            </div>
            <div class="col-3 fw-bold">
                Email
            </div>
            <div class="col-2 fw-bold">
                Phone Number
            </div>
            <div class="col-2 fw-bold">
                Status
            </div>
        </div>
        <ng-container *ngFor="let user of users">
            <div class="clickable user" (click)="openUser(user._id)">
                <app-user [user]="user"></app-user>
            </div>
        </ng-container>
    </div>
    <div class="row float-end mt-2" *ngIf="totalSize > this.pageService.getPageSize()">
        <div class="col-sm-10">
            <app-paginator 
            [length]="totalSize"
            [pageSize]="this.pageService.getPageSize()"
            (change)="onPageChange($event)"
            [page]="page"
            [rotate]="true"
            [ellipses]="false"
            [maxSize]="12"
            ></app-paginator> 
        </div>
    </div>
        
</div>