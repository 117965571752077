import { Component, OnInit } from '@angular/core';
import { ListingService } from 'src/app/services/listing.service';
import { Apartment } from 'src/app/models/apartment/apartment';
import { Page } from 'src/app/models/general/page';
import { Utils } from 'src/app/utils/utils';
import { ApiService } from 'src/app/services/api.service';
import { LoginService } from 'src/app/services/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatorService } from 'src/app/services/paginator.service';
import { CityService } from 'src/app/services/city.service';
import { LayoutService } from 'src/app/services/layout.service';
@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.css']
})
export class ListingsComponent implements OnInit {
  limit: number = 12;
  selected: string = 'Montreal';
  apartments: Array<Apartment> = new Array<Apartment>();
  query: string = "";
  
  // page is the current page index, and listings showed on current page is [pageStart, pageEnd]
  page: number = 1;
  cities: string[] = [];
  isActive: boolean = false;
  occupied: boolean = false;
  wholeApartment: boolean = false;
  listedAndActivedOnly: boolean = true;
  status: string[] = [
    "All Status",
    "Available",
    "Booked",
    "Occupied",
    "Partically Booked"
  ]
  selectedStatus: string = "All Status";
  totalSize: number = 0;
  

  constructor(
    private listingService: ListingService,
    private utils: Utils,
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    public pageService: PaginatorService,
    private router: Router,
    private loginService: LoginService,
    private cityService: CityService,
    public layoutService: LayoutService
  ) { 
    
  }

  changeCity(city: string) {
    this.selected = city;
    this.router.navigate(['/listing'], {
      queryParams: { city: this.selected },
      queryParamsHandling: "merge"
    })
  }

  changeStatus(statusItem: string) {
    this.selectedStatus = statusItem;
    this.router.navigate(['/listing'], {
      queryParams: { status: this.selectedStatus },
      queryParamsHandling: "merge"
    })
  }
  
  doSearch() {
    this.router.navigate(['/listing'], {
      queryParams: { query: this.query, page: 1 },
      queryParamsHandling: "merge"
    });
  }

  searchApartments() {
    let params: {
      field: string,
      value: any
    }[] = [];

    if (this.listedAndActivedOnly) {
      params.push({
        field: 'listed',
        value: true
      });
      params.push({
        field: 'active',
        value: true
      })
    }
    if (this.selected != 'All') {
      params.push({
        field: 'city',
        value: this.utils.capitalize(this.selected)
      })
    }
    if (this.query.length > 0) {
      params.push({
        field: 'query',
        value: this.query
      })
    }
    params.push({
      field: 'skip',
      value: (this.page - 1) * this.pageService.getPageSize()
    });
    params.push({
      field: 'limit',
      value: this.pageService.getPageSize()
    });
    switch (this.selectedStatus) {
      case 'Available':
        params.push({
          field: 'available',
          value: true
        })
        break;
      case 'Booked':
        params.push({
          field: 'booked',
          value: true
        })
        break;
      case 'Partically Booked':
        params.push({
          field: 'partically',
          value: true
        })
        break;
      case 'Occupied':
        params.push({
          field: 'occupied',
          value: true
        })
        break;
    }
    try {
      this.listingService.searchApartments(params).subscribe(resp => {
        if ((resp as any).length > 0) {
          this.apartments = (resp as any)[0].results;
          this.totalSize = (resp as any)[0].count[0].count;
        } else {
          this.apartments = []
          this.totalSize = 0;
        }
        this.loginService.setLoggedIn(true);
      }, (error) => {
        console.log(error);
        this.router.navigate(['/login']);
        this.loginService.setLoggedIn(false);
      })
    } catch (err) {
      this.apartments = []
    }
  }


  async ngOnInit() {
    this.cityService.getCities().subscribe((resp: any) => {
      resp.forEach((cityInfo: any) => {
        this.cities.push(cityInfo['name']);
      })
    })
    this.cities.push("All");

    this.api.subscribeToAPIChange((prod: boolean) => {
      this.apartments = []
      this.router.navigate(['/listing']);
    });

     // subscribe to query params change
    this.activatedRoute.queryParams.subscribe(params => {
      this.readQueryParams(params);
      this.searchApartments();
    })

    // deal with current query parameters
    const currentQueryParams = this.activatedRoute.snapshot.queryParams;
    this.readQueryParams(currentQueryParams);
    this.router.navigate(['/listing'], {
      queryParams: currentQueryParams,
      queryParamsHandling: "merge"
    })
  }


  setPage(pageNumber: number): void {
    this.page = pageNumber;
  }

  onPageChange(pageEvent: Page) {
    this.router.navigate(['/listing'], {
      queryParams: { page: pageEvent.index },
      queryParamsHandling: "merge" 
    })
  }

  initPaginator() {
    this.page = 1;
  }

  getSearchURL() {
    return `${this.utils.getAPIUrl()}apartments`
  }

  readQueryParams(params: any): void {
    if ('page' in params) {
      this.setPage(params['page']);
    } else {
      this.setPage(1);
    }

    if ('city' in params) {
      this.selected = params['city'];
    } else {
      this.selected = 'Montreal';
    }

    if ('status' in params) {
      this.selectedStatus = params['status'];
    } else {
      this.selectedStatus = 'All Status';
    }
    
    if ('query' in params) {
      this.query = params['query']
    } else {
      this.query = "";
    }
  }
}