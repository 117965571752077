import { Component, OnInit, Input } from '@angular/core';
import { Photo } from 'src/app/models/apartment/photo';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-photo-carousel',
  templateUrl: './photo-carousel.component.html',
  styleUrls: ['./photo-carousel.component.css']
})
export class PhotoCarouselComponent implements OnInit {
  @Input() inputPhotos: Photo[] = [];
  photos: Photo[] = [];

  constructor(
    private utils: Utils
  ) {
   }

  ngOnInit(): void {
    for (let photo of this.inputPhotos) {
      this.photos.push(photo);
    }
  }

  getImgSrc(src: string): string {
    return `${this.utils.getImageHost()}apartment/${src}`;
  }

}
