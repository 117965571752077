<app-tokens-accordion
    [categories]="accordion"
    title="Keywords Management"
    categoryName="category"
    tokenName="keyword"
    [loading]="loading"
    (onCategoryAdd)="onCategoryAdd()"
    (onCategoryEdit)="onCategoryEdit($event)"
    (onCategoryDelete)="onCategoryDelete($event)"
    (onTokenAdd)="onTokenAdd($event)"
    (onTokenEdit)="onTokenEdit($event)"
    (onTokenDelete)="onTokenDelete($event)"
    (onToggle)="onToggle($event)"
></app-tokens-accordion>
