import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Utils } from '../utils/utils';
import { HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private utils: Utils,
    private http: HttpClient,
    private cookie: CookieService,
    private authService: AuthService
  ) { }

  paymentPaidToHost(paymentInfo: any): Observable<any> {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    let url = `${this.utils.getAPIUrl()}admin/payment/paid-to-host`;
    return this.http.post<any>(url, ({
      payoutInfo: paymentInfo 
    }), {headers: headers});
  }
}