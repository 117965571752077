import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { right } from '@popperjs/core';
import { ChartConfiguration, ChartComponent, Chart } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ApiService } from 'src/app/services/api.service';
import { Model, Cluster, Clusters, Keyword } from '../ai-results.types';
import { TopKeywordsComponent } from '../top-keywords/top-keywords.component';

@Component({
  selector: 'app-ai-results-data',
  templateUrl: './ai-results-data.component.html',
  styleUrls: ['./ai-results-data.component.scss']
})
export class AiResultsDataComponent implements OnInit {

  @ViewChild('chart') chart: ElementRef<HTMLCanvasElement> | undefined;
  @Input() model: Model = {} as Model;
  @Output() onBack = new EventEmitter<void>();
  @Output() onDismiss   = new EventEmitter<void>();

  private hovering = false;
  public isLoading: boolean = true;
  private bubbleChartColors = ['red', 'green', 'blue', 'yellow', 'purple', 'gray', 'black', 'orange', 'fuchsia', 'lime', 'teal', 'maroon', 'aqua', 'olive', 'lightpink', 'navy', 'thistle', 'wheat', 'peru'];
  public bubbleChartLegend = true;
  public bubbleChartDatasets: ChartConfiguration<'bubble'>['data']['datasets'] = [];
  public bubbleChartOptions: ChartConfiguration<'bubble'>['options'] = {
    responsive: true,
    animation: {
      duration: 500
    },
    plugins: {
      legend: {
        position: right,
        labels: {
          usePointStyle: true,
          padding: 15,
        },
        maxHeight: 200,
        onHover: (evt: any, item: any, legend: any) => {
          if (this.chart)
            this.chart.nativeElement.style.cursor = 'pointer';
          this.hovering = true;
          const chart = legend.chart;
          const index = item.datasetIndex;
          if (index === undefined) return;
          for (let i = 0; i < chart.data.datasets.length; i++) {
            if (i !== index) {
              chart.data.datasets[i].backgroundColor = 'transparent';
              chart.data.datasets[i].borderColor = 'transparent';
            } else {
              chart.data.datasets[i].backgroundColor = this.bubbleChartColors[i];
              chart.data.datasets[i].borderColor = this.bubbleChartColors[i];
            }
          }
          chart.update();
        },
        onLeave: async (evt: any, item: any, legend: any) => {
          if (this.chart)
            this.chart.nativeElement.style.cursor = 'default';
          this.hovering = false;
          await this.delay(500);
          if (this.hovering) return;
          const chart = legend.chart;
          const index = item.datasetIndex;
          if (index === undefined) return;
          for (let i = 0; i < chart.data.datasets.length; i++) {
            chart.data.datasets[i].backgroundColor = this.bubbleChartColors[i];
            chart.data.datasets[i].borderColor = this.bubbleChartColors[i];
          }
          chart.update();
          this.hovering = false;
        },
        onClick: (evt: any, item: any, legend: any) => {
          const index = item.datasetIndex;
          if (index === undefined) return;
          this.showKeywords(index);
        }
      },
      tooltip: {
        enabled: false
      },
    }
  };
  public topKeywords: Keyword[] = []


  constructor(
    private modalService: NgbModal,
    private zone: NgZone,
    private apiService: ApiService
    ){}

  ngOnInit() {
    this.getClusters()
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  getClusters() {
    this.apiService.get<Clusters>('/models/' + this.model.model_tag + '/clusters').subscribe({
      next: (response) => {
        this.setClustersDots(response.clusters);
        this.topKeywords = response.top_keywords;
      }
      // error: (error: HttpErrorResponse) => {reject(error);}
    })
  }

  setClustersDots(clusters: Cluster[]) {
    this.model.results.clusters = clusters
    clusters.forEach((cluster, index) => {
      const data = [];
      // let i = 0;
      for (const point of cluster.visualization) {
        //if (i % 4 === 0)
          data.push({x: point.x, y: point.y, r: 2});
        //i = (i+1)%4;
      }
      this.bubbleChartDatasets.push({
        data: data,
        borderColor: this.bubbleChartColors[index],
        backgroundColor: this.bubbleChartColors[index],
        label: `Cluster ${index + 1}`
      });
    })
    this.isLoading = false
  }

  showKeywords(index: number) {
    let topKeywords = this.topKeywords;
    if (index >= 0)
      topKeywords = this.model.results.clusters[index].top_keywords
    this.zone.run(() => {
      const modalRef = this.modalService.open(TopKeywordsComponent, { centered: true, size: 'md', scrollable: true });
      modalRef.componentInstance.keywords = topKeywords;
      modalRef.componentInstance.cluster = index;
    });
  }

  useModel() {
    this.apiService.do('/models/' + this.model.model_tag + '/load').subscribe({
      next: () => {
        localStorage.removeItem("models")
        this.onBack.next();
      }
    })
  }

}
