import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  constructor(
    private http: HttpClient,
    private util: Utils
  ) { }

  getCities() {
    const url = `${this.util.getAPIUrl()}cities`;
    return this.http.get(url);
  }
}
