import { Component, OnInit } from '@angular/core';
import { Photo } from 'src/app/models/apartment/photo';
import { User } from 'src/app/models/user/user';
import { UserService } from 'src/app/services/user.service';
import { Utils } from 'src/app/utils/utils';
import { Constant } from 'src/app/utils/constant';
import { ActivatedRoute } from '@angular/router';
import { ListingService } from 'src/app/services/listing.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PhotoCarouselComponent } from './photo-carousel/photo-carousel.component';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TokenService } from 'src/app/services/token.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { LayoutService } from 'src/app/services/layout.service';
import { ConfirmModalComponent } from '../../matching/shared/confirm-modal/confirm-modal.component';
import { MessageModalComponent } from '../../general/modal/message-modal/message-modal.component';

@Component({
  selector: 'app-listing-detail',
  templateUrl: './listing-detail.component.html',
  styleUrls: ['./listing-detail.component.css']
})
export class ListingDetailComponent implements OnInit {
  apartment: any = {} as any;
  photos: Photo[] = [];
  imgHost: string = "";
  isAddressCollapsed: boolean = true;
  isOwnerCollapsed: boolean = true;
  isFacilitiesCollapsed: boolean = true;
  isRoomsCollapsed: boolean = true;
  owner: User = {} as User;
  facilities: string[] = [];
  bedTypes: string[] = [];
  bookedRooms: {
    id: string,
    moveInDate: Date,
    moveOutDate: Date
  }[] = [];
  activeId:number = 1;
  mapOptions: google.maps.MapOptions = {};
  markerPosition: google.maps.LatLngLiteral = {lat: 0, lng: 0};
  safeEditingUrl: SafeResourceUrl | null = null;
  isEditingRealName: boolean = false;
  isEditingRealPhone: boolean = false;
  isEditingRealEmail: boolean = false;
  isLoading: boolean = false;

  constructor(
    private utils: Utils,
    private userService: UserService,
    private constant: Constant,
    private route: ActivatedRoute,
    private listingService: ListingService,
    private modalService: NgbModal,
    private router: Router,
    public sanitizer: DomSanitizer,
    private tokenService: TokenService,
    private authService: AuthService,
    public layoutService: LayoutService,
  ) { 
    this.imgHost = this.utils.getImageHost();
  }

  getImgHost() {
    return this.utils.getImageHost();
  }

  getBedType(typeIndex: string) {
    return this.bedTypes[Number(typeIndex)];
  }

  getBathCount() {
    if (this.apartment.bathCount == '0') return '1';
    else if (this.apartment.bathCount == '3') return '3+';
    return (Number(this.apartment.bathCount)+1).toString();
  }

  getRangeOfPrice() {
    let minPrice = this.apartment.rooms[0].price,
        maxPrice = this.apartment.rooms[0].price;
    for (let room of this.apartment.rooms) {
      if (room.price > maxPrice) {
        maxPrice = room.price;
      }
      if (room.price < minPrice) {
        minPrice = room.price;
      }
    }
    return [minPrice, maxPrice];
  }

  openCarouselModal() {
    const modalRef = this.modalService.open(PhotoCarouselComponent);
    modalRef.componentInstance.inputPhotos = this.photos;
  }

  openModal(content: any) {
    this.modalService.open(content, { scrollable: true, size: 'xl' });
  }

  getAccomodations() {
    return this.constant.getAccomodations();
  }

  getFacilities() {
    return this.constant.getFacilities();
  }

  isWholeApartment() {
    return this.listingService.isWholeApartment(this.apartment);
  }

  isAvailable() {
    return this.listingService.isAvailable(this.apartment, this.bookedRooms);
  }

  isPartiallyBooked() {
    return this.listingService.isPartiallyBooked(this.apartment, this.bookedRooms);
  }

  isBooked() {
    return this.listingService.isBooked(this.apartment, this.bookedRooms);
  }

  isOccupied() {
    return this.listingService.isOccupied(this.apartment);
  }

  isRoomBooked(id: string) {
    for (let room of this.bookedRooms) {
      if (room.id == id) {
        return true;
      }
    }
    return false;
  }

  getBookedRoom(id: string) {
    for (let room of this.bookedRooms) {
      if (room.id == id) {
        return room;
      }
    }
    return {
      moveInDate: undefined,
      moveOutDate: undefined,
      id: ""
    };
  }

  isListedByHivenue(): boolean {
    return 'listedByHivenue' in this.apartment && this.apartment.listedByHivenue === true;
  }

  getBookingRequestOfRoom(roomId: string) {
    let res = [];
    for (let request of this.apartment.rentRequests) {
      for (let id of request.roomIds) {
        if (id === roomId) {
          res.push(request);
        }
      }
    }
    return res;
  }

  setListedByHivenue(val: boolean) {
    this.apartment.listedByHivenue = val;
    this.isLoading = true;
    this.listingService.updateApartment(this.apartment._id, this.apartment).subscribe((resp) => {
      (resp as any).rentRequests = this.apartment.rentRequests;
      this.apartment = resp;
      this.isLoading = false; 
    }, (error) => {
      alert(error);
    })
  }

  // update apartment without confirming
  updateApartmentHelper() {
    this.isLoading = true;
    this.listingService.updateApartment(this.apartment._id, this.apartment).subscribe((resp) => {
      (resp as any).rentRequests = this.apartment.rentRequests;
      this.apartment = resp;
      this.isLoading = false; 
    }, (error) => {
      alert(error);
    })
  }

  updateApartment() {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.closed.subscribe(() => {
      const msgModal = this.modalService.open(MessageModalComponent);
      this.listingService.updateApartment(this.apartment._id, this.apartment).subscribe((resp) => {
        (resp as any).rentRequests = this.apartment.rentRequests;
        msgModal.componentInstance.title = "Success";
        msgModal.componentInstance.message = "Successful Operation";
      }, (error) => {
        msgModal.componentInstance.title = "Error";
        if (!error.error) error = { error: error }
        msgModal.componentInstance.message = error.error;
      })
    })
    
  }

  onChangeVisiblityOfApartment() {
    this.updateApartmentHelper();
  }

  onChangeOccupationStatusOfApartment() {
    this.updateApartmentHelper();
  }

  ngOnInit(): void {
    this.route.params.subscribe( params =>
      this.listingService.getApartmentById(params['id']).subscribe(
        resp => {
          this.apartment = resp;
          if (!resp) 
            this.apartment = history.state.apartment;
          for (let photo of this.apartment.photos) {
            this.photos.push(photo);
          }
          this.userService.getUserInfo(this.apartment.owner.userId._id)
            .subscribe((res: any) => {
                this.owner = res;
                this.apartment.owner.name = this.owner.first_name + " " + this.owner.last_name;
          }, error => {
            this.router.navigate(['/login']);
          });
          this.facilities = this.constant.getFacilities();
          this.bedTypes = this.constant.getBedTypes();
          this.mapOptions = {
            center:  {
              lat: this.apartment.adress.lat,
              lng: this.apartment.adress.lng
            },
            zoom: 13
          }
          this.markerPosition = {
            lat: this.apartment.adress.lat,
            lng: this.apartment.adress.lng
          }
          this.bookedRooms = this.listingService.getBookedRooms(this.apartment);
          console.log(this.bookedRooms)
          this.tokenService.generateLoginToken(this.apartment.owner.userId).subscribe(resp => {
            this.safeEditingUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              `${environment.frontEndUrl}en/share/apartment?edit=${this.apartment._id}&token=${(resp as any).token}&owner=${this.apartment.owner.userId._id}`
            )
          })
        },
        error => {
          this.router.navigate(['/login']);
        }
      )
    )
    
    
    
  }

}