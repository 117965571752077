import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { HistoryResponse, MatchingHistory } from 'src/app/models/matching/matchingHistory';
import { MatchingService } from 'src/app/services/matching.service';

@Component({
  selector: 'app-matching-history',
  templateUrl: './matching-history.component.html',
  styleUrls: ['./matching-history.component.css']
})
export class MatchingHistoryComponent implements OnInit {

  page: number = 1;
  response: HistoryResponse | undefined;
  searchControl = new FormControl('');
  private _loading = false;

  constructor(
    private matchingService: MatchingService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe
  ) { }

  async ngOnInit() {
    this.loadPage(1);
    this.searchControl.valueChanges.subscribe(() => {
      this.loadPage(1);
    })
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    if (value) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
    this._loading = value;
  }

  async loadPage(page: number) {
    this.loading = true;
    this.page = page;
    this.response = await this.matchingService.getMatchHistory(page, this.searchControl.value as string);
    this.loading = false;
  }

  formatDate(date: string | Date): string {
    return this.datePipe.transform(date, 'yyyy/MM/dd HH:mm') ?? '';
  }

}
