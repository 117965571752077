import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { PaginatorComponent } from './paginator.component';



@NgModule({
  declarations: [
    PaginatorComponent
  ],
  imports: [
    CommonModule,
    NgbModule
  ],
  exports: [PaginatorComponent]
})
export class PaginatorModule { }
