import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";


export const RENT_REQUEST_STATUS = {
    PENDING: 'PENDING',
    DECLINED: 'DECLINED',
    APPROVED: 'APPROVED',
    PAYMENT_FAILED: 'PAYMENT_FAILED',
    CLOSED: 'CLOSED',
    PAID: 'PAID',
    NO: 'NO_PAYMENT',
    PARTIALLY_PAID: 'PARTIALLY_PAID',
    CLOSED_BY_SYSTEM: 'CLOSED_BY_SYSTEM',
    ADMIN_CANCELLED: 'ADMIN_CANCELLED',
    DELETED: 'DELETED'
};

export const RENT_REQUEST_STAGES = {
    ADMIN: 'ADMIN',
    HOST: 'HOST',
};

export const MAX_SHORT_STAY_DAYS = 62;
@Injectable({
    providedIn: 'root'
})
export class Constant {
    constructor(
        private http: HttpClient
    ) {}

    getCities(): string[] {
        return [
            "Montreal",
            "Toronto",
            "Ottawa",
            "Kingston"
        ]
    }

    getFacilities(): string[] {
        return [
            "",
            "Bedding",              // 1
            "Closet",
            "Private bathroom",
            "Desk",
            "Windows",
            "Dresser",
            "Bedside lamp",
            "Plants"
        ]
    }

    getBedTypes(): string[] {
        return [
            "",
            "Single Bed",
            "Double Bed",
            "Queen Bed",
            "King Bed",
            "Bunk Bed",
            "Sofa Bed",
            "Air Mattress"
        ]
    }

    getAccomodations(): string[] {
        return [
            "",
            "High Speed WiFi",
            "Air Conditioning",
            "Parking",
            "Centeral Heating",
            "Laundry Room",
            "Washer",
            "Gym",
            "Alarm System",
            "TV",
            "Pet Friendly",
            "Smoking",
            "Couple Friendly",
            "Family Friendly",
        ]
    }

    getSpokenLanguages() {
        return this.http.get<{ [id: string]: {
            name: string,
            nativeName: string
          }}>(
            'assets/i18n/languages.json'
        )
    }
}