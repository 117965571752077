import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkerParam, WorkerParamUpdate } from 'src/app/services/workers.types';

interface Section {
  name: string;
  params: {
    infos: WorkerParam,
    control: FormControl
  }[]
}

@Component({
  selector: 'app-worker-params',
  templateUrl: './worker-params.component.html',
  styleUrls: ['./worker-params.component.scss']
})
export class WorkerParamsComponent {

  public params: WorkerParam[] = [];
  public sections: Section[] = [];
  public activeSection: string = '';
  public collapsed: {[section: string]: boolean} = {};
  

  constructor(
    public modal: NgbActiveModal,
    private changeDetectorRef: ChangeDetectorRef
    ) {}

  ngOnInit(): void {
    this.sections = [];
    this.collapsed = {};
    this.activeSection = '';
    for (const param of this.params) {
      let section = this.getSection(param.ui.section);
      if (section === undefined) {
        section = {params: [], name: param.ui.section};
        this.sections.push(section);
        this.collapsed[section.name] = true;
      }
      if (this.activeSection === '') {
        this.activeSection = section.name;
      }
      section.params.push({
        infos: param,
        control: new FormControl(param.value)
      })
    }
  }

  save(): void {
    const updatedParams: WorkerParamUpdate[] = [];
    for (const section of Object.values(this.sections)) {
      for (const param of section.params) {
        updatedParams.push({id: param.infos.id, value: param.control.value})
      }
      
    }
    this.modal.close(updatedParams);
  }

  private getSection(name: string): Section | undefined {
    for (const section of this.sections) {
      if (section.name === name) return section;
    }
    return undefined;
  }

}
