import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingDetailComponent } from '../listing-detail/listing-detail.component';
import { ListingRoutingRoutingModule } from './listing-routing-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ListingRoutingRoutingModule
  ]
})
export class ListingRoutingModule { }
