<div class="d-flex flex-column gap-3" style="min-height: 100%;">

    <!-- Component Header -->
    <div class="row">
        <h3 class="col-12 col-sm-7 col-md-6 col-lg-4">Matches History</h3>
        <div class="col-12 col-sm-5 col-md-6 col-lg-8">
            <input
              type="text"
              class="form-control"
              [formControl]="searchControl"
              placeholder="Search"
              aria-label="search"
              aria-describedby="button-addon2"
              name="search"
            />
        </div>
    </div>

    <!-- Accordion -->
    <div *ngIf="response && response.history.length > 0" class="flex flex-column w-100">
        <ngb-accordion  class="mb-4" [closeOthers]="true">
            <ngb-panel *ngFor="let match of response.history" [id]="match._id">

                <!-- Match Title -->
                <ng-template ngbPanelTitle>
                    <div class="w-100 pe-3 d-grid">
                        <div class="row">
                            <!-- Left side -->
                            <div class="col-12 col-md-8">
                                <div class="text-truncate">{{match.user.email}}</div>
                                <div class="text-truncate">{{match.apartment.adress.adress}}</div>
                            </div>
                            <!-- Right side -->
                            <div class="col-12 col-md-4 d-flex justify-content-end align-items-center">
                                <span class="text-truncate" style="font-weight: 500;">{{formatDate(match.created)}}</span>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <!-- Match Content -->
                <ng-template ngbPanelContent>
                    <div class="d-flex flex-column gap-1 mb-3">

                        <!-- Rent Request -->
                        <div class="mb-2" style="font-weight: 500; font-size: 16px;">Rent Request</div>
                        <div class="w-100 d-grid" style="font-weight: 400; font-size: 12px;">
                            <div class="row">
                                <span  class="col-2" style="font-weight: 500; font-size: 14px;">ID: </span >
                                <span  class="col-10 align-middle" style="line-height: 21px;">{{match.rentRequest._id}}</span >
                            </div>
                            <div class="row">
                                <span  class="col-2" style="font-weight: 500; font-size: 14px;">Move In: </span >
                                <span  class="col-10 align-middle" style="line-height: 21px;">{{formatDate(match.rentRequest.moveInDate)}}</span >
                            </div>
                            <div class="row">
                                <span  class="col-2" style="font-weight: 500; font-size: 14px;">Move Out: </span >
                                <span  class="col-10 align-middle" style="line-height: 21px;">{{formatDate(match.rentRequest.moveOutDate)}}</span >
                            </div>
                            <div class="row">
                                <span  class="col-2" style="font-weight: 500; font-size: 14px;">Duration: </span >
                                <span  class="col-10 align-middle" style="line-height: 21px;">{{match.rentRequest.stayInDays}} days</span >
                            </div>
                            <div class="row">
                                <span  class="col-2" style="font-weight: 500; font-size: 14px;">Created: </span >
                                <span  class="col-10 align-middle" style="line-height: 21px;">{{formatDate(match.rentRequest.createdAt)}}</span >
                            </div>
                        </div>

                        <!-- Apartment -->
                        <div class="mt-3 pt-3 mb-2 border-top" style="font-weight: 500; font-size: 16px;">Apartment</div>
                        <div class="w-100 d-grid" style="font-weight: 400; font-size: 12px;">
                            <div class="row">
                                <span  class="col-1" style="font-weight: 500; font-size: 14px;">ID: </span >
                                <span  class="col-11 align-middle" style="line-height: 21px;">{{match.apartment._id}}</span >
                            </div>
                            <div class="row">
                                <span  class="col-1" style="font-weight: 500; font-size: 14px;">Name: </span >
                                <span  class="col-11 align-middle" style="line-height: 21px;">{{match.apartment.name}}</span >
                            </div>
                            <div class="row">
                                <span  class="col-1" style="font-weight: 500; font-size: 14px;">Address: </span >
                                <span  class="col-11 align-middle" style="line-height: 21px;">{{match.apartment.adress.adress}}</span >
                            </div>
                            <div class="row">
                                <span  class="col-1" style="font-weight: 500; font-size: 14px;">Host: </span >
                                <span  class="col-11 align-middle" style="line-height: 21px;">{{match.apartment.owner.name}} ({{match.apartment.owner.userId}})<ng-icon *ngIf="match.apartment.hostLivesInApartment" class="aligned" name="ionHome"></ng-icon></span >
                            </div>
                        </div>
                        
                        <!-- Client -->
                        <div class="mt-3 pt-3 mb-2 border-top" style="font-weight: 500; font-size: 16px;">Client</div>
                        <div class="w-100 d-grid" style="font-weight: 400; font-size: 12px;">
                            <div class="row">
                                <span  class="col-1" style="font-weight: 500; font-size: 14px;">ID: </span >
                                <span  class="col-11 align-middle" style="line-height: 21px;">{{match.user._id}}</span >
                            </div>
                            <div class="row">
                                <span  class="col-1" style="font-weight: 500; font-size: 14px;">Name: </span >
                                <span  class="col-11 align-middle" style="line-height: 21px;">{{match.user.first_name}} {{match.user.last_name}}</span >
                            </div>
                            <div class="row">
                                <span  class="col-1" style="font-weight: 500; font-size: 14px;">Email: </span >
                                <span  class="col-11 align-middle" style="line-height: 21px;">{{match.user.email}}</span >
                            </div>
                        </div>
                        
                        <!-- Neighborhood match -->
                        <div class="mt-3 pt-3 mb-2 border-top" style="font-weight: 500; font-size: 16px;">Neighborhood match</div>
                        <div class="row">
                            <span  class="col-1" style="font-weight: 500; font-size: 14px;">Level: </span >
                            <span  class="col-11 align-middle" style="line-height: 21px;">{{match.neighborhoodMatchLevel}}</span >
                        </div>
                        <div class="row">
                            <span  class="col-1 align-top" style="font-weight: 500; font-size: 14px;">Keywords: </span >
                            <div  class="col-11 d-flex flex-wrap gap-2">
                                <span *ngFor="let keyword of match.neighborhoodMatchReasonRaw" class="text-light px-2 rounded user-select-none" style="font-size: 12px; background-color: #3991ff;" ngbTooltip="{{keyword.weight.toFixed(4)}}" container="body" tooltipClass="custom-tooltip">
                                    {{keyword.keyword}}
                                </span>
                            </div>
                        </div>

                        <!-- Co-living match -->
                        <div class="mt-3 pt-3 mb-2 border-top" style="font-weight: 500; font-size: 16px;">Co-living match</div>
                        <div class="row">
                            <span  class="col-1" style="font-weight: 500; font-size: 14px;">Level: </span >
                            <span  class="col-11 align-middle" style="line-height: 21px;">{{match.matchLevel}}</span >
                        </div>
                        <div class="row">
                            <span  class="col-1 align-top" style="font-weight: 500; font-size: 14px;">Keywords: </span >
                            <div  class="col-11 d-flex flex-wrap gap-2">
                                <span *ngFor="let keyword of match.matchReasonRaw" class="text-light px-2 rounded user-select-none" style="font-size: 12px; background-color: #68c131;" ngbTooltip="{{keyword.weight.toFixed(4)}}" container="body" tooltipClass="custom-tooltip">
                                    {{keyword.keyword}}
                                </span>
                            </div>
                        </div>

                        <!-- Individual match -->
                        <div class="mt-3 pt-3 mb-2 border-top" style="font-weight: 500; font-size: 16px;">Individual matches</div>
                        <div class="w-100 d-flex flex-column align-items-end gap-3">
                            <div class="col-11 d-flex flex-column gap-2" *ngFor="let individualMatch of match.individualMatches">
                                <span  class="w-100 align-middle" style="line-height: 21px; font-size: 12px;">{{individualMatch.user.first_name}} {{individualMatch.user.last_name}} ({{individualMatch.user.email}}) [{{individualMatch.user._id}}]</span >
                                <div  class="w-100 d-flex flex-wrap gap-2">
                                    <span *ngFor="let keyword of match.matchReasonRaw" class="text-light px-2 rounded user-select-none" style="font-size: 12px; background-color: #ff7a59;" ngbTooltip="{{keyword.weight.toFixed(4)}}" container="body" tooltipClass="custom-tooltip">
                                        {{keyword.keyword}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>


                </ng-template>
            </ngb-panel>
        </ngb-accordion>
        <div class="w-100 mt-3 d-flex justify-content-end">
            <app-paginator
                [length]="response.itemCount"
                [pageSize]="response.pageSize"
                [page]="response.pageNumber"
                (change)="loadPage($event.index)"
            ></app-paginator>
        </div>
        
    </div>

    <!-- Empty -->
    <div *ngIf="response && response.history.length <= 0" class="flex-grow-1 d-flex flex-column justify-content-center">
        <h4 class="text-center" >Nothing to show...</h4>
    </div>

    <!-- Loading -->
    <div *ngIf="!response" class="flex-grow-1 d-flex flex-column justify-content-center">
    </div>
</div>