<div class="d-flex flex-column gap-3">
  <div class="d-flex flex-row justify-content-between">
    <h3>Settings</h3>
  </div>
  <div class="card">
    <div class="card-header">
      <h5>Feedback Module</h5>
    </div>
    <div class="card-body">
      <form #form [formGroup]="formGroup">
        <div class="row">
          <div class="form-group pb-4">
            <label for="timeFeedbackInputTenant">
              Percentage of the stay after a feedback sollicitation for tenants
            </label>
            <div class="col-9 col-md-6 col-lg-3">
              <div class="has-validation mb-1 input-group">
                <input
                  type="number"
                  class="form-control"
                  maxlength="2"
                  formControlName="timeFeedbackInputTenant"
                  id="timeFeedbackInputTenant"
                  placeholder="Enter percentage"
                  [class.is-invalid]="
                    formGroup.controls.timeFeedbackInputTenant.invalid
                  "
                  [class.is-valid]="
                    formGroup.controls.timeFeedbackInputTenant.valid
                  "
                />
                <span
                  class="input-group-text font-monospace"
                  id="inputGroupAppend"
                >
                  %
                </span>
                <div class="invalid-feedback">
                  Provide positive number only
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group pb-4">
            <label for="timeFeedbackInputHost">
              Number of days for Host feedback after move out date
            </label>
            <div class="col-9 col-md-6 col-lg-3">
              <div class="has-validation mb-1 input-group">
                <input
                  type="number"
                  class="form-control"
                  maxlength="10"
                  formControlName="timeFeedbackInputHost"
                  id="timeFeedbackInputHost"
                  placeholder="Enter number of days"
                  [class.is-invalid]="
                    formGroup.controls.timeFeedbackInputHost.invalid
                  "
                  [class.is-valid]="
                    formGroup.controls.timeFeedbackInputHost.valid
                  "
                />
                <span
                  class="input-group-text font-monospace"
                  id="inputGroupAppend"
                >
                  days
                </span>
                <div class="invalid-feedback">
                  Provide positive number only
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group pb-4">
            <label for="sendInterval">
              Grace period for sending an email
            </label>
            <div class="col-9 col-md-6 col-lg-3">
              <div class="has-validation mb-1 input-group">
                <input
                  type="number"
                  class="form-control"
                  maxlength="10"
                  formControlName="sendInterval"
                  id="sendInterval"
                  placeholder="Enter number of days"
                  [class.is-invalid]="formGroup.controls.sendInterval.invalid"
                  [class.is-valid]="formGroup.controls.sendInterval.valid"
                />
                <span
                  class="input-group-text font-monospace"
                  id="inputGroupAppend"
                >
                  days
                </span>
                <div class="invalid-feedback">
                  Provide positive number only
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end pt-3">
          <button
            type="submit"
            class="btn btn-primary"
            style="min-width: 60px;"
            [class.disabled]="formGroup.invalid || !formGroup.dirty"
            (click)="save()"
          >
            <p class="mb-0" *ngIf="!loading">save</p>
            <div
              *ngIf="loading"
              style="width: 14px; height: 14px;"
              class="spinner-border"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
