<div class="filter">
    <div class="row">
        <div class="col-auto">
            <h2>Payouts</h2>
        </div>
        <div class="col-auto">
            <mat-form-field appearance="fill">
                <mat-label>City</mat-label>
                <mat-select (selectionChange)="handleCityChange($event)" [(ngModel)]="selectedCity">
                    <mat-option *ngFor="let city of this.cities" [value]="city.name">
                        {{ city.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-auto">
            <mat-form-field appearance="fill">
                <mat-label>Period</mat-label>
                <input matInput [matDatepicker]="dp" [formControl]="date">
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp
                                startView="multi-year"
                                (monthSelected)="setMonthAndYear($event, dp)"
                                panelClass="example-month-picker">
                </mat-datepicker>
              </mat-form-field>
        </div>
    </div>
</div>

<div class="mt-2">
    <table mat-table [dataSource]="payouts">
        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> Unit Address</th>
            <td mat-cell *matCellDef="let element; let i = index;" [attr.rowspan]="getRowspan(i)" [style.display]="accumulatedRowspan[i] ? '' : 'none'"> 
                {{ getAddress(element._id) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="bedroom">
            <th mat-header-cell *matHeaderCellDef> Bdrs #</th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="!isStudio(element.payout)">
                    {{ element.payout.reservationId.roomIds.length }} / {{ element.payout.reservationId.apartment.rooms.length }}
                </ng-container>
                <ng-container *ngIf="isStudio(element.payout)">
                    Studio
                </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="payout_date">
            <th mat-header-cell *matHeaderCellDef> Payout Date</th>
            <td mat-cell *matCellDef="let element">
                {{ dateUtil.createDate(element.payout.payoutDate) | date: 'mediumDate'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef> Owner </th>
            <td mat-cell *matCellDef="let element; let i = index">
                <a [routerLink]="['/user', getOwnerId(element.payout.reservationId)]" class="text-decoration-underline" target="_blank" *ngIf="accumulatedRowspan[i]">
                    {{ getOwnerName(element.payout) }}
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="payout">
            <th mat-header-cell *matHeaderCellDef> Payout </th>
            <td mat-cell *matCellDef="let element">
                {{ getPayoutValue(element.payout) | currency: 'CAD': 'symbol-narrow': '1.0-2' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="actual">
            <th mat-header-cell *matHeaderCellDef> Actual Paid </th>
            <td mat-cell *matCellDef="let element">
                {{ getActualPaidValue(element.payout) | currency: 'CAD': 'symbol-narrow': '1.0-2' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="tag">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <span class="badge text-bg-primary fw-bold px-2" *ngIf="isFirstPayout(element.payout)">
                    1ST PAY.
                </span>
                <span class="badge text-bg-secondary fw-bold px-2" *ngIf="isListedByHivenue(element.payout)">
                    HIVENUE
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="paid_checkbox">
            <th mat-header-cell *matHeaderCellDef> 
            </th>
            <td mat-cell *matCellDef="let element" class="mx-2">
                <mat-checkbox  
                    [checked]="isPaid(element.payout)"
                    (change)="handlePaymentCheck(element.payout, $event)"
                >
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Edit </th>
            <td mat-cell *matCellDef="let element">
                <button 
                    mat-icon-button 
                    matTooltip="Primary" 
                    color="accent" 
                    (click)="handlePayoutEdit(element.payout)"
                >
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator 
                [length]="totalNumberOfPayouts"
                [pageSize]="limit"
                (page)="handlePageChange($event)"
                [pageIndex]="pageIndex - 1"
              aria-label="Select page">
    </mat-paginator>
</div>