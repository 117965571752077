import {HttpClient, HttpHeaders} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Utils } from 'src/app/utils/utils'
import { FeedbackSettings } from './settings.types'
import { ToastService } from 'src/app/services/toast/toast-service'
import { AuthService } from 'src/app/services/auth.service'

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private util: Utils,
    private http: HttpClient,
    private toastService: ToastService,
    private authService: AuthService,
  ) {}

  async getSettings(): Promise<FeedbackSettings> {
    const headers =
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    return new Promise<FeedbackSettings>((resolve, reject) => {
      const url = `${this.util.getAPIUrl()}admin/feedback-settings`
      this.http.get<FeedbackSettings>(url, { headers }).subscribe({
        next: resolve,
        error: reject,
      })
    })
  }

  async saveSettings(feedbackSettings: FeedbackSettings): Promise<FeedbackSettings> {
    const headers =
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    return new Promise<FeedbackSettings>((resolve, reject) => {
      const url = `${this.util.getAPIUrl()}admin/feedback-settings/modify`
      this.http.patch<FeedbackSettings>(url, feedbackSettings, { headers }).subscribe({
        next: (response) => {
          this.toastService.show('Settings saved.', {
            classname: 'bg-success text-light',
            delay: 5000,
          })
          resolve(response)
        },
        error: () => {
          this.toastService.show('Error while saving settings.', {
            classname: 'bg-danger text-light',
            delay: 5000,
          })
          reject()
        },
      })
    })
  }
}
