<div class="d-flex flex-column gap-3">
  <div class="d-flex flex-row justify-content-between">
    <h3>Form Management</h3>
    <div>
      <button type="button" class="btn btn-dark" (click)="createForm()">
        New Form
        <ng-icon name="ionAddCircle" class="aligned ms-2" size="22"></ng-icon>
      </button>
    </div>
  </div>

  <ngb-accordion [closeOthers]="true" activeIds="static-1">
    <ngb-panel *ngFor="let form of forms" [id]="form._id">
      <ng-template ngbPanelTitle>
        <div class="w-100 pe-3 d-flex justify-content-between">
          <span>{{ form.name }}</span>
          <div class="d-flex gap-2">
            <span
              class="badge text-bg-success p-2 m-auto"
              style="
                cursor: default;
                font-weight: 500;
                width: 70px;
                height: 30px;
                line-height: 14px;
              "
              *ngIf="form.active"
            >
              Active
            </span>
            <button
              class="btn btn-sm btn-light"
              style="width: 70px; height: 30px;"
              *ngIf="!form.active"
              (click)="activateForm(form._id, $event)"
            >
              Activate
            </button>
            <button
              type="button"
              (click)="editForm(form._id, $event)"
              class="btn btn-primary-outline px-1 py-0"
            >
              <ng-icon
                name="bootstrapPencilFill"
                class="aligned"
                size="16"
              ></ng-icon>
            </button>
            <button
              type="button"
              (click)="deleteForm(form._id, $event)"
              class="btn btn-primary-outline px-1 py-0"
            >
              <ng-icon
                name="bootstrapTrashFill"
                class="aligned"
                size="16"
              ></ng-icon>
            </button>
          </div>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="d-flex flex-column gap-2">
          <div class="d-flex flex-row-reverse">
            <button
              type="button"
              style="font-size: 14px;"
              class="btn btn-dark"
              (click)="addQuestion(form._id)"
            >
              Add a question
              <ng-icon
                name="ionAddCircle"
                class="aligned ms-2"
                size="18"
              ></ng-icon>
            </button>
          </div>
          <div class="d-flex col-12 flex-wrap gap-2">
            <div *ngIf="form.questions.length < 1" class="text-center w-100">
              This form has no question
            </div>
            <ul class="list-group w-100" *ngIf="form.questions.length > 0">
              <li
                *ngFor="
                  let question of form.questions;
                  let i = index;
                  let first = first
                "
                class="list-group-item ps-4"
              >
                <div
                  class="question-number"
                  [ngStyle]="{
                    'border-radius': first ? '5px 0 5px 0' : '0 0 5px 0'
                  }"
                >
                  {{ i + 1 }}
                </div>
                <div
                  class="flex-row d-flex align-items-center justify-content-between"
                >
                  <div>
                    <Strong>
                      Input type:
                    </Strong>
                    {{ question.inputType }}
                  </div>
                  <div class="d-flex flex-row gap-2">
                    <span
                      style="
                        background-color: #2f4858;
                        color: white;
                        font-weight: 500;
                      "
                      class="badge px-2 py-1 m-auto"
                      *ngIf="question.userStatus != userStatus.BOTH"
                    >
                      {{ question.userStatus + ' only' }}
                    </span>
                    <span
                      style="
                        background-color: #2f4858;
                        color: white;
                        font-weight: 500;
                      "
                      class="badge px-2 py-1 m-auto"
                      *ngIf="question.required"
                    >
                      required
                    </span>
                  </div>
                  <div class="d-flex flex-row gap-2">
                    <button
                      type="button"
                      (click)="editQuestion(form._id, question._id)"
                      class="btn px-1 py-0"
                    >
                      <ng-icon
                        name="bootstrapPencilFill"
                        class="aligned"
                        size="16"
                      ></ng-icon>
                    </button>
                    <button
                      type="button"
                      (click)="deleteQuestion(form._id, question._id)"
                      class="btn px-1 py-0"
                    >
                      <ng-icon
                        name="bootstrapTrashFill"
                        class="aligned"
                        size="16"
                      ></ng-icon>
                    </button>
                  </div>
                </div>
                <br />
                <div class="row d-flex align-items-center">
                  <div class="text-break">
                    <strong>Question :</strong>
                    <br />
                    {{ question.title.en }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
