
<div class="row">
    <div class="col-auto">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">Listings</a></li>
                <li class="breadcrumb-item active text-secondary" aria-current="page">
                    {{ apartment._id }}
                </li>
            </ol>
        </nav>
    </div>
    <div class="col" [ngClass]="!layoutService.isLessThanSizeForFullDisplay() ? 'text-end' : 'none'">
        <mat-checkbox
            [checked]="isListedByHivenue()"
            (change)="setListedByHivenue($event.checked)"
        >
            Listed by Hivenue
        </mat-checkbox>
    </div> 
</div>


<div class="basic-info containter">
    <div class="row">
        <div class="col-5" id="listing_image">
            <a href="javascript:;" (click)="openCarouselModal()">
                <img 
                src="{{getImgHost()}}apartment/{{photos[0].source}}" alt=""
                class="w-100"
                *ngIf="photos.length > 0">
            </a>
        </div>
        <div class="col">
            <div class="container">
                <div class="row">
                    <div class="col" id="apartment_name">
                        <h3>{{apartment.name}}</h3>
                    </div>
                    <div class="col justify-content-end" id="apartment_price">
                        <ng-container *ngIf="apartment.wholeApartment || apartment.rooms.length == 1; else privateRoomBlock">
                            <h3 class="text-end">
                                {{ apartment.rooms[0].price | currency: 'CAD' : 'symbol-narrow' : '1.0-2' }} {{ "/ Month"}}
                            </h3>
                        </ng-container>
                        <ng-template #privateRoomBlock>
                            <h3 class="text-end">
                                {{getRangeOfPrice()[0] | currency: 'CAD': 'symbol-narrow' : '1.0-2'}} - {{getRangeOfPrice()[1] | currency: 'CAD' : 'symbol-narrow': '1.0-2'}} {{ "/ Month"}}
                            </h3>
                        </ng-template>
                    </div>
                </div>
               
                <p>
                    <i class="bi bi-geo-alt"></i>
                    <a href="javascript:;" class="text-decoration-none text-decoration-underline ms-2" (click)="openModal(map)">
                        {{ apartment.adress.adress }}
                    </a>
                </p>
                <p class="mt-2">
                    <ng-container *ngIf="apartment.wholeApartment">
                        <span class="text-bg-secondary badge rounded-pill">Entire Place</span>
                    </ng-container>
                    <ng-container *ngIf="!apartment.wholeApartment">
                        <span class="text-bg-secondary badge rounded-pill" *ngIf="apartment.rooms.length == 1">Private Room</span>
                        <span class="text-bg-secondary badge rounded-pill" *ngIf="apartment.rooms.length  > 1">Private Rooms</span>
                    </ng-container>
                    <span class="badge text-bg-dark rounded-pill ms-2" *ngIf="isOccupied()">Occupied</span>
                    <span class="badge text-bg-success rounded-pill ms-2" *ngIf="isAvailable()">Available</span>
                    <span class="badge text-bg-warning rounded-pill ms-2" *ngIf="isBooked()">Booked</span>
                    <span class="badge text-bg-primary rounded-pill ms-2" *ngIf="isPartiallyBooked()">{{ bookedRooms.length + "/" + apartment.rooms.length + " Booked"}}</span>
                    
                </p>
                <p class="mt-2">
                    <mat-slide-toggle [(ngModel)]="apartment.occupied" (change)="onChangeVisiblityOfApartment()">
                        {{ apartment.occupied ? 'Occupied' : 'Available' }}
                    </mat-slide-toggle> 
                    <mat-slide-toggle [(ngModel)]="apartment.isListed" (change)="onChangeOccupationStatusOfApartment()">
                        {{ apartment.isListed ? 'Visible' : 'Invisible' }}
                    </mat-slide-toggle>
                </p>

                <div class="row">
                    <div class="col">
                        <p class="mt-2 " *ngIf="apartment.creationDate">
                            Listed: {{ apartment.creationDate | date: 'mediumDate' }}
                        </p>
                    </div>
                    <div class="col text-end">
                        <p class="mt-2 " *ngIf="apartment.updatedAt">
                            Updated: {{ apartment.updatedAt | date: 'mediumDate' }}
                        </p>
                    </div>

                </div>

                <div class="row">
                    <div class="col-auto">
                        <a target="_blank" [href]="safeEditingUrl" class="btn btn-primary">Edit</a>
                    </div>
                </div>

                <p class="text-secondary description mt-4">
                    {{ apartment.description}}
                </p>

                <button 
                    type="button" 
                    class="btn btn-link mt-2 justify-self-start align-self-bottom p-0 text-secondary fw-bold text-decoration-none "
                    (click)="openModal(descriptionContent)">
                    Read More
                </button>
            </div>
        </div>
    </div>
    <div class="row mt-2" id="listing_info">
        <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="activeId">
            <li [ngbNavItem]="1">
                <a ngbNavLink>Details</a>
                <ng-template ngbNavContent>
                    <div class="container">
                        <ng-container class="apartment-info"  >
                            <div class="row text-secondary" id="titles" *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
                                <div class="col-2" id="beds_and_bath">Beds / Bath</div>
                                <div class="col-2" id="min_stay"> Minium Stay</div>
                                <div class="col-3" id="neighborhood">Neighbourhood</div>
                                <div class="col-4" id="property_access_detail">Property Access Detail</div>
                            </div>
                            <div class="row fw-bold" id="apartment_info">
                                <div class="col-2">
                                    <span *ngIf="layoutService.isLessThanSizeForFullDisplay()" class="fw-normal text-secondary">Room: </span>{{ apartment.rooms.length }}
                                    /
                                    <span *ngIf="layoutService.isLessThanSizeForFullDisplay()" class="fw-normal text-secondary">Bath: </span>{{ getBathCount() }}
                                    <span class="badge text-bg-primary rounded-pill ms-2" *ngIf="apartment.isStudio">
                                        Studio
                                    </span> 
                                </div>
                                <div class="col-2">
                                    <span *ngIf="layoutService.isLessThanSizeForFullDisplay()" class="fw-normal text-secondary">Mininum Stay:  </span>
                                    <ng-container *ngIf="apartment.wholeApartment">
                                        {{ apartment.rooms[0].minimumStay }}
                                        <ng-container *ngIf="apartment.minimumStay ==  '1'; else miniumStayMoreBlock">
                                            month
                                        </ng-container>
                                        <ng-template #miniumStayMoreBlock>
                                            months
                                        </ng-template>
                                    </ng-container>
    
                                    <ng-container *ngIf="!apartment.wholeApartment">
                                        /
                                    </ng-container>
                                </div>
                                <div class="col-3">
                                    <span *ngIf="layoutService.isLessThanSizeForFullDisplay()" class="fw-normal text-secondary">Neighborhood:  </span>
                                    {{ apartment.adress.neighborhood }}
                                </div>
                                <div class="col-4">

                                    <ng-container *ngIf="apartment.moveInDetails">
                                        <span *ngIf="layoutService.isLessThanSizeForFullDisplay()" class="fw-normal text-secondary">Property Access Method:  </span>{{ apartment.moveInDetails.propertyAccessMethod }} 
                                        <span *ngIf="layoutService.isLessThanSizeForFullDisplay()" class="fw-normal text-secondary">Check-in Time:  </span>{{apartment.moveInDetails.hostWaitGuestTime}}
                                        <ng-container *ngIf="layoutService.isLessThanSizeForFullDisplay()">
                                            <br>
                                        </ng-container>
                                        <ng-container *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
                                            /
                                        </ng-container>
                                        <span *ngIf="layoutService.isLessThanSizeForFullDisplay()" class="fw-normal text-secondary">Check-out Time:  </span>{{apartment.moveInDetails.checkoutTime}}
                                    </ng-container>
                                    
                                </div>
                            </div>
                        </ng-container>
                    
                        

                        <div class="home-amenities mt-4">
                            <h5 class="text-secondary">Home Amenities</h5>
                        </div>
                        <div class="row">
                            <app-checkbox-list
                                [indexes]="apartment.accomodations"
                                [indexStart]="1"
                                [indexEnd]="9"
                                [values]="getAccomodations()"
                            >

                            </app-checkbox-list>
                        </div>

                        <div class="home-rules mt-4">
                            <h5 class="text-secondary">Home Rules</h5>
                        </div>
                        <div class="row mb-4">
                            <app-checkbox-list
                                [indexes]="apartment.accomodations"
                                [indexStart]="10"
                                [indexEnd]="13"
                                [values]="getAccomodations()"
                            >
                            </app-checkbox-list>
                        </div>
                    </div>
                    
                </ng-template>
            </li>
            <li [ngbNavItem]="2">
                <a ngbNavLink>Room Details</a>
                <ng-template ngbNavContent>
                    <ngb-accordion class="justify-content-center">
                        <ngb-panel *ngFor="let room of apartment.rooms; let i = index">
                            <ng-template ngbPanelTitle>
                                    <div class="col-7 border-end pe-2">
                                        <div class="row" id="left_row">
                                            <div class="col-auto">
                                                <span class="fw-bold room-number"> Room{{i+1}}</span>
                                            </div>
                                            <div class="col-auto">
                                                <span class="badge text-bg-secondary rounded-pill ms-2">{{getBedType(room.bedType)}}</span>
                                            </div>
                                            <div class="col-auto" *ngIf="!apartment.wholeApartment">
                                                <span class="fw-bold price ms-4">
                                                    ${{room.price}} {{"/ Month"}} 
                                                </span>
                                            </div>
                                            <div class="col text-end" *ngIf="!apartment.wholeApartment">
                                                <span class="text-secondary">Minium Stay:</span>
                                                <span class="fw-bold"> 
                                                    {{room.minimumStay}} 
                                                    <ng-container *ngIf="room.minimumStay == '1'; else _1">
                                                        month
                                                    </ng-container>
                                                    <ng-template #_1>
                                                        months
                                                    </ng-template>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-5 ps-2" *ngIf="!apartment.wholeApartment">
                                        <div class="row">
                                            <ng-container *ngIf="isRoomBooked(room._id);">
                                                <div class="col">
                                                    <span class="badge text-bg-warning rounded-pill">
                                                        <span class="fw-bold ">{{getBookingRequestOfRoom(room._id).length }} Request(s) </span>
                                                    </span>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="!isRoomBooked(room._id)">
                                                <div class="col-auto">
                                                    <span class="badge text-bg-success rounded-pill ">Available</span>
                                                </div>
                                                <div class="col text-start">
                                                    <span class="text-secondary " *ngIf="!layoutService.isLessThanSizeForFullDisplay()">From - To:</span>
                                                    <span class="fw-bold">
                                                        {{ room.moveInDate | date: 'mediumDate' }} - {{ room.moveOutDate | date: 'mediumDate'}}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <h5 class="mt-2 text-secondary">Booking Reqeusts</h5> 
                                <div class="row mt-2" id="right_row">
                                    <ngb-accordion class="justify-content-center">
                                        <ngb-panel *ngFor="let bookingRequest of getBookingRequestOfRoom(room._id); let i = index">
                                            <ng-template ngbPanelTitle>
                                                <div class="row">
                                                    <div class="col-auto">
                                                        {{ bookingRequest._id }}
                                                    </div>
                                                    <div class="col-auto">
                                                        {{ bookingRequest.moveInDate | date: 'mediumDate' }} - {{ bookingRequest.moveOutDate | date: 'mediumDate'}} 
                                                    </div>
                                                </div>
                                                
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>  
                                </div> 
                                <h5 class="mt-2 text-secondary">Accomodations</h5>
                                <div class="row mt-2" id="right_row">
                                    <app-checkbox-list
                                        [indexes]="room.facilities"
                                        [values]="getFacilities()"
                                        [indexStart]="1"
                                    >
                                    </app-checkbox-list>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion> 
                </ng-template> 
            </li>
            <li [ngbNavItem]="3">
                <a ngbNavLink>Contact</a>
                <ng-template ngbNavContent>
                    <div class="container">
                        <mat-card>
                            <mat-card-header>
                              <div mat-card-avatar class="example-header-image" style="
                                background-image: url('{{owner.image}}');
                                background-size: cover;
                              "></div>
                              <mat-card-title>{{ owner.first_name + " " + owner.last_name }}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <mat-grid-list 
                                    [cols]="layoutService.isLessThanSizeForFullDisplay() ? 1 : 3" 
                                    [rowHeight]="layoutService.isLessThanSizeForFullDisplay() ? '4:1' : '3:1'">
                                    <mat-grid-tile>
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Name</mat-label>
                                            <input matInput disabled [value]="apartment.owner.name">
                                        </mat-form-field>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Tel</mat-label>
                                            <input matInput disabled [value]="owner.phone">
                                        </mat-form-field>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Email</mat-label>
                                            <input matInput disabled [value]="owner.email">
                                        </mat-form-field>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Real Name</mat-label>
                                            <input matInput [(ngModel)]="apartment.owner.realName">
                                        </mat-form-field>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Real Phone</mat-label>
                                            <input matInput [(ngModel)]="apartment.owner.realPhone">
                                        </mat-form-field>
                                    </mat-grid-tile>
                                    <mat-grid-tile>
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Real Email</mat-label>
                                            <input matInput [(ngModel)]="apartment.owner.realEmail">
                                        </mat-form-field>
                                    </mat-grid-tile>
                                </mat-grid-list>
                            </mat-card-content>
                            <mat-card-actions>
                              <button mat-flat-button color="primary" (click)="updateApartment()">
                                <mat-icon>
                                    content_copy
                                </mat-icon>
                                Save
                              </button>
                            </mat-card-actions>
                          </mat-card>
                    </div>
                   
                </ng-template>
            </li>
        </ul>
        
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</div>



<ng-template #descriptionContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Description</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p class="text-secondary">
            {{ apartment.description }}
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>


<ng-template #map let-modal>
	<div class="modal-header">
        <h4 class="modal-title">Map</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body d-flex justify-content-center align-items-center">
        <div id="propertyMap-container">
            <google-map [options]="mapOptions">
                <map-marker [position]="markerPosition">
                </map-marker>
            </google-map>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
    </div>
</ng-template>

