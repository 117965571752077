import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Utils } from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class PayoutService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private util: Utils
  ) { }

  public getPayouts(body: any): Observable<any> {
    const headers = 
      new HttpHeaders().set(
        'authorization', this.authService.getToken()
      );
    let url = `${this.util.getAPIUrl()}admin/payment/payouts`;
    return this.httpClient.post<any>(url, body, { headers });
  }

  public getPayoutsByReservationId(id: string, payload: any): Observable<any> {
    const headers = new HttpHeaders().set(
      'authorization', this.authService.getToken()
    );
    let url = `${this.util.getAPIUrl()}admin/reservations/${id}/payouts`;
    return this.httpClient.post<any>(url, payload, {headers});
  }
}