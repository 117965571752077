
<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{mode === 'edit' ? 'Edit' : 'Create'}} Neighborhood</h4>
    <button
        type="button"
        class="btn-close"
        (click)="modal.dismiss()"
    ></button>
</div>
<div class="modal-body">
    <form #form [formGroup]="formGroup">
        <div class="form-group mb-3">
            <label for="nameEn" class="form-label">Name</label>
            <div class="input-group has-validation mb-1">
                <span class="input-group-text font-monospace" id="inputGroupPrepend">en</span>
                <input 
                    formControlName="nameEn"
                    class="form-control"
                    [class.is-invalid]="formGroup.controls.nameEn.invalid"
                    [class.is-valid]="formGroup.controls.nameEn.valid"
                    type="text"
                    id="nameEn">
                <div *ngIf="getErrorMessage('nameEn')" class="invalid-feedback">
                    {{getErrorMessage('nameEn')}}
                </div>
            </div>
            <div class="input-group has-validation mb-3">
                <span class="input-group-text font-monospace" id="inputGroupPrepend">fr</span>
                <input 
                    formControlName="nameFr"
                    class="form-control"
                    [class.is-invalid]="formGroup.controls.nameFr.invalid"
                    [class.is-valid]="formGroup.controls.nameFr.valid"
                    type="text"
                    id="nameFr">
                <div *ngIf="getErrorMessage('nameFr')" class="invalid-feedback">
                    {{getErrorMessage('nameFr')}}
                </div>
            </div>
        </div>
        <div class="form-group mb-3">
            <label for="googleApiName" class="form-label">Google API name</label>
            <div class="input-group has-validation mb-1">
                <input 
                    formControlName="googleApiName"
                    class="form-control"
                    [class.is-invalid]="formGroup.controls.googleApiName.invalid"
                    [class.is-valid]="formGroup.controls.googleApiName.valid"
                    type="text"
                    id="googleApiName">
                <div *ngIf="getErrorMessage('googleApiName')" class="invalid-feedback">
                    {{getErrorMessage('googleApiName')}}
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
    <button class="btn btn-dark" [class.disabled]="formGroup.invalid" type="button" (click)="submit()">{{mode === 'edit' ? 'Save' : 'Create'}}</button>
</div>
