import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { FormControl, NgForm, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FeedbackService } from '../../services/feedback.service'
import { FeedbackForm } from '../../services/feedback.type'

@Component({
  selector: 'app-form-add-modal',
  templateUrl: './form-add-modal.component.html',
  styleUrls: ['./form-add-modal.component.css'],
})
export class FeedbackAddModalComponent implements OnInit {
  @ViewChild('form', { read: NgForm }) form: any

  private feedbackForm: FeedbackForm | undefined
  public mode: 'edit' | 'add' = 'edit'
  public nameControl = new FormControl('', [Validators.required])

  constructor(
    public modal: NgbActiveModal,
    private feedbackService: FeedbackService,
  ) {}

  ngOnInit(): void {
    if (!this.feedbackForm) {
      this.modal.dismiss()
      return
    }
    this.nameControl.setValue(this.feedbackForm.name)
  }

  async submit() {
    if (this.nameControl.invalid || !this.feedbackForm) return
    const payload: FeedbackForm = {
      _id: this.feedbackForm._id,
      name: this.nameControl.value as string,
      active: this.feedbackForm.active,
      questions: [],
    }
    try {
      if (this.mode === 'edit')
        this.modal.close(await this.feedbackService.modifyForm(payload))
      else this.modal.close(await this.feedbackService.createForm(payload.name))
    } catch (e) {
      console.error(e)
      return
    }
  }

  // pt enlever la fct a sers pas a grand chose si ya pas de traduction
  getErrorMessage(controlId: string): string {
    const formControl = this.nameControl.get(controlId)
    if (!formControl) return ''
    let prefix = ''
    switch (controlId) {
      case 'tag':
        prefix = 'Tag'
        break
      case 'nameEn':
        prefix = 'English name'
        break
      case 'nameFr':
        prefix = 'French name'
        break
      default:
        prefix = 'Field'
    }
    if (!formControl.errors) return ''
    if (formControl.errors['required']) return prefix + ' is required.'
    return ''
  }
}
