import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListingDetailComponent } from '../listing-detail/listing-detail.component';

const routes: Routes = [
  {path: 'listing/:id', component: ListingDetailComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ListingRoutingRoutingModule { }
