<div class="modal-header">
  <h4 class="modal-title" id="modal-title">
    {{ mode === 'edit' ? 'Edit' : 'Add' }} Question
  </h4>
  <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
</div>
<div class="modal-body">
  <form #form [formGroup]="formGroup" (keydown.enter)="$event.preventDefault()">
    <div
      class="d-flex flex-row justify-content-between align-items-center mb-3"
    >
      <div class="flex-col">
        <div class="input-group has-validation mb-1">
          <div ngbDropdown class="city-dropdown">
            <button
              type="button"
              class="btn btn-outline-primary"
              id="dropdownBasic1"
              [disabled]="mode === 'edit'"
              ngbDropdownToggle
            >
              {{ formGroup.controls.inputType.value }}
            </button>
            <div ngbDropdownMenu>
              <button
                ngbDropdownItem
                *ngFor="let type of inputType | keyvalue"
                (click)="onInputTypeChange(type.value)"
              >
                {{ type.value }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col">
        <div class="form-check form-switch mb-1">
          <input
            formControlName="required"
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="required"
          />
          <label class="form-check-label" for="required">
            Answer is required
          </label>
        </div>
      </div>
    </div>
    <div class="form-group mb-3">
      <form [formGroup]="formGroup.controls.title">
        <label class="form-label">Question title</label>
        <div class="input-group has-validation mb-1">
          <span class="input-group-text font-monospace" id="inputGroupPrepend">
            en
          </span>
          <input
            formControlName="en"
            class="form-control"
            [class.is-invalid]="
              formGroup.controls.title.controls.en.invalid &&
              formGroup.controls.title.controls.en.touched
            "
            [class.is-valid]="
              formGroup.controls.title.controls.en.valid &&
              formGroup.controls.title.controls.en.touched
            "
            type="text"
            id="en"
          />
        </div>
        <div class="input-group has-validation">
          <span class="input-group-text font-monospace" id="inputGroupPrepend">
            fr
          </span>
          <input
            formControlName="fr"
            class="form-control"
            [class.is-invalid]="
              formGroup.controls.title.controls.fr.invalid &&
              formGroup.controls.title.controls.fr.touched
            "
            [class.is-valid]="
              formGroup.controls.title.controls.fr.valid &&
              formGroup.controls.title.controls.fr.touched
            "
            type="text"
            id="fr"
          />
        </div>
      </form>
    </div>
    <div
      class="form-group mb-3"
      [ngSwitch]="formGroup.controls.inputType.value"
    >
      <label class="form-label">
        Input options
      </label>

      <!-- Text input options -->
      <form [formGroup]="formGroup.controls.textInputOptions">
        <div
          *ngSwitchCase="inputType.TEXT"
          class="input-group has-validation mb-1"
        >
          <span class="input-group-text font-monospace" id="inputGroupPrepend">
            Placeholder en
          </span>
          <input
            formControlName="en"
            class="form-control"
            type="text"
            id="en"
          />
        </div>
        <div *ngSwitchCase="inputType.TEXT" class="input-group has-validation">
          <span class="input-group-text font-monospace" id="inputGroupPrepend">
            placeholder fr
          </span>
          <input
            formControlName="fr"
            class="form-control"
            type="text"
            id="fr"
          />
        </div>
      </form>

      <!-- Range input options -->
      <form [formGroup]="formGroup.controls.rangeInputOptions">
        <div class="input-group" *ngSwitchCase="inputType.RANGE">
          <div class="input-group-prepend">
            <span class="input-group-text">
              Min and Max range
            </span>
          </div>
          <input formControlName="min" class="form-control" type="number" />
          <input formControlName="max" class="form-control" type="number" />
        </div>
      </form>
      <!-- Checkbox input options -->
      <div
        *ngSwitchCase="inputType.CHECKBOX"
        formArrayName="checkboxAndSelectInputOptions"
        class="d-flex flex-column w-100"
      >
        <div
          class="d-flex flex-row w-100 p-0 mb-1"
          *ngFor="
            let choice of formGroup.controls['checkboxAndSelectInputOptions']
              .controls;
            let i = index;
            let first = first
          "
        >
          <form [formGroup]="choice" class="w-100">
            <div class="d-flex flex-column w-100">
              <div class="d-flex flex-row justify-content-between w-100">
                <span class="input-group-text font-monospace">
                  {{ 'Choice ' + (i + 1) }}
                </span>
                <span *ngIf="first" class="input-group-text font-monospace p-0">
                  <button class="btn" (click)="addChoice()">
                    <ng-icon
                      name="bootstrapPlusLg"
                      class="aligned text-black"
                    ></ng-icon>
                  </button>
                </span>
                <span
                  *ngIf="!first"
                  class="input-group-text font-monospace p-0"
                >
                  <button class="btn" (click)="removeChoice(i)">
                    <ng-icon
                      name="bootstrapTrashFill"
                      class="aligned"
                    ></ng-icon>
                  </button>
                </span>
              </div>
              <div class="d-flex flex-row">
                <span class="input-group-text font-monospace">
                  en
                </span>
                <input
                  class="form-control w-100"
                  [formControl]="choice.controls.en"
                  [class.is-invalid]="
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.en.invalid &&
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.en.touched
                  "
                  [class.is-valid]="
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.en.valid &&
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.en.touched
                  "
                  type="text"
                />
                <span class="input-group-text font-monospace">
                  fr
                </span>
                <input
                  class="form-control w-100"
                  [formControl]="choice.controls.fr"
                  [class.is-invalid]="
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.fr.invalid &&
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.fr.touched
                  "
                  [class.is-valid]="
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.fr.valid &&
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.fr.touched
                  "
                  type="text"
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- Select input options -->
      <div
        *ngSwitchCase="inputType.SELECT"
        formArrayName="checkboxAndSelectInputOptions"
        class="d-flex flex-column w-100"
      >
        <div
          class="d-flex flex-row w-100 p-0 mb-1"
          *ngFor="
            let choice of formGroup.controls['checkboxAndSelectInputOptions']
              .controls;
            let i = index;
            let first = first
          "
        >
          <form [formGroup]="choice" class="w-100">
            <div class="d-flex flex-column w-100">
              <div class="d-flex flex-row justify-content-between w-100">
                <span class="input-group-text font-monospace">
                  {{ 'Choice ' + (i + 1) }}
                </span>
                <span *ngIf="first" class="input-group-text font-monospace p-0">
                  <button class="btn" (click)="addChoice()">
                    <ng-icon
                      name="bootstrapPlusLg"
                      class="aligned text-black"
                    ></ng-icon>
                  </button>
                </span>
                <span
                  *ngIf="!first"
                  class="input-group-text font-monospace p-0"
                >
                  <button class="btn" (click)="removeChoice(i)">
                    <ng-icon
                      name="bootstrapTrashFill"
                      class="aligned"
                    ></ng-icon>
                  </button>
                </span>
              </div>
              <div class="d-flex flex-row">
                <span class="input-group-text font-monospace">
                  en
                </span>
                <input
                  class="form-control w-100"
                  [formControl]="choice.controls.en"
                  [class.is-invalid]="
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.en.invalid &&
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.en.touched
                  "
                  [class.is-valid]="
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.en.valid &&
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.en.touched
                  "
                  type="text"
                />
                <span class="input-group-text font-monospace">
                  fr
                </span>
                <input
                  class="form-control w-100"
                  [formControl]="choice.controls.fr"
                  [class.is-invalid]="
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.fr.invalid &&
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.fr.touched
                  "
                  [class.is-valid]="
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.fr.valid &&
                    formGroup.controls.checkboxAndSelectInputOptions.controls[i]
                      .controls.fr.touched
                  "
                  type="text"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- select who's the question for -->
    <div class="form-group mb-3">
      <label class="form-label">Who is this for?</label>
      <span class="d-flex justify-content-center mb-3">
        <div *ngFor="let status of userStatus | keyvalue">
          <label [for]="status.value" class="form-check-label">
            {{ status.value }}
          </label>
          <input
            [id]="status.value"
            type="radio"
            [value]="status.value"
            formControlName="userStatus"
            class="form-check-input mx-2"
          />
        </div>
      </span>
    </div>
  </form>
</div>

<!-- end button section -->
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="modal.dismiss()"
  >
    Cancel
  </button>
  <button
    class="btn btn-dark"
    [class.disabled]="isInvalidForm() || !formGroup.dirty"
    type="button"
    (click)="submit()"
  >
    {{ mode === 'edit' ? 'Save' : 'Add' }}
  </button>
</div>
