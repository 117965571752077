<div class="d-flex flex-column gap-3">
    <div class="d-flex flex-row justify-content-between">
        <h3>Settings</h3>
    </div>
    <div class="card">
        <div class="card-header">
            <h5>Feedback Module</h5>
        </div>
        <div class="card-body">
            <form #form [formGroup]="formGroup">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group pb-4">
                            <label for="timeFeedbackInputTenant">Percentage of the stay after a feedback sollicitation for tenants</label>
                            <div class="input-group has-validation mb-1">
                                <input
                                    type="text"
                                    class="form-control"
                                    maxlength="2"
                                    formControlName="timeFeedbackInputTenant"
                                    id="timeFeedbackInputTenant"
                                    placeholder="Enter percentage"
                                    [class.is-invalid]="formGroup.controls.timeFeedbackInputTenant.invalid"
                                    [class.is-valid]="formGroup.controls.timeFeedbackInputTenant.valid">
                                <div class="invalid-feedback">
                                    Provide positive number only
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group pb-4">
                            <label for="timeFeedbackInputHost">Number of days for Host feedback after move out date</label>
                            <div class="input-group has-validation mb-1">
                                <input
                                    type="text"
                                    class="form-control"
                                    maxlength="10"
                                    formControlName="timeFeedbackInputHost"
                                    id="timeFeedbackInputHost"
                                    placeholder="Enter number of days"
                                    [class.is-invalid]="formGroup.controls.timeFeedbackInputHost.invalid"
                                    [class.is-valid]="formGroup.controls.timeFeedbackInputHost.valid">
                                <div class="invalid-feedback">
                                    Provide positive number only
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end pt-3">
                     <button type="submit" class="btn btn-primary" [class.disabled]="formGroup.invalid || !formGroup.dirty" (click)="save()">Save</button>
                </div>
            </form>
        </div>
    </div>
</div>

